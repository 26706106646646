import { default as _91slug_93KObAeJ0Y1fMeta } from "/home/forge/uclermarket.com.tr/pages/[slug].vue?macro=true";
import { default as address9zGuiowZnsMeta } from "/home/forge/uclermarket.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_93aAvg1gOgb3Meta } from "/home/forge/uclermarket.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93RuV0BDyZAwMeta } from "/home/forge/uclermarket.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexzHuIoKIDesMeta } from "/home/forge/uclermarket.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewEN3y1BEwA9Meta } from "/home/forge/uclermarket.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_937pZHLHbRlsMeta } from "/home/forge/uclermarket.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexJptCHp5thZMeta } from "/home/forge/uclermarket.com.tr/pages/account/statement/index.vue?macro=true";
import { default as useriK0JEKuwjvMeta } from "/home/forge/uclermarket.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistEdjccJWIwnMeta } from "/home/forge/uclermarket.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartfkispfTd4nMeta } from "/home/forge/uclermarket.com.tr/pages/cart.vue?macro=true";
import { default as indexixeMp2beSXMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/index.vue?macro=true";
import { default as payment7crXXCvb8xMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientJVuYUHsP7BMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/uclermarket.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestcVbWuqWKtvMeta } from "/home/forge/uclermarket.com.tr/pages/customer/request.vue?macro=true";
import { default as successYXwzz0zVWfMeta } from "/home/forge/uclermarket.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisim9Pnzg6isvsMeta } from "/home/forge/uclermarket.com.tr/pages/iletisim.vue?macro=true";
import { default as indexzfbSoLyExPMeta } from "/home/forge/uclermarket.com.tr/pages/index.vue?macro=true";
import { default as loginDJKSleOvxkMeta } from "/home/forge/uclermarket.com.tr/pages/login.vue?macro=true";
import { default as offer0P0LSml3HXMeta } from "/home/forge/uclermarket.com.tr/pages/offer.vue?macro=true";
import { default as statusnKo6fvvukrMeta } from "/home/forge/uclermarket.com.tr/pages/order/status.vue?macro=true";
import { default as trackKTxvXHz3esMeta } from "/home/forge/uclermarket.com.tr/pages/order/track.vue?macro=true";
import { default as forgotsDNYihKzDmMeta } from "/home/forge/uclermarket.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetuxSm8BYrM0Meta } from "/home/forge/uclermarket.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationj4czNjwBi0Meta } from "/home/forge/uclermarket.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryzK2dKG3uDoMeta } from "/home/forge/uclermarket.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerLSFJhbGzrTMeta } from "/home/forge/uclermarket.com.tr/pages/register.vue?macro=true";
import { default as searchSZmUoGG5W9Meta } from "/home/forge/uclermarket.com.tr/pages/search.vue?macro=true";
import { default as locationsOyrBXJs6EFMeta } from "/home/forge/uclermarket.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stubLBysIGp3OXMeta } from "/home/forge/uclermarket.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLBysIGp3OX } from "/home/forge/uclermarket.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93KObAeJ0Y1fMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: address9zGuiowZnsMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93aAvg1gOgb3Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93RuV0BDyZAwMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexzHuIoKIDesMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewEN3y1BEwA9Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_937pZHLHbRlsMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexJptCHp5thZMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: useriK0JEKuwjvMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistEdjccJWIwnMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/uclermarket.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexixeMp2beSXMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment7crXXCvb8xMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/uclermarket.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/uclermarket.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/uclermarket.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/uclermarket.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexzfbSoLyExPMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginDJKSleOvxkMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offer0P0LSml3HXMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/uclermarket.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/uclermarket.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotsDNYihKzDmMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetuxSm8BYrM0Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/uclermarket.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/uclermarket.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerLSFJhbGzrTMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchSZmUoGG5W9Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/uclermarket.com.tr/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-makineyedek-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-oda-kokus-bambulu-25ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akaji-soya-sosu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akdeniz-yesillikleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/Default.aspx",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/premier-1572",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blog",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/homend",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cp--pilic----ciger--kg-",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mum-4-lu-uzun-23306",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyaz-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/2-li-renkli-samdan-mum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/3-chocolate-bitter-dubai-cikolatasi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/3-chocolate-dubai-cikolatasi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/3-chocolate-dubai-cikolatasi-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/6-li-bar-mum-karisik-renk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/7days-kuruvasan-60-gr-findik-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/7days-kuruvasan-60-gr-kakao-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/7days-kuruvasan-72-gr-vanilya-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/7days-mini-croissant-kakao-kremali-185-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/7days-mini-croissant-visneli-185-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/absolute-nano-firca-g-330",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/absolute-sac-acma-firca-g-688",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-1-lt-bahar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-1-lt-extra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-2-lt-bahar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-2-lt-extra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-2-lt-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-4-kg-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-camasir-suyu-4-lt-extra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-ultra-camasir-suyu-2-lt-ferahlik-etkisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-ultra-camasir-suyu-2-lt-limon-bahcesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ace-ultra-kopuk-700-mlferahlik-etkisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/acma-tarak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/acraft-mevsim-su-bardagi-68",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-dezenfektan-sprey-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-el-temizleme-jeli-100-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-islak-cep-mendili-315-aktif",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-islak-cep-mendili-315-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-islak-havlu-50-li-aktif",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-islak-havlu-50-li-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-1500-ml-aktif-iyi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-1500-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-1500-ml-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-1lt500-ml-aktif",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-1lt500-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-300-ml-aktif-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-300-ml-hassas-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-500-ml-aktif",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-500-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-500-ml-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-650-mlhassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activex-sivi-sabun-700-ml-hassas-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-entry-pack-kayisi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-entry-pack-sade-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-picecek-80-ml-shot-lmnzncflmatcha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-picecek-80-ml-shot-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-prepro-tupper-musli-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-pyogurt-400gr-lktozsuz-big-cup",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-pyogurt-400gr-sade-big-cup",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-shotkmurverymersini-80ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-shotnarcilek-80ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-shotturuncpropolis-80ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-single-kasikli-ananas-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/activia-single-kasikli-cilek-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/afistikli-parmak-fitil-lokum-kg-portakal-a-cb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/agrotalya-kocanda-misir-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-ceylon-200-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-ceylon-400-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-ceylon-tea-50-gr-25li-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-earl-grey-32-gr-48li-demlik-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-earl-grey-cay-2-gr-25li-bardak-poscay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-eng-tea-50-gr-25li-bardak-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-engl-break-32-gr-48li-demlik-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-english-brea-2gr12li-bardak-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-english-breakfast-200-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-english-breakfast-400-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-special-blend-200-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ahmad-tea-special-blend-400-gr-dokme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-elektirikili-kit-yedek-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-elektirikili-kit-yedek-temizligin-esintis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-elektirikili-kit-yedek-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-elektirikili-kit-yedek-vanilya-19ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-elektirikili-yedek-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-fresh-mtk-ydk-250-ml-bahar-cicekleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-fresh-mtk-ydk-lavanta-250-ml12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/airwick-makineyedek-bahar-cicekleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-ezine-3-dilim-peynir-keci-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-ezine-3-dilim-peynir-klasik-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-ezine-3-dilim-peynir-koyun-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirim-1-lt-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirim-1-lt-ormanmeyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirix-250ml-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirli-cilekli-yogurt-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirli-ketentohumlu-yogurt-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-kefirli-ymersinli-yogurt-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-trakya-eski-kasar-250-gr-mandira",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akilic-trakya-eski-kasar-300-gr-mandira",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akkaya-buz-poset-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akkaya-buz-poset-2-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-biberiye-20-gr-pst-20",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-galete-unu-500-gr-eko-15",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-hindistan-cevizi-125-gr-eko-15",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-kajun-50-gr-pst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-kofte-bahari-50-gr-pst-20",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-nane-15-gr-psise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-pasta-susu-40-gr-pst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-sogantozu-50-gr-pst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/akman-tatli-biber-1000-gr-pst-20",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-antrikot-fume-et-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-dana-cotto-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-et-cubuklari-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-fistikli-kavurma-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-fume-dil-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-kuru-et-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-kuzu-but-fume-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-pastirma-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-rozbif-biberli-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-rozbif-kekikli-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-rozbif-sade-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-sucuk-visneli-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alcatra-visneli-sucuk-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-boom-boom-80ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-boom-boom-gold-80-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-boom-boom-tub-570-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-classics-antep-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-classics-kakao-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-classics-oreo-stick-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-classics-vanilya-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-frigola-62-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-frigola-bar-60-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-frigola-toblerone-30x90-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-frigola-toblerone-85-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-frigola-tub-6x570-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-karlar-ulkesi-800ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-choc-van-110ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-cilek-van-kakao-6x1400ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-fistik-van-kakao-6x1400ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-fistik-vanilya-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-kakao-vanilya-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-vanilya-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-keyif-visnelimon-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-kulah-maras-usulu-120-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-500-ml-kaymaklim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-fistik-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-kup-105-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-sade-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-sade-500-ml-Xmn6K",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-sade-antep-fistik-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-sade-cifte-dovulmus-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-maras-yanik-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-milka-stick-82-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-moo-shake-60-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-oreo-bar-30x72-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-twister-tub-6x750ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/algida-winner-bar-30x60-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alkan-3-lu-toprakli-grup-priz-2mt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/almondy-gateau-milka-hazelnut-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/almondy-gateau-toblerone-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alo-matik-15-kg-kar-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alo-matik-4-kg-beyaz-ve-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-badem-sutu-1-lt-barista",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-badem-sutu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-bitcok-hindistan-ceviz-icecegi-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-cikolatali-hindistan-cevisiz-icecegi-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-hindistan-cevizi-badem-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-hindistan-cevizi-sutu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-soya-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/alpro-soya-sutu-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altin-kilic-klasik-beyaz-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-cecil-peynir-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-dil-peynir-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-eski-kasar-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-kaymak-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-lor-400-gr-kaymakli-ayvalik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/altinkilic-orgu-peynir-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ambre-sgunes-sutu-200-ml6-gunes-sonr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/amoy-susam-yag-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/anavarza-bal-450-gr-cicek-bali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/anavarza-cicek-bali-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/anavarza-cicek-bali-850-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/anavarza-cicek-bali-akilli-kapak-325-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-310g-cilek-25-sekeri-azaltilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-310g-kumkuat25-sekeri-azaltilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-310g-seftali25-sekeri-azaltilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-310g-visne-25-sekeri-azaltilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-4meyveli-recel-370-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-cilek-receli-370-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-kayisi-receli-370-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/andros-visne-receli-370-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-bresaola-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-dana-et-cubuklari-110gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-dana-et-cubuklari-110gr-aci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-fermente-sucuk-280-gr-kangal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-fermente-sucuk-340-gr-kangal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/apikoglu-pastirma-90-gr-cemensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aptamil-kasik-mama-250-gr-7-tahilli-balli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aptamil-prosyned-no1-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aptamil-prosyned-no2-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ari-250-gr-kahvalti-balli-irmikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ari-250-gr-sutlu-pirinc-unu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ari-grissini-100gr-zyaglikepek12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-ari-sutu-apimix-120-gr-cocuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-ari-sutu-apimix-240-gr-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-dogal-petekli-karakovan-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-organik-bitlis-karakovan-bali-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-organik-polen-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-petekli-cicek-bali-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arican-suzme-cicek-bali-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-3-kg-oxi-beyaz-extre-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-3-kg-oxi-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-4-kg-dag-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-45-kg-oxi-gucu-etkili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-45-kg-oxi-gucu-etkili-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-55-kg-oxi-ekstra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-55-kg-oxi-ekstra-hijyen-renklilere",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-6-kg-dag-esintisi-beyazrenkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-7-kg-dag-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-matik-7-kg-dag-esintisi-renkler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-1430-ml-22-yikama-febreze-etkili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-1625-ml-25-yikama-ekstra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-975-ml-15-yikama-dag-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-16-yikama-parlak-re",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-16-yikama-parlak-si",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-18-yikama-renkli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-18-yikama-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-29-yikama-renkli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-29-yikama-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariel-sivi-camasir-deterjani-42-yikama-parlak-re",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-beyaz-peynir-450-gr-inek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-bpeynir-600-gr-inek27",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-izmir-tulum-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-tam-yagli-erzincan-tulum-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-trakya-eski-kasar-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ariste-van-otlu-peynir-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arizona-blueberry-white-tea-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arizona-green-tea-honey-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arizona-iced-tea-peach-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-krem-20-ml-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-krem-20-ml-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-efsane-tras-kopugu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-bicak-kullan-at-t2-pro-5-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-bicak-kullan-at-t3-pro-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-bicak-kullan-at-t3-pro-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-bicak-kullan-at-tek-bicak-5-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-200-ml-black-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-200-ml-cool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-250-ml-gold-power",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-255ml-black-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-255ml-cool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kolonyasi-cool2502-avantaj-pk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-kremi-100-ml-cool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-sonrasi-krem-50-ml-comfort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-men-tiras-sonrasi-krem-50-ml-cool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-60-ml-extra-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-60-ml-soft-touch",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-degerli-yaglar-krem-60-ml-zeytinyagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-150-ml-avakado-aerosprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-300-ml-naturel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-prebiyotik-250-ml-badem-sutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-prebiyotik-250-ml-pirinc-sutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-prebiyotik-250-ml-yulaf-sutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-nem-krem-prebiyotik-60-ml-badem-sutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-tjel-200-ml-extra-sensitive24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-tjeli-200-ml-anti-irritation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/arko-ultramed-40-gr-cilt-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/armelit-koku-giderici-250-ml-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/armut-frenk-armutu-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aroy-d-hindistan-cevizi-sutu-165ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aroy-d-hindistan-cevizi-sutu-400",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-glow-cars-fs-135",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-jole-sticker-dp-005",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-kelebek-baykus-pms-900",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-puffy-sticker-gulen-yuz-pm-933",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-renkli-fosforlu-mini-yildizlar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-barbie-brb-010",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-disney-mickey-ds-017",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-giraffe-epoxy-gulen-yuz-yb-001",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-giraffe-epoxy-kalper-yb-003",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-giraffe-epoxy-yildizlar-yb-002",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/artikel-sticker-pjmasks-pjm-010",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asi-kunefe-folyolu-2li-kutu-serbeti-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asi-kunefe-folyolu-standart-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asi-kunefe-folyolu-tekli-serbetli-125-gr-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asi-kunefe-tekli-katmer-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asl-clipper-cakmak-gazi-300ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asl-koko-kisa-mutfak-cakmagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asl-koko-mutfak-cakmagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asl-posetli-kurdan-1000li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asl-vmax-kurdan-200lu-tuzluklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aslindagazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aspara-gusto-kuskonmaz-hardalli-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aspara-gusto-kuskonmaz-naturel-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asperox-sprax-bulasik-mak-tablet-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asperox-yuzey-temizbahar-esintisi-25-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asperox-yuzey-temizleyici-ask-masali-25-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asperox-yuzey-temz-temizligin-kokusu-25-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/astra-utu-kolasi-500-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asy-grande-chef-sekerlik-hasirkelebek-erz-26",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/asya-luks-kurdan-400-ad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-aku-takviye-kablosu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-doseme-temizleyici-kokulu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-hizli-cila",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-jant-temizleyici-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-lastik-temizleyici-ve-parlatici-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-profesyonel-hizli-yapistirici-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/autokit-torpido-bakim-sutu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avokado-baby-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avokado-ekonomik-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avokado-taze-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-200-ml-cilekgul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-200-ml-elmaadacayi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-200-ml-limon-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-200-ml-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-6200-ml-cilekgul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-6200-ml-elmaadacayi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-6200-ml-limon-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avoya-soda-6200-ml-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avsar-c-plus-maden-su-200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avsar-c-plus-maden-su-200-ml-mandalina",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/avsar-c-plus-maden-su-6200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo-150-ml-apollo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo-150-ml-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo-150-ml-jungle-fresh-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo-150-mlleat-cook",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo150-ml-africa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo150-ml-dtemptation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo150-ml-epic-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deo150-ml-ice-chill",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deorollon-50-ml-africa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-deorollon-50-ml-dark-temptation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-dus-jeli-300-ml-blue-lavender",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-stick-black-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/axe-stick-leathers-cook-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aydinoglu-kestane-sekeri-700-gr-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aytug-desenli-bambu-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aytug-desenli-modal-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aytug-erkek-corap-modal-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/aytug-neon-havlu-suba-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ayvalik-cekirdeksiz-yesil-zeytin-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/baby-lips",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-100-cicek-ihlamur-40gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-damla-sakizi-1-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-defne-yapragi-6-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-dolma-bahari-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-gajun-baharati-55-gr-dunya-lezzetleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-hindistan-cevizi-35-gr-tuzluk-kapak-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-kisnis-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-kolay-etsiz-cig-kofte120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-mor-reyhan-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-sarimsak-toz-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-sogan-toz-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bagdat-tane-sumak-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahar-hindi-enginarli-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahar-hindi-tantuni-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-beyaz-peynir-serpme-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-burger-peyniri-dilimli-200-gr8li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-labne-peyniri-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-labne-peyniri-3180gr-firsat-paketi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-rende-peynir-200-gr12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-suzme-peynir-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-suzme-peynir-250-gr-probiyotik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-tereyag-150-grfolyo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-tereyag-200-gr-folyo-18",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bahcivan-tost-peyniri-350-gr-dilimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/baklavalik-yufka-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bal-kabagi-dilimlenmis-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balikci-ve-oglu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-draje-cikolata-kahveli-60-gr-sutlubitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-draje-sutlu-cikolata-60-gr-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-draje-sutlu-cikolata-60-gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-gala-tane-find-cikolata-360-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-grandis-butun-antep-fistik-sutlu-cikolata-80",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-grandis-butun-badem-sutlu-cikolata-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-grandis-butun-findik-sutlu-cikolata-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-sargili-madlen-kutu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin-sutlu-6li-kasik-cikolata-48-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balin1967-lorro-mix-5-cesit-cikolata-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balocco-kup-gofret-250-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-df4283-frozen-0-6li-balon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-fenerbahce-2li-kopuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-flut-kopuk-blister",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-kopuk-tabancasi-blister-isiksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-kucuk-asorti-poset-balon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-lbs0742-besiktas-6li-balon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-lpj4155-pijamaske-6li-balon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balonevi-parti-balonlari-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balparmak-cicek-bali-350-gr-cit-kapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balparmak-suzme-cicek-bali-650-gr-30",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/balparmak-yayla-szm-ccek-bali-850-gr-90gr-cam-hdy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/baltali-keci-kefir-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/baltali-keci-tereyag-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-cimbiz-emoji",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-dis-fircasi-cocuk-flip-flap-5-yas-ustu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-dis-fircasi-nico-tea",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-dis-fircasi-seti-akrobat-junior",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-dis-fircasi-soft-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-emoji-kum-saatli-cocuk-dis-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-sac-fircasi-bamboo-228",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banat-sac-fircasi-emoji",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bando-hamsi-vide-1000-gr-temizlenmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bando-karides-tereyagli-guvec-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bando-mezgit-fileto-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bando-mezgit-pane-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bando-panga-fileto-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banduff-lsns-rafadan-tayfa-yarabandi-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-acili-kebap-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-baget-kg-ktb-175",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-bonfile-kg-ktb-165",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-but-kg-kalcali-ktb-183",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-but-kg-kalcasiz-tb-173-174",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-citir-pilic-kanat-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-citir-pilic-kupleri-225gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-citir-pilic-parmak-225gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-dondurulmus-baget-1000gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-dondurulmus-bonfile-1000gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-gogus-kg-tb-167-168",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-hindi-kangal-sucuk-420gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-hindi-mangal-sucuk-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-izgara-kanat-kg-b-tb-365",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-izgara-kanat-kg-ktb-185",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-izgara-tava-kg-k-tb-191",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-kanat-kg-yaprak-12091",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-acili-fajitsa-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-burger-300-gr2418",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-but-sarma-kg-btb-178",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-gurme-sosis-350-gr-aci-bar-cesni",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-jumbo-sosis-250-gr-cheddarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-kg-firinlik-soslu-bpilic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-kofte-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-kokteyl-sosis-360-gr-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-kori-soslu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-mini-jumbo-sosis-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pilic-yaylasoslu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/banvit-pirzola-kg-kt-181",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-250-gr-tortellini-peynir-formagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-400-gr-glutensiz-spaghetti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-400-gr-tam-bugday-burgu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-400-gr-tam-bugday-kelebek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-400-gr-tam-bugday-kucuk-kalem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-400-gr-tam-bugday-spaghetti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-500-gr-deniz-kabugu-makarna",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-500-gr-fettucine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-500-gr-mini-midye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-500-gr-spagetti-yassi-bavetta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-sosu-190-gr-pesto-genovese",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-sosu-190-gr-pesto-rosso",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barilla-makarna-sosu-190-gr-pesto-siciliana",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/barni-kek-150-gr-cikolata-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-1-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-1-bebek-sutu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-2-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-2-devam-sutu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-3-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-3-devam-sutu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-4-800-gr-cocuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-4-devam-sutu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-gold-1-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-gold-2-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-gold-250-gr-sutlu-balli-irmikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-gold-250-gr-sutlu-pirincli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bebelac-gold-3-800-grcocuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-keciboynuzu-propolis-ham-bal-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-on-the-go-propolis-keciboynuzu-hb-cocuk-77",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-propolis-ari-sutu-ham-bal-karisimi-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-propolis-ari-sutu-ham-bal-karisimi-190-gr-coc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-propolis-damla-20-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beeo-shot-gingseng-cocuk-propolis-karamurver-kboy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bemtat-organik-bugday-unu-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bemtat-organik-ekmeklik-un-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bemtat-organik-wafflepankek-karisimi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/benefisha-sushi-sarma-hasiri-242405-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bennatur-camping-fruits-200-ml-port-man-s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bennatur-fruit-punch-200-ml-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/benorganic-visne-suyu-946-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/benorganik-kayisi-sefelma-suyu-946-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/benorganik-uzum-suyu-100-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/benorganikvisne-suyu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bepanthol-baby-pisik-onleyici-merhem-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bepanthol-f-losyon-200-mlintensive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bepanthol-lipstick-gunes-koruyucu-dudak-bakim-kre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bepanthol-onarici-bakim-merhemi-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bergaz-ezine-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/berrak-sarimsak-tursusu-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beslen-yumurta-30lu-m-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-bayan-corap-penye-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-bayan-sneakers-noktali-simli-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-dargede-bayan-sneakers-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-desenli-soket-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-bambu-sneakers-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-bambu-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-koton-kisa-konc-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-koton-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-modal-lastiksiz-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-corap-modal-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-erkek-modal-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-ince-jakarli-corap-bayan-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-jakarli-bayan-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-kadin-corap-bambu-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-kadin-corap-bambu-sneakersyz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-kadin-corap-modal-sneakers-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-kadin-corap-neseli-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-koton-babet-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-lakost-kabartma-corap-bayan-patik-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-lastikli-bayan-patik-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-licrali-jakarli-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-nopeli-bayan-sneakers-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-tah-patik-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/besta-tenis-bayan-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bestacemberli-kisa-konc-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beta-beyaz-cay-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beta-dunya-caylari-50-gr-nar-cayi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazoz-250-ml-6-li-reyhan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazoz-250-ml-6-li-zencefil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazoz-250-ml-reyhan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazoz-250-ml-zencefil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazoz-sade-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazozu-sade-6-li250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazozu-turuncgil-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beyoglu-gazozu-turuncgil-6-li250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/beypazari-maden-su-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bezelye-sultani-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biber-cin-aci-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biber-gul-kg-tursuluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biber-kapya-yesil-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biber-koy-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biber-sivri-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bifa-nostalji-gofret-125-gr9",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/big-babol-sakiz-fantastic-world-unicorn-eggs-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/big-babol-sakiz-fun-game-64-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/big-babol-sakiz-magic-cubes-25-gr-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/big-babol-sakiz-shakies-26-gr-cilekcuzdan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/billur-tuz-140-gr-deniz-tuzu-degirmen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/billur-tuz-140-gr-himalaya-tuzu-degirmen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/billur-tuz-200-gr-himalaya-tuzu-degirmen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/billur-tuz-iyotlu-sofra-tuzu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bin-dokuzyuz-seksen-dort-george-orwell",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-fresh-1-lt-pembe-dusler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-fresh-yuz-tem-1-lt-ferah-evim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-fresh-yuz-tem-25-lt-ferah-evim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-fresh-yuzey-temizleyici-25-lt-lovely-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-15-kg-renkli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-15-kg-ultra-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-6-kg-renkli-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-sivi-2145-ml-parfumsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-sivi-2145-ml-renkli-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-matik-sivi-2145-ml-ultra-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-oxyjen-dogal-sivi-deterjan-1170-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-oxyjen-sivi-deterjan-1950-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-oxyjen-soft-1320-mlyaseminbeyaz-cay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-sivi-deterjan-3lt-renkli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-sivi-deterjan-3lt-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-soft-1-lt-sensitive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-soft-1440-mlaskla-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-soft-1440-mlmanolya-bahcesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bingo-soft-konsantre-1440-ml-kuzumun-kokusu-oglum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-botanic-oils-samp-360-ml-argan-yagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-botanic-oils-samp-360-ml-isirgan-tohumu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-collagen-samp-360-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-men-samp-360-mlforte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-men-samp-360-mlkepek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-procyanidin-samp-360-ml24-kepek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-procyanidin-samp-360-ml24-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-procyanidin-samp-360-mlzyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-sac-krem-250ml-argan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bioblas-sac-sekil-250ml-kolejen-keratin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biomen-sampelma-sirkesi-vitamin-b3-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biomen-sampkafein-arjinin-kuruyiprsaclar-1000m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biomen-sampmentolcinko-tum-sac-tipleri-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biomen-sampsiyah-sarmisak-gumus-iyonu-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/birrr-tea-seftali-aromali-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/birrr-tea-seftali-aromali-6-li-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/birtat-aci-badem-ezmesi-60gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/birtat-el-yapimi-gofret-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-mood-125-gr-karamel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-mood-40-gr-caramel-macchiato",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-nirvana-gofret-100-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-nirvana-gofret-100-gr-hcevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-nirvana-gofret-28-gr-roll",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-nirvana-gofret-28-gr-roll-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-pia-kek-100-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-stix-cikolata-36-gr-hceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/biscolata-stix-cikolata-36-gr-karamelli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bivero-gourmet-blue-cheese-100-gr-kuflu-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bivero-gourmet-norvec-somon-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bivero-gourmet-toz-parmesan-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bjk-asma-oto-kokusu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bjk-kavanoz-kokusu-gool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bjk-kavanoz-kokusu-stad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blade-deo-deep-chill-150-ml-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blade-deo-greendram-150-ml-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blade-deo-selconfidence-150-ml-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blade-deodorant-150-mlfaster",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blue-drop-jel-el-dezenfektani-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blue-drop-jel-el-dezenfektani-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/blue-heaven-rokfor-kg-2-vak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bogurtlen-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-dondurulmus-manti-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-hgogus-fileto-kg-tb-map",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-hindi-butun-kg-4-6kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-hindi-sote-but-kg-tb-map",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-hkulbasti-500gr-map",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-hust-kanat-kg-incik-tb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-karisik-pizza-820-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-milfoy-hamuru-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bolca-pismis-but",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/boombastic-hcevizli-sut-cikolata-bar-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/boombastic-yfistikli-cikolata-bar-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bounty-cikolata-bar-57-gr-h-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bounty-soft-kurabiye-biskuvi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-brillent-jel-2-42-gr-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-brillent-jel-2-42-gr-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-brillent-jel-42-gr-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-deluxe-duopack-manolya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-power-aktiv-cam-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-power-aktiv-duo-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-power-aktiv-duo-pack-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-wc-jel-700-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/bref-wc-jel-700-ml-flower",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/brita-marella-xl-akilli-surahi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/brokoli-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/brokoli-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-4-tahilli-tavuk-salatasi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-arrabbiata-sos-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-bezelye-konserve-830-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-bitirim-acili-ketcap650-gr-mayonez550",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-butun-mantar-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-cicek-bamya-konservesi-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-dilimli-mantar-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-dogranmis-domates-560-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-domates-biber-salcasi-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-domates-dogranmis-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-domates-rendelenmis-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-domates-salcasi-600-gr-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-domates-suyu-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-enginar-300-gr-kalbi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-enginar-300-gr-mini-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-feslegenli-pizza-sosu-580-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kahvaltilik-sos-320-gr-aci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kahvaltilik-sos-320-gr-tatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kapari-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-ketcap-aci-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-ketcap-tatli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kurutulmus-domates-100-gr-doypack",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kurutulmus-domates-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-kurutulmus-domates-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-mas-fasulyeli-tavuk-saltasi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-mayonez-top-down-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-meksika-fasulyeli-tavuk-saltasi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-meksika-fasulyesi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-meksikan-sos-300-gr-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-patlican-salatasi-560-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-salsa-sos-300-gr-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-sarimsak-konservesi-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-soslu-meksika-fasulyeli-tavuk-saltasi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-tatli-aci-sos-300gr-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-yakan-sos-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-zeytinyagli-enginar-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burcu-zeytinyagli-yaprak-sarma-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-enerji-icecek-250ml-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-enerji-icecek-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-gold-enerji-icecek-250ml-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-gold-enerji-icecek-4-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-gold-enerji-icecek-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burn-zero-peach-enerji-icecek-4-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burpak-bone-100-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/burpak-galos-100-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/buyulu-sembol-masal-zamani-melez-prenses-2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cafe-crown-sicak-cikolata-185-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cafe-crown-toz-salep-15gx10x12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caffeo-12-kahve-filtresi-80-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cagdas-renkli-cam-cerezlik-kalpyildiz-model",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cagla-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calgon-kirece-karsi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calgon-konsantre-kirece-karsi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-hafif-bi-mayonez-zeytinyagli-355-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-ketcap-600-grmayonez-540-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-mayochup-405-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-peri-peri-sos-245-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-petso-mayonez-235-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-sriracha-aci-sos-255-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-sriracha-mayonez-240-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/calve-truflu-mayonez-235-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camlica-gazoz-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camlica-gazoz-25-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camlica-limonlu-25-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-1-lt-pencere-matik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-1-lt-pencere-matik-sirkeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-500-ml-dusakabin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-500-ml-toz-al-ahsap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-robo-sil-yuzey-temizleyici-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-robo-sil-yuzey-temizleyici-floral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/camsil-yer-sil-2-lt-floral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canaci-kahramanmaras-camani-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canada-dry-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-junior-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-maxi-30-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-maxi-plus-25-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-midi-30-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-xlarge-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-bebek-bezi-jumbo-pk-xxlarge-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canbebe-islak-havlu-56-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cancan-patates-cipsi-130-gr-kibrit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cancan-patates-cipsi-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-23lu-bel-bant-l-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-23lu-bel-bant-m-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-23lu-bel-bant-xl-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-24lu-buyuk-l-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-24lu-ext-buyuk-xl-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-24lu-orta-m-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot-orta-medium-9-adetx",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-emici-kulot8-extra-large-8-lix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-kids-pants-4-7-yas-9-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-kulotlu-mesane-pedi-l-9-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-mesane-pedi12-kucuk-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-ultra-mesane-12ad12-kucuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/canped-vucut-temizleme-havlusu-48li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/capanoglu-pastirma-90gr-cemensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-limonata-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-limoncilek-1-lt-cool-pink",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-limonnane-1-lt-cool-mint",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-atom-tetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-destek-karisik-tetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-pulpy-karisik-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-pulpy-mandalina-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-pulpy-portakal-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-pulpy-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-1-lt-visnelim-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-15-lt-pulpy-portakal-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-200-ml-destek-karisik-tetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-atom-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-destek-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-karisik-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-kayisi-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-portakal-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-seftali-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-250ml-visne-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-su-330-ml-pulpy-portakal-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-suyu-6200-ml-atom",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-meyve-suyu-6200-ml-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cappy-ramazan-serbeti-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/captan-america-yara-bandi-10-lu-ince-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/carelly-sac-fircasi-fon-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/carte-dor-selection-gold-800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-ant-bit-kay-6x850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-barista-850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-cil-cik-van-6x650-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-cil-cik-van-6x850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-kaymak-6x850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-sws-krml-muz-6x850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-cls-vis-bit-kay-6x850-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-ndselkaramel-krby-6x800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-ndselmyv-sol-6x800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cartedor-ndseltatli-ruya-6x800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/casa-mexico-aci-biber-sos-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/casa-mexico-truf-mantari-aci-sos-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cavalier-sekersiz-cikolata-44-gr-sutlutablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cavandish-seker-175-gr-harvey-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-bardak-cay-25li-suzen-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-dokme-cay-1000-gr-rize-personel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-dokme-cay-400-gr-organik-hemsin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-suzme-sade-yesil-cay-40-gr-25li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-tomurcuk-cay-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/caykur-yesil-cay-125-gr-organik-zumrut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cemalim-klasik-beyaz-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cengiz-pismaniye-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cerezza-cips-117-gr-mangal-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cerezza-cips-170-gr-sinema-peynir-sogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cerezza-dev-cips-145-gr-yer-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cerezza-dev-cips-150-gr-baharatli-domates-sosu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cevizli-tulum-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cheesecake-oreo-425-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cheetos-cips-15-gr-citircik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cheetos-cips-60-gr-3lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-asia-gari-shoga-kavanoz-zencefil-tursusu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-asia-gulutensiz-soya-sosu-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-asia-istiridye-sos-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-asia-teriyaki-sos-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-cin-eristesi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-kapari-cicegi-185-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-panko-ekmek-kirintisi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chefline-soya-sosu-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chokella-tahil-gevrek-280-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chupa-chups-cildirtan-lez-15-gr-cil-misket-lim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chupa-chups-fizzy-drinks-15-grportkola",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chupa-chups-melody-cola-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/chupa-chups-seker-29-gr-lolipop",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-gouda-kbiberli-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-gouda-kimyonlu-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-gouda-sade-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-gouda-sriracha-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-gouda-truffle-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-grana-padano-parmesan-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-grana-padano-parmesan-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-mascarpone-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-parmesan-100-gr-toz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cibus-parmesan-100-gr-tozcam-tuzluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-dogal-sprey-750-ml-banyo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-jel-zemin-uzmani-750-ml-b-sabun-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-1500-ml-amonyakli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-450-ml-ultra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-500-ml-frezya-ve-muge",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-500-ml-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-675-ml-ultra-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-750-mldoganin-gucu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-konsantre-sprey-70-ml-cok-amacli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-konsantre-sprey-70-ml-ultra-yag-cozucu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-sprey-750-ml-amonyakyedek-serum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-sprey-750-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-krem-sprey-750-ml-limonyedek-serum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-perfect-power-sprey-435-ml-kirec-pas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-powershine-sprey-750-ml-banyo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-powershine-sprey-750-mlyedek-serum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-ultra-hijyen-alkol-bazli-sprey-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-ultra-hijyen-yuzey-temizleyici-mendil-45li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cif-ultra-serum-70-ml-mutfak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cilek-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cilek-kiz-dergisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-aktif-kopuk-600-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-dezenfektan-banyo-tem-750-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-dogalguc-kir-ve-kirec-coz750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-dogalguc-kiryag-coz750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-lavabo-acici-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cillit-bang-tuvalet-temizleyici-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cipo-karabugday-patlagi-88gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cire-aseptin-krem-30-ml-avokado",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cire-aseptin-krem-30-ml-papatya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-domates-rendesi-580-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-irmik-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-kalem-makarna-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-100-nar-suyu-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-aycicekyag-2lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-beyaz-un-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-borulce-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-domates-salcasi-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-dut-pekmezi-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-elma-sirkesi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-galeta-unu-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-haslanmis-bezelye-580gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-haslanmis-fasulye-580gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-haslanmis-nohut-580gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-hindistan-cevizi-rendesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-keciboy-pekmezi-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-kuru-bezelye-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-misir-unu-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-nar-eksisi-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-soya-fasulyesi-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-soya-unu-400gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-tam-bugday-unu-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-tarhana-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/city-farm-organik-uzum-sirkesi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-crape-65-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-crepe-250-gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-w-master-mini-120gr-ciko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-w-master-mini-120gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-cube-200gr-cikopst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-cube-200gr-findik-pst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-120-gr-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-250-gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-65-gr-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-65-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-65-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cizmeci-time-wafer-master-65-gr-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clasigo-afistikli-cikkapbar-dondurma-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clasigo-bitter-cikkapbar-dondurma-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-350-ml-cool-sport-mentol-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-350-ml-komple-bakim-viamin-kop-kepeg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-350-mlronaldo-ozel-seri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-350180-ml-cool-sport-mentol-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-485-ml-hizli-stil-2-in-1-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-485-ml-komur-etkili-yogun-arindirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-485-ml-sac-dok-karsi-women",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-485-ml-yumusakparlak-2in1-women",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-2si1-arada-hizli-sitil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-3in1-arindirici-komur",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-3in1-ferahlatici-ment",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-gunluk-arinma-ve-ferahlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-makferyagli-sac-derisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-350-ml-yogun-arindirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp-men-500-ml-cool-sport-menthol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-180ml-sac-dok-karsi-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-180ml-sac-dokulmelerine-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-bitkisel-sentez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-kil-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-nem-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-sac-dok-karsi-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-sac-dokulmelerine-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-samp350-ml-yumusak-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-sampuan-600-ml-bitkisel-sentez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clear-sampuan-600-ml-komple-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clin-cam-tem-500-ml-sirke-parlakligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clipper-cakmak-gazi-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clipper-mini-tube-cakmak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/clonex-el-dezenfektani-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-200-ml-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-250-ml-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-6200-ml-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-light-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-light-250-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-light-6250-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-lime-250-ml-zero-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-sekersiz-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-sekersiz-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-sekersiz-6200-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-sekersiz-6250-ml-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coca-cola-zero-250-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cocomax-hindistancevizi-suyu-350-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coffee-mate-kahve-kremasi-100-gr-eko-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/coffee-mate-kahve-kremasi-500-gr-eko-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cold-brew-hazelnut-findikli-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cold-brew-vanilya-coffee-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-360-dis-fircasi-11-prorelief-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-barbie-batman-cocuk-dis-macunu-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfirca-11-mikro-hassas-12-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfirca-360-siyah-tekli-orta-io",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfirca-360-visible-white-io",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfirca-super-black-tekli-dis-firca-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-360-advanced-optic-white-single",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-360-advanced-single",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-360-interdental-single-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-mikro-ince-compact-single",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-mikro-ince-gelismis-tekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-neo-2548-bristles-firca-kili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dfircasi-twister-single-tb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-11-mikro-ince-hasdiseti-b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-21-extra-clean",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-21-extra-clean-colors",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-cocuk-smiles-6-yas12-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-super-flexi-siyah-tekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-uclu-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-fircasi-zigzag-charcoal-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-ipi-50mt6-profdis-eti-sagligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-mac-60-ml-florursuz-naneli-3-5-yas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-mac-75x2-ml-triple-acti-xtra-wh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-50-ml-optic-white-pari-beyazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-50-ml-optik-beyaz-aninda-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-50-ml-total-gelismis-beyazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-50-ml-total-prof-ak-etki-i",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-50ml-total-prof-aktif-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-extra-natural-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-hassasiyetdis-eti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-max-white-kalici-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-misvak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-optik-beyaz-aktif-kom",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-optik-beyaz-aninda-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-optik-parildayan-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-75-ml-optik-white-kilmineral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-optic-white-pari-bey-50x2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-total-a-komur-pro-tem-50-ml-i",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macun-total-a-komur-pro-tem-75-m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-125-ml-uclu-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-40-ml-florursuz-0-2-yas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-natural-ext-alu-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-natural-extaktif-komur",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-sensifoam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-total-gelismis-beyazlik-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-total-pro-dis-eti-sagl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75-ml-visible-white",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75ml-optik-beyaz-expert-white",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunu-75ml-total-gelismis-nane-temiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dis-macunucharcoal-clean-2x75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dmac-75-ml-barbiespiderman12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dmacunu-50-ml-minions-cocuk6-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-dmacunu-cocuk-set",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-foam-soft-siyah-tekli-dis-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-maksimum-anti-curuk-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-aktif-komur-dis-macunu-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-charcoalzigzag-charcoal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-expert-complete-75ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-kahv-cay-tutun-5050-ml-ikili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-kahve-cay-tutun-kul-125-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-kahve-cay-tutun-kullanan-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-optic-white-oxygen-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-plax-250-ml-nane-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-plax-250-ml-optik-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-plax-500-ml-nane-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-plax-500250-ml-agiz-bak-suyu-firsat-pk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-plax-agiz-bakim-suyu-500-ml-caylimon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-smiles-cocuk-dis-macunu-dis-firca-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-total-gelismis-beyazlik-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-uclu-etki-125-ml-x2-ikili-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-visible-white-75-ml-ec-colors-tekli-firc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/colgate-zero-peppermint-seffaf-dis-macunu-98-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-aluminyum-folyo-30cm-x-15m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-buzdolabi-poseti-20x30cm-kucuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-buzdolabi-poseti-24x38cm-orta-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-buzdolabi-poseti-30x45cm-buyuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cift-kilt-buztorb-19x25cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-tor-buzgulu-limon-kokulu-55x60cm-orta-b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-tor-buzgulu-limon-kokulu-65x70cm-buyuk-b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-40x47cm-kucuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-55x60cm-orta-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-65x80cm-buyuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-bahcickok-40x47cm-kucuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-elma-kok55x60cm-orta-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-kavkok65x80cm-buyuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-profes-72x95cm-battal-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-cop-torbasi-profes-80x110cm-jumbo-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-eko-buzgul-cop-torbasi-van-kok-55x60cm-or",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-eko-buzgul-cop-torbasi-van-kok-65x70cm-bu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-evcil-hayvan-kaka-torbasi-kit-25cm-x-38cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-evcil-hayvan-kaka-torbasi-yedek-25cm-x-38cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cook-strec-film-30cm-x-15m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-ask-atesi-24x120ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-classico-cikolata-120ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-classico-kaymak-120ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-deli-gul-24x125ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-disc-milka-130-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-disc-oreo-130ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-disc-oreo-140-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cornetto-disk-van-karamel-130ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-cikolata-findik-tahil-bar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-findik-yer-fistigi-kybar-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-kakao-kirmizi-mytahil-bar-17-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-kakao-muz-tahil-bar-17-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-karisik-meyve-tahil-bar-17-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-kuruyemis-m-bar-findik-kakao-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-kuruyemis-m-bar-kajuyer-fistigi-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/corny-kuruyemis-m-bar-visne-kakao-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cosby-candy-toys-5-drajeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cp-sebzeli-tavuk-corba-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/crispina-freeze-dried-cilek-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/crispina-freeze-dried-elma-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/crispina-freeze-dried-incir-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/crispina-freeze-dried-misir-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cubuk-6-li-dogum-gunu-mum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cumhuriyet-kangal-sucuk-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cumhuriyet-pastirma-90gr-secme-cemensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/cumhuriyet-sucuk-220-gr-kangal-vakumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/d-olive-sac-kremi-200-ml-renk-koruyucu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalan-fresh-490-okyanus-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalin-konst-yumusatici-1200-ml-bahar-ninnisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalin-pisik-kremi-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalin-pisik-kremi-30-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalin-sampsac-ve-vucut-300-ml-kids-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dalin-vazelin-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-200-ml-karpuzcilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-200-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-200-ml-mangoananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-6200-ml-armutkivi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-6200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-6200-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-aromali-maden-su-6200-ml-mangoananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-maden-su-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-maden-su-330-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-maden-su-6200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-su-05-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-su-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-su-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-su-330-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/damla-su-750-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-antrikot-kg-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-bacon-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-cirali-antrikot-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-dallas-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-dos-panceta-koftelik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-etlerikarkas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-fileto-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kaburga-asado-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kaburga-kemikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kirinti-kg-kemiksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kobe-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-koftelik-kaburga-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kol-kg-kemikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kol-kurek-kg-kemiksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-kusbasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-sote-kg2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dana-yurek-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danablu-rokfor-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danette-2-lim-4-lu-ciksut-70-gr4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danette-puding-375gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danino-cheese-640-gr-bebe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danino-cheese-640-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danino-disney-cilekli-yog-2-x-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danino-disney-kar-or-mey-aro-yog-290gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danissimo-elma-tarcin-125gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danissimo-kirmizi-meyveler-125-gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danissimo-limon-biskuvi-125gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-disney-hupper-cilekli-yogurt-67-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-disney-milkshake-biskuvili-188gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-disney-mshake-cilek-vanilya-188gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-hupper-cikolatali-puding-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-hupper-muz-aromali-yogurt-67-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-hupper-muzlu-puding-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-krema-200ml-bitkisel-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-meyveli-yog-400-gr-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/danone-sut-180-gr-6-li-kakaolu-sut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-160-gr-fasulyeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-160-gr-kinoa-chia-bulgur",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-160-gr-kuskuslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-160-gr-pilavli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-175-gr-kinoa-chia-bulgur",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ac-ye-ton-185-gr-4-tahilli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ayagli-ton-475-gr-hediyeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-cim-cim-karides-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-dnm-mezgit-pane-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ev-yemekleri-200-gr-past-kurufasulye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ev-yemekleri-200-gr-tavuk-etli-nohut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ev-yemekleri-200-gr-tavuk-sote",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-fish-finger-250-gr-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-fish-finger-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-fish-finger-470-gr-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-hamsi-sosyete-105-gr-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-hyemek-makarnali-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-hyemek-meksika-fasi-160g-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-hyemek-pilavli-185g-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-jumbo-karides-200-gr-donuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-jumbo-karides-300-gr-donuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-karides-400-gr-donuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-kolay-pisir-balik-kavurma-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-kolay-pisir-deniz-mahsullu-linguine-300",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-kolay-pisir-deniz-mahsullu-penne-300-gr-d",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-kolay-pisir-jumbo-karides-250-gr-tereyagl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-levrek-corba-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-mezgit-pane-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-midye-kaplama-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-norvec-dilim-somon-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-sardalya-105-gr-acili-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-sogutulmus-havyar-115-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-sogutulmus-siyah-havyar-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-280-gr-zyagli-2si-50-ind-pos",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-80-gr-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-dilim-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-dilim-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-fume-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-fume-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-marin-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-somon-marine-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-2140-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-2140-gr-light",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-2140-gr-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-280-gr-2si-50-ind-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-280-gr-light-2si-50-ind-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-280-gr-zyagli-2si-50-ind-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-375-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-375-gr-ekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-80-gr-light-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanel-ton-fileto-125-gr-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dardanelcitir-sardalya-400-gr-kaplamali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dates-cezayir-hurma-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dates-hurma-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-cafe-creme-cekirdek-kahve-500-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-crema-intense-lungo-kapsul-kahve-10lu-55",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-espresso-57-cekirdek-kahve-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-espresso-57-ristretto-kapskahve-10lu-55",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-fine-aroma-espresso-kapsul-kahve-10lu-55",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-hazir-kahve-100-gr-fine-aroma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-hazir-kahve-100-gr-rich-aroma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-lungo-crema-eleg-kapsul-kahve-10lu-55g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/davidoff-rich-aroma-espresso-kapsul-kahve-10lu-55",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/deco-eggnooddel-350-gryumurtali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dedecco-gnocchi-patates-makarna-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/deep-fresh-antibakteriyel-sivi-sabun-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/deep-fresh-dus-jeli-1000ml-kecisutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/deep-fresh-yuzey-temizleme-mendili-50-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/defans-protect-aeresol-100-ml8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/defans-sinekkovan-100-ml-losyon-su-bazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/delizia-chia-tohumu-500",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/delizia-hardal-150-gr-eski-usul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/delizia-kirmizi-kinoa-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/denizhavuz-bone",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-banyo-10-lu-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-banyo-5-li-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-lady-2-bicak-5-li-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-lady-platinum-3-lu-blister",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-samurai-3-5-li-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-samurai-platinum-3lu-tras-bicagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-tek-bicakli-10-lu-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-tek-bicakli-5-li-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/derby-tras-kopugu-200-ml-normal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-60-gece-elektrolikit-yedek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-bocek-yemi-cifte-guc12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-hamam-bocegi-yemi-max",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-karinca-yemi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-karincakiran-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/detan-maxi-bocek-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/diadermine-hydralist-gece-boyu-nem-bakimi-100ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/diadermine-liftbotology-50-ml-gunduz-kremi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-cool-1lt-lime-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-cool-310ml-lime-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-eksi-elma-cam-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-meyve-su-1-lt-100-nar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-meyve-su-1-lt-nar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-meyve-su-200-ml-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-meyve-su-250-ml-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-milk-shake-310-ml-browniecikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-milk-shake-310-ml-fistikbeyaz-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dimes-visne-cam-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dincevimsan-firca-set-ev-057",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dincevimsan-havan-orta-boy-ev-016",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dincevimsan-oklava-firca-takimi-ev-055",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dixi-bref-yuzey-temizleyici-25-ltcam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dixi-bref-yuzey-temizleyici-25-ltyaban-gulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dixi-bref-yuzey-temizleyici-900-ml-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/diyet-sepeti-kurutulmus-naturel-kivi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/diyet-sepeti-kurutulmus-naturel-portakal-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-arap-sabunu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-b-deterjan-svi-1000ml-elma-sunger-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-b-deterjan-svi-1000ml-limon-sunger-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-bulasik-deterjan-svi-4-lt-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-bulasik-deterjan-svi-750ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-bulasik-deterjan-svi-750ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-camasir-suyu-35-lt-cam-ferah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-camasir-suyu-750-ml-beyaz-sabun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-camasir-suyu-750-ml-cam-ferah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-camasir-suyu-750-ml-kar-beyazligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-lavabo-acici-jel-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-deterjan-3000-ml-renk-ve-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-deterjan-3000-ml-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-deterjan-3000-ml-yunlunarin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-beyaz-sabun-kokulu-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-lavanta-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-papatya-kokulu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-pudra-kokulu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-pudra-kokulu-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-zeyinyagli-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-sivi-sabun-zeytin-cicegi-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-toz-lavabo-acici-350-3al-2ode",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-yuzey-temizleyici-1000-ml-beyaz-sabun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-yuzey-temizleyici-2500-ml-beyaz-sabun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-yuzey-temizleyici-2500-ml-hanimeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-yuzey-temizleyici-2500-ml-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doa-yuzey-temizleyici-2500-ml-papatya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-beypazari-eriste-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-chia-tohumlu-musli-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-ciko-musli400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-goji-berry-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-kara-uzum-cekirdegi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-meyveli-musli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doga-evi-yer-keciboynuzu-ezmesi-350-gr-seker-ilave",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-beyaz-cay-portakal-cicekli-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-bitki-cay-20-li-9-otlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-buyulu-bohca-10-lu-mango-coconut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-form-cay-maydonozlimonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-gizli-bahce-bardak-cay-25-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-gizli-bahce-demlik-poset-48-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-gizli-bahce-dokme-cay-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-gizli-bahce-earl-grey-48li-demlik-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-guclu-hisset-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-ozel-cay-18li-emzirme-donemi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-probiyotik-cay-rooibos-vanilya-14-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-probiyotik-cay-yc-acai-ananas-14-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-probiyotikli-beyaz-cay-14-lu-karamurver",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-probiyotikli-siyah-cay-14-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-tomurcuk-cay-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-yesil-cay-18-li-mango-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-yesil-cay-18-li-papaya-hceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-yesil-cay-20-li-a-c-e-vitaminli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogadan-yesil-cay-20-li-chai",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogan-makarac-freeze-dr-dondkur-16gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogan-makarac-freeze-dr-dondkur-16gr-pancar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-beyaz-sirke-2000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-beyaz-sirkes-1000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-limon-sosu-500-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-1-lt-acili-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-1-lt-acili-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-1-lt-sade-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-1-lt-sade-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-300-ml-acili-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-salgam-suyu-300-ml-sade-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doganay-uzum-sirkesi-500-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogruluk-klasik-inek-peynir-600-gr-teneke",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogruluk-lezzine-dil-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogruluk-lezzine-orgu-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogruluk-lezzine-otlu-inek-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dogus-cay-bergamotlu-avantaj-paket-2540-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dolco-egg-noodle-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dolphin-kullan-at-eldiven-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dolphin-latex-muayene-eldiveni-50-li-pudrali-m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dolphin-yassi-cop-sis-50li-9-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domates-beef-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domates-kokteyl-dalli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domates-salkim-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domates-siyah-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-3240-ml-dag-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-3240-ml-kar-beyazi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-5-li-guc-okyanus-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-5-li-guc-wc-blok-duopack-cam-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-5-li-guc-wc-blok-duopack-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-7-li-puskurtme-750-ml-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-750-ml-dag-esintisi-kar-beyazligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-1850-ml-bio-active",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-750-ml-beyaz-sabun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-750-ml-bio-active",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-750-ml-kar-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-750-ml-pembe-guc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camasir-suyu-750-ml750-ml-bulsunhed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-camsuyu-sprey-450-ml-camferagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-hijyenik-kopuk-450-ml-banyo-kutup",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-hijyenik-kopuk-450-ml-mutfak-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-pratik-yuzey-sprey-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-pratik-yuzey-temizleme-mendili-45li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-250-gr-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-250-gr-lux-ejder-meyves",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-250-gr-lux-hibiscus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-250-gr-manolya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-2x50-gr-cam-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-5-li-guc-manolya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-wc-blok-5li-guc-50-gr-cam-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/domestos-yuzey-temizleyici-1500-gr-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-102-gr-flamin-hot-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-113-gr-extreme-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-113-gr-turka-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-169-gr-nacho-parti-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-26-gr-shots-aci-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-26-gr-shots-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-64-gr-nacho-aile-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doritos-cips-68-gr-taco-aile-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/doruk-un-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-cream-bar-sabun-90gr-orginal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-deo-150-ml-dogma-lotus-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-deo150-ml-dogma-coco12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-deo150-ml-invisible",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-dus-jeli-450-ml-mangobadem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-roll-on-40-gr-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-roll-on-55-gr-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-sac-bakim-kremi-350-ml-argan-yagi-onarici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp-400-ml-argan-yagi-onarici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp-400-ml-kalender-ozu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp400-ml-besleyici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp400-ml-hindistanzerdacal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp400-ml-kepek-aloe-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp400-ml-narin-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-samp400-ml-uzun-sac-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-sivi-sabun-450-ml-avakado",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-sivi-sabun-450-ml-lotuspirinc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dove-sivi-sabun-450-ml-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-dekor-cikolata-parcalari-70-gr-3-renk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-dekor-cikolata-parcalari-70-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-oetker-jelatin-6-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-pepper-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-pepper-cherry-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-pepper-zero-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-yogotella-68-gr-cileklimuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-yogotella-77-gr-kayisiliseftalili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dr-yogotella-83-gr-limonlucheesecake",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dreamies-odul-60-gr-ordekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dreamies-odul-60-gr-sigir-peynirli-mix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dreamies-odul-60-gr-somonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dreamies-odul-60-gr-tavuklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/drfrappe-findik-aromali-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/drfrappe-klasik-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-2-kakaolu-puding-yog-orman-meyve-363",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-2-si-1-arada-vanilinli-hamur-kabartma-to",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-2li-kazandibi-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-berry-hibiscus-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-creme-ole-110-gr-vanilyali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-creme-ole-88-gr-mavi-karamelli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-creme-ole-91-cikolata-parcacikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-creme-ole-99gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-creme-ole-orman-meyveli-89-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-damla-sakizli-sekerli-vanilin-3-lu-15-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-eglen-bilim-renk-degis-glazur-241-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-eglenceli-bilim-slime-254-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-ekspres-salep-14-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-ekspres-salep-18-gr-zencefilk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-ekspres-salep-4-lu-avantaj-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-glutensiz-ekmek-karisimi-291-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-glutensiz-kakaolu-kek-karisim-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-glutensiz-misir-nisastasi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-glutensiz-vanilinli-kek-karisimi-390-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-hamur-kabartma-tozu-10-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-hizli-nisasta-10-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-icilebilir-cikolata-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-irmik-helvasi-400-gr-12-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-jel-100-gr-limon-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-jel-100-gr-muz-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-jel-100-gr-visne-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-karisik-meyve-parcalari-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-kazandibi-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-kek-karisimi-496-gr-havuclu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-krem-karamel-92-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-krem-santi-150-gr-cikolatali-sos-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-caramel-macchiato-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-cikolatali-24gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-cikolatali-cilekli-24-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-cilekli-22-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-mocaccino-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-milkshake-muzlu-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-mousse-cikolatali-86-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-mozaik-pasta-262-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-muhallebi-210-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pasta-kremasi-136-gr-vanilinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pastaci-kremasi-156-gr-sade-pismeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pastaci-kremasi-55-gr-vanilyali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pirinc-unu-5-li-irmikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pizza-560-gr-mini-mozzarella-peynirli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pizza-600-gr-mini-mista-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pizza-guseppe-supreme-415-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-pizza-mista-karisik-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-profiterol-305gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-profiterol-beyaz-277-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-puding-147-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-puding-147-gr-x-3-al-2-ode-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-recelyap-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-sicak-cikolata-4lu-76-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-sicak-cikolata-tadinda-19-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-sicak-cikolata-tadinda-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-sivi-aroma-verici-38-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-siyah-kakaolu-un-karisimi-328-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-sultan-lokumu-tatlisi-315-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-tatli-sos-128-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-tatli-sos-80-gr-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-trilece-315-gr-karamelli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-trilece-360-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-trilece-karamelsupangle-hedli-458-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-tursukur-100-gr-212",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetker-un-kurabiyesi-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/droetkercool-lime-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dudomi-noodle-570gr-kori",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dudomi-noodle-570gr-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dulce-de-leche-sut-karameli-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dunya-gazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-aa-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-aa-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-aaa-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-optimum-ince-kalem-aaa-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-optimum-kalin-kalem-aa-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duracell-pil-ozel-alkalin-lr44-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/durex-karsilikli-zevk-12-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/durex-prezervatif-10-lu-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/durex-prezervatif-10-lu-yok-otesi-ultra-kaygan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/durex-prezervatif-8-li-yakin-hisset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-11-soft-sens-490-gr-sabun-d-mineral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-11-soft-sens-490-gr-sabun-greyfurt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-detox-dus-jeli-500-ml-charcoal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dual-bulgur-250-gr-mercimekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dual-bulgur-250-gr-nohutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-avakado-kil-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-fresh-cicek-tazeligi-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-fresh-okyanus-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-fresh-sensations-450-ml-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-hydro-pure-sakura-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-organik-frt-kirmizi-meyv-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-organik-fruits-vanilyahindistan-45",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-dus-jeli-theapy-argankil-450-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-fresh-sens-4100gr-sabun-argan-yag-kil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-fresh-sens-4150-gr-sabun-misket-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-fresh-sens-4150-gr-sabun-okyanus-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-fresh-sens-5750gr-sabun-okyanus-tazeligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-hydro-pure-aloevera-kalip-sabun-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-hydro-pure-dus-sabunu-sakura-cicegi-3150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-islak-mendil-50li-beyaz-sabun-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kalip-sabun-safdogal-800gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kolonya-150-ml-sprey36",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kolonya-200-ml-pvc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kolonya-400-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kolonya-400-ml-mandalina-ozlu-pvc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-kolonya-900ml-bidon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-nat-oli-5750gr-sabun-zeytinyagi-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-nat-olive-150-gr-sabun-zeytinyagi-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-nat-olive-4150-gr-sabun-zeytinyagi-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-barbunya-pilaki-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-garniturlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-kavurma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-mercimekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-nohutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-sebzeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-bulgur-pilavi-250-gr-tereyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-haslanmis-misir-3235-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-haslanmis-misir-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-pratik-kisir-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sac-kremi-kolajenbadem-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-safdogal-470-gr-sabun-argankil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-safdogal-sabun-4150-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-15-lt-hcevizorg-zeytinyag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-15-lt-kiraz-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-15-lt-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-15-lt-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-1500-ml-akdeniz-lavantasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-1500-ml-degerli-cicekler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-1500-ml-dogal-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-300-ml-akdeniz-lavantasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-300-ml-degerli-cicekler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-avokado",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-beyaz-sabun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-hceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-kiraz-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-mandgrey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-orkide",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-500-ml-zeytinyag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-degerli-cicekler1500300-ml-avanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-sivi-sabun-zeytinyagli-1500300-ml-avantaj-p",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-ssabun-300ml-dogal-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/duru-yerli-pilavlik-pirinc-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dus-banyo-lifi-yusley",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/dut-siyah-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eat-real-dereotlu-cesnili-nohut-cipsi-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eat-real-dereotlu-mercimek-cipsi-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eat-real-domates-feslg-nohut-cipsi-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ecos3-organik-sivi-sabun-500-ml-alovera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eds-kokusuz-pramit-kulluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eduscho-caffe-crema-strong-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eduscho-espresso-classic-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eduscho-esprosso-intenso-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eduscho-filtre-kahve-250-gr-strong",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eduscho-filtre-kahve-250-gr-traditional",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/egos-sac-jolesi-islak-sert-tup-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/egos-wax-100-ml6-killi-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/egos-wax-100-ml6-parlak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-ayran-1-lt-probiyoik-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-ayran-195-ml-probiyoik-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-ayran-330-gr-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-beyaz-peynir-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-cokokaymak-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dil-peyniri-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dilimli-kasar-350gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dipcips-yogurt-125-gr-aci-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dipcips-yogurt-125-gr-barbeku-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dipcips-yogurt-125-gr-cheddar-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-dipcips-yogurt-125-gr-domates-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-efsane-yogurt-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-efsane-yogurt-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-ananas-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-cilek-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-cilek-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-elma-tarcin-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-havuc-zerdacal-mandalina-290-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-kereviz-muz-ananas-290-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-orman-meyveli-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-ormanmeyveli-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-pancar-limon-zencefil-290-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-sade-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-seftalikayisi-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-kefir-yesilcay-elma-290-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-probiyotik-yogurt-400-gr-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-probiyotik-yogurt-4x100-gr-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-probiyotik-yogurt-4x100-gr-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-probiyotik-yogurt-4x100g-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-sutlu-tatli-150gr-kazandibi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-sutlu-tatli-150gr-keskul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-sutlu-tatli-150gr-smuhallebi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-yogurt-1250-gr-efsane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-yogurt-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eker-yogurt-4250gr-homejenize",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-beyaz-peynir-450-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-beyaz-peynir-suzme-peynir-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-beyaz-peynir-tyagli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-kasar-peynir-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-mavi-kuflu-peynir-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-peynir-200-gr-cubuk-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-peynir-200-gr-top-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekici-tereyag-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekin-eriste-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-biber-salcasi-660-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-bugday-nisastasi-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-bugday-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cavdar-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-ceksiz-kuru-uzum-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cezerye-40-gr-hurmali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-chia-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cig-badem-ici-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cig-kabak-cekirdegi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cig-karabugday-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cig-karabugday-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-cin-misir-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-dermason-fasulye-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-domates-salcasi-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-dut-pekmezi-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-elma-sirkesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-eriste-400-gr-tam-bugday",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-karabugday-patlagi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-karisik-salca-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-keciboynuzu-pekmezi-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-kekik-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-ketcap-280-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-ketcap-280-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-arpa-sehriye-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-burgu-ruseym-sebzeli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-burgu-tam-bugday-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-cicek-ruseym-sebzeli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-fettucine-sade-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-tam-siyez-250-gr-tel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-tam-siyez-300-gr-burgu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-makarna-tam-siyez-400-gr-kuskus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-mas-fasulyesi-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-misir-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-musli-500-gr-yulafbugdaycavdar-kar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-nar-eksisi-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-nohut-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-org-bebe-zyag-100-ml-soguk-sikim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-asma-yapragi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-cam-bali-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-cicek-bali-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-elma-suyu-konsantresi-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-hindistan-cevizi-yagi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-irmik-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-polen-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-recel-275-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-siyez-bulgur-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-yagli-sele-zeytin-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-organik-zeytin-ezmesi-210-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-pirinc-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-semdinli-bali-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-sirke-500-ml-alic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-siyez-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tahil-karisimi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tahin-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tahin-helvasi-antep-fistik-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tahin-helvasi-mozaik-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tahin-helvasi-sade-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tarhana-bebek-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-tbugday-unu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-uzum-pekmezi-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-uzum-sirkesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-yesil-mercimek-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-yesil-zeytin-kirma-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ekoloji-yulaf-ezmesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/el-gato-soguk-demleme-200-ml-cool-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/el-gato-soguk-demleme-200-ml-hibiscus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elbipak-bardak-alti-desenli-50-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-724-300-ml-belirgin-bukleler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-724-300-ml-elektriklenmeyi-onleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-724-300-ml12-onarici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-dogaenerjisi-sup-sac-kremi-170ml-avokado",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-sac-kremi-350-ml-brezilya-keratin-terapis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-400-ml-brezilya-keratin-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-argan-yagihibiskus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-avakadouzum-cekirdegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-esmer-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-guclu-ve-parlak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-hindistan-ceviz-yagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-ipeksi-yumusaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-onarici-ve-yeniden-yapbak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-sac-dokulmelerine-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-samp-500-ml-saglikla-uzayan-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-skremi-400-ml-guclu-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-skremi-400-ml-onarici-ve-yeniden-yapbak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-skremi-400-ml-sac-dok-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-skremi-400-ml-saglikli-uzayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elidor-skremi-500-ml-esmer-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-chocoeggy-asetat-kutu-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-chocoeggy-happy-easter-10x200-gr-glutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-chocoeggy-metal-kutu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-cikolata-18-gr-kasik-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-cikolata-60-gr-findiklicikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-cikolata-60-gr-sutlu-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-draje-cikolata-150-gr-cakiltasi-mey-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-draje-cikolata-20-gr-cakiltasi-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-draje-karisik-cik-125-gr-kahve-cekir-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-gourmet-col-beyaz-170-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikr-cik-164-gr-prem-chocollpralbyr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikr-cik-178-gr-prescol-spes-kutu-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikr-cik-216-gr-gourm-collkirkutu-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikram-cikol-170gr-gour-collec-siyah-kad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikram-lux-pralines-262gr-altin-kutub",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cik-312-gr-napoliten-kutu-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikol-170gr-gourmet-collec-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikol-200gr-royal-sut-cik-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-160gr-hearts-in-colors",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-160gr-taste-of-colors",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-166-gr-napoliten-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-187-gr-passion",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-375gr-kahv-mad-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-cikolata-430-gr-vip-madlen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-gourmet-spesiyal-170-gr-kirmizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-gourmet-spesiyal-170-gr-somon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-ikramlik-gourmet-spesiyal-170-gr-yesil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-mini-elitoloji-cikolata-200-gr-animals-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-roastbeef-250-gr-pizzalik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-seker-400-gr-karisik-dolgulu-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-seker-400-gr-minibon-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-truffle-kirmizi-kutu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-vegan-cikolata-60-gr-biterfindik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elit-vegan-cikolata-60-gr-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elite-organik-200-ml-cilkarelma-meyve-suyu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elite-organik-200-ml-sefmuzelma-meyve-suyu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elite-organik-bogurtlen-suyu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elle-vire-tereyag-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elma-arapkizi-kg-1-m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elma-fuji-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elma-grani-2boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elma-ithal-starking-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-dream-long-mukemmel-duz-serum-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-mor-maske-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sac-bakim-yagi-100ml-komple-yipranmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sac-guzellestirici-krem-150ml-kuru-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sac-guzellestirici-krem-150ml-normal-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sac-kremi-200-ml-dream-long-bye-bye-maka",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sac-maskesi-300-ml-dream-long",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sampuan-450-ml-2in1-komple-onarici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sampuan-450ml-arginine-2in1-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-smaske-300-ml-6-mucizevi-yag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-smaske-300-ml-hindistan-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-smaske-300-ml6-komple-onarici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-smaske-300-mlarginine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/elseve-sprey-2-li-bakim-iksiri-200ml-boyali-sacla",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-siyah-zeytin-430-gr-ayvalik-261-290",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-siyah-zeytin-660-ccayvalik-kirma-231-260",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-yesil-zeytin-660cc-domat-kirma-161-180",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-z-yag-750-ml-12-soguk-sizma-marasca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-zeytinyag-sizma-2lt-naturel-sizma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eminali-zeytinyag-sizma-500-ml-naturel-sizma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-ml-bw-inv-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-ml-detox-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-ml-love",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-ml-romance",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-mlnatural-bloom",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-mlnatural-breeze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-deodorant-150-mlocean-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-roll-on-50ml-naturel-breeze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-roll-on-50ml-ocean-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/emotion-roll-on-50ml-romance",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/engin-yagli-sardalya-kg-yenginer-3kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/enginar-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/enginar-temizlenmis-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/enjoy-balli-kraker",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/enjoy-muhabbet-kusu-yemi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/equal-deo-150-ml-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eren-et-dana-antrikot-fume-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eren-et-dana-kontrafile-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eren-et-fistikli-baton-salam-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erenet-cheddarli-sosis-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erenet-dana-cotto-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erik-papaz-erik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-033-lt-250812",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-05-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-1-lt-6906",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-330-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erikli-su-750-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-klozet-blogu-2x40-gr-camasir-suyu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-klozet-blogu-2x40-gr-super-karbonat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-pas-ve-kirec-cozucu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-pro-active-435-ml-paslanmaz-celil-yuzeyank",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-super-kirec-ve-kir-cozucu-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-temizlik-icin-karbonat-15-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-temizlik-icin-sirkeli-temizleyici-750-ml-sp",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ernet-tuz-ruhu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ersan-mantar-350-gr-butun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-biberiye-adet-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-cin-biber-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-citir-havuc-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-citir-marul-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-corn-salat-semizotu-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-ege-otu-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-fencel-adet-zerdacal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-feslegen-paket-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-frenk-sogani-25-gr-siniklav",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-ispanak-700-gr-ayiklanmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-kakdeniz-150-gr-kucuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-kekik-25-gr-pkt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-kisnis-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-kuzu-kulagi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-marul-miniyedikule",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-maskolin-salata-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-meksika-biber-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-mini-roka-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-patlican-mini-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-sap-kereviz-demet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-sari-ceri-domates",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/erust-taze-baharat-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/escabel-pompali-aseton-gliserin-e-vit200ml-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/escabel-pompali-aseton-gliserin-e-vit200ml-muz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/esemmat-bocek-yemi-4lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/esemmat-elektro-likt-60-gece",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/esemmat-karinca-yemi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/essweet-agda-bezi-10-mt-rulo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/essweet-agda-bezi-2-mtspatula-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/essweet-sac-boya-renk-acici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-ahenk-napoliten-cikolata-33-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-ask-tadinda-37-gr-findikli-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-balik-kraker-74-gr-perforajli-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-benimo-biskuvi-216-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-benimo-biskuvi-80-gr-beyaz-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-beyaz-cikolata-uzun-15-gr-karamelli-patlakl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-bidolu-gofret-81-gr-findikli-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-bidolu-gofret-81-gr-yer-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-browni-intense-350-gr-3lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-browni-intense-findikli-48-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-browni-kek-200-gr-kakao-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-browni-kek-40-gr-cikolata-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-bumbo-42-gr-kakaoluportakalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-100-gr-limonlu-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-158-gr-findik-ezkrem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-175-gr-findik-ezkrem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-175-gr-fistik-ezkrem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-3100-gr-sutlukremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-biskuvi-82-gr-kakao-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-kurabi-biskuvi-198-gr-findiklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-kurabi-biskuvi-198-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-kurabiye-150-gr-bademli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-kurabiye-150-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-kurabiye-150-gr-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-tuzlu-273-gr-corekotlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-burcak-tuzlu-273-gr-zeytinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-c-gofret-ask-tadinda-37-gr-karamel-macchiato",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-canga-gofret-35-gr-yerfistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cicibebe-bebek-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cicibebe-bebek-biskuvi-172-gr-sebzeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cik-60-gr-tuzlu-bademli-karamelize-bey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-200-gr-findikli-krema-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-200-gr-visne-karamel-dolgulu-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-57-gr-sutlu-karamelli-patlakli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-65-gr-sutlukare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-bar-17-gr-antep-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cikolata-beyaz-30-gr-antep-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cin-biskuvi-lokmalik-114-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-175-gr-peynirli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-50-gr-cheddar-jalapeno",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-50-gr-isotlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-50-gr-mevyes-ve-yogurtlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-50-gr-pizza",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-kraker-80-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-crax-cubuk-maltli-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-cubuk-kraker-56-gr-susamli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-etimek-h-kizarmis-ekmek-1115-gr-cavdarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-finger-biskuvi-900-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-biskuvi-225-gr-5-li-eksi-mayali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-biskuvi-40-gr-domateszeytinkekik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-biskuvi-40-gr-kakaofindikkepekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-biskuvi-40-gr-mahlepciyacorekotlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-biskuvi-50-gr-incirhurma-ve-ciya-tohum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-form-musli-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-frigo-50-gr-kakaobiskuvilisutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-gong-misirpirinc-pat-68-gr-thai-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-gong-misirpirinc-pat68-gr-eksi-krema-sogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-gong-misirpirinc-patlagi-68-gr-tatli-barbeku",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-karam-cik-45-grbitter-cilekantep-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-karam-cikolata-60-grportakalbadem54kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kombo-lokmalik-84-gr-cikolatali-hindistan-cev",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kombo-lokmalik-kremali-biskuvi-96gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kremali-244-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kremali-61-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kremali-61-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kremali-61-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-kremali-61-gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-4-tahil-ezmesi-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-granola-180gr-kakaokuruuzumfindik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-granola-200-gr-kuru-elma-kuru-dut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-granola-200-gr-visnekakaofindik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-granola-40gr-visnekakaofindik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-yulaf-bar-105-gr-3-lu-kuru-meyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-yulaf-bar-35-gr-kuru-meyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-lifalif-yulaf-toplari-36-gr-domatesli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-maximus-yer-fistikli-36-gr-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-maximus-yer-fistiklikaramelli-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-musli-bar-35-gr-kuru-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-musli-bar-35-gr-kuruyemisli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-musli-ince-cesnili-250-gr-lifalifkakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-musli-ince-zerdecal-250-gr-lifalif",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-nero-biskuvi-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-nero-biskuvi-3100-gr-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-nero-bold-biskuvi-120-gr-beyaz-kremali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pbeurre-kakao-kremali-270-gr-biskuvi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-petit-beurre-biskuvi-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-petit-beurre-biskuvi-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-petit-beurre-biskuvi-mini-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-petito-gofret-bol-sutlu-26-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pizzavic-peynir-kr-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-popkek-kek-55-gr-beyaz-kaplamalihind-ceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-popkek-kek-55-gr-bitter-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-popkek-kek-55-gr-franbuaz-soslubeyaz-kap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-popkek-kek-55-gr-kayisi-yogurtlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-popkek-kek-mini-162-gr-kayisisiyogurtlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-bebe-biskuvisi-180-gr-glutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-biskuvi-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-kek-144-gr-glutensiz-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-kurabiye-80-gr-susamlicorek-otlumah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-kurabiye-80-gr-zeytinlkekikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-kurabiye-85-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-pronot-kurabiye-85-gr-portakalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-sultani-biskuvi-175-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-sut-burger-35-gr-kakaolu-sutluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-sut-burger-35-gr-muzlu-sutluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-sut-burger-48-gr-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-tartini-turta-114-gr-frambuazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-tartini-turta-180-gr-frambuazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-topkek-kek-40-gr-h-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-topkek-mini-150-gr-kakao-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-topkek-mini-150-gr-limon-hashas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-tutku-mozaik-biskuvi-150-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eti-wanted-karamel-432-gr-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/etimek-mini-kitir-ay-cekirdekli-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/etimek-mini-kitir-corekotlu-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-cerrahi-maske-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-cerrahi-maske-10-lu-trendy-desenli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-cerrahi-maske-10lu-cocuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-cerrahi-maske-40li-tekli-sarim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-color-maske-50li-sm-bej",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-color-maske-50li-sm-pembe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-hijyen-sprey-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evony-yetiskin-bezi-orta-boy-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/evy-baby-bebek-bezi-ekonomik-pk-yenidogan-40-l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-cool-creme-511-ext-kullu-acik-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-cool-creme-611-ext-kullu-koyu-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-cool-creme-711-ext-kullu-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-cool-creme-811-ext-kullu-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-11-yogun-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-3-kkestane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-5-15-bkahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-53-sicak-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-6-35-kkahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-603-dogal-isiltili-acik-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-641-findik-kahvesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-7-3-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-71-kkullu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-743-sultan-bakiri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-8-ksari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/excellence-sboyasi-9-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/exotic-salgam-suyu-330-ml-acisiz-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-dogal-zyagli-el-ve-vucut-losyon-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tunc-dis-macunu-90-ml-aloe-vera-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tunc-dis-macunu-90-ml-ceviz-kabugu-o",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tunc-dis-macunu-90-ml-kakao-ve-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-argan-yag-250ml-el-vucut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-body-splash-blue-moon-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-body-splash-silky-touch-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-body-splash-warm-sugar-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-60-ml-karadut-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-75-ml-bambu-karbon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-75-ml-karanfil-oz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-75-ml-mismak-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-75-ml-propolis-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-90-ml-bambu-karbonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-90-ml-karanfil-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-90-ml-misvak-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dis-macunu-90-ml-nane-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-dus-jeli-600-ml-dogal-zeytinyag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-frambuazli-sac-tonigi-500ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-kolonya-400-ml-kiraz-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-limon-kolonya-1-lt-suzgecli-pump",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-limon-kolonyasi-50-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-oda-k-500-ml-hawaii-anas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-oda-k-500-ml-okyanus-fer",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-sivi-sabun-15-lt-hawaii-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-sivi-sabun-15-ml-bodrum-mandali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-sivi-sabun-500-ml-bodrum-mandali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-sivi-sabun-500-ml-hawaii",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-zyagli-kopuk-sabun-500m-bodrum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-zyagli-kopuk-sabun-500m-jkiraz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/eyup-sabri-tuncer-zyagli-kopuk-sabun-500m-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fa-deo-150-ml-fresh-pulse-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fa-deo-150-ml-sport-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fa-deo-150-ml-women-freshly-free-greyfurt-ici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-3in-1-parlatici-360ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-3in-1-parlatici-710ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-blsk-sivi-1000-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-blsk-sivi-2600-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-hepsi-bir-arada-22-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-kapsul-mak-temizleyici-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-15-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-26-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-plas-ultra-33lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-plus-27li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-plus-ultra-12-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-platinum-plus-ultra-32-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-power-spray-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-power-spray-800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-sivi-mak-temizleyici-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fairy-sivi-platinium-1500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/falim-karisik-meyve-5x5-multipak-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/falim-orman-meyveleri-5x5-multipak-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fallot-dijon-hardal-210-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fallot-tohumlu-hardal-205-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/familia-plus-tuvalet-kagidi-40li-coconut-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fanatik-gazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fanta-gazoz-1-lt-karisik-aromaliwth-green",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fanta-portakal-6250-ml-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fatih-klasik-beyaz-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fax-470-gr-sabun-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fax-470-gr-sabun-meyve-bahcesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fax-470-gr-sabun-yaseminlavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fax-ssabun-18-lt-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fb-asma-oto-kokusu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fb-kavanoz-kokusu-gool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fb-kavanoz-kokusu-stad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fd-sos-topuk-catlak-kremi-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-113-ahsap-firca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-2-li-tirnak-makas-seti12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-204-style-sac-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-208-acma-tarak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-215-capa-tarak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-ahsap-ayak-torpusu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-cimbiz-yan-uclu-renkli12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-cimbiz1-profesyonel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-dis-pastasi-90-ml-naturel12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-dis-pastasi-90-ml-smokers12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-dus-banyo-lifi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-epilasyon-yayi12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-esnek-firca24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-fondoten-sungeri-2-li24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-kare-firca24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-keratin-sac-maskesi-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-keratin-sac-sutu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-makyaj-allik-firca12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-makyaj-far-aplikator-set-5-li12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-makyaj-fircasi-5-li12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-makyaj-sungeri12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-oval-firca24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-ponza-tasi12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-sac-bakim-yagi-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-stop-filtreli-agizlik-30-lu-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-tirnak-makasi-bebek12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-tirnak-pensi12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-tirnak-torpusu-kucuk12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-vucut-losyonu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-yayli-epilasyon-aleti12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fe-yuz-kremi-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-bezelye-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nusspo-kak-findik-kremasi-snack-batir-ve-ye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nusspo-kakaolu-findik-kremasi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nusspo-sade-findik-ezmesi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nutella-1000-gr-almanya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nutella-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nutella-biscuits-10304-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nutella-biscuits-28414-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nutella-go-52-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nvea-el-kremi-75-ml-yatistirici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-250-ml-karamel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-240-ml-cappucino",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-250-ml-black-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-250-ml-iced-coconut-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-250-ml-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-250-ml-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/obsesso-soguk-kahve-250-ml-white-chocolate-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ogut-beyaz-nohut-1000-gr-11mm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-cifte-haz10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-gercek-uyum10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-hisset10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-nirvana-kayganlastici-jel-100ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-rotar10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-rotarritim-20-li-10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-sicak-temas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-ultra-hisset10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-zero-aqua10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ok-zero10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/okomesan-sushi-pirinci-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-anti-spray-ultra-defense-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-deo-stick-captain-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-deo-stick-whitewater-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-deodarant-whitewater-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-deodorant-captain-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/old-spice-dus-jeli-gel-whitewater-400ml-eimea",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olea-zeytin-300-gr-yesil-halhali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olea-zeytin-370-gr-siyah-dilimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olea-zeytin-370-gr-yesil-dilimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olea-zeytin-900-gr-siyah-kurusele-321-380",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olea-zeytin-900-gr-yesil-izgara",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-cigne-elma-47-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-limon-portakal-462-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-mini-mints-mango-port-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-mini-mints-ocaliptus-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-soft-mints-nane-47-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-stick-cilek-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-stick-mentol-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-stick-orman-meyveleri-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-zero-28-gr-limporgryefurt-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/olips-zero-28-gr-mentol-okaliptus-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-active-toz-deterjan-12-kg-oxygen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-active-toz-deterjan-45-kg-oxygen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-likit-910-ml-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-matik-12-kg-domestos-etkili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-matik-4-kg-active",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-matik-55-kg-active-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-matik-6-kg-active-oxygen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-matik-75-kg-activ-fresh-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-brokoli-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-combo-pizzali-790-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-crinky-patates-10x1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-dondurulmus-hashbrown-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-dondurulmus-patatesli-misir-kroket-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-dondurulmus-silindir-kroket-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-gurme-mini-churros-patates-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-ispanak-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-karisik-orman-meyveleri-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-organik-brokoli-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-sogan-halkali-combo-740-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feast-tost-mania-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/feat-leke-cikarici-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-185-mljapon-kiraz-cicekleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-ml-hijyen-mutfak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-ml-hijyen-salon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-ml-lavanta-konforu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-ml-okyanus-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-ml-sigara-koku-onleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-mlbahar-cicekleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-mldag-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-mljapon-kiraz-cicekleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-300-mltemiz-carsaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-platinum-300-ml-amazon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-platinum-300-ml-efsanevi-himalaya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-aerosol-platinum-300-ml-mis-mezepotamya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/febreze-yeni-araba-kokusu-2ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mama-somonlu-485gr-ekonomik-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mama-yavru-kedi-485gr-ekonomik-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mamasi-85-gr-yavru-sigir-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mamasi-party-60-gr-peynir-karisim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mamasi-party-60-gr-piknik-karisim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/felix-kedi-mamasi-sigir-etli-485gr-ekonomik-pake",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fellas-protein-bar-32-gr-tarcinkakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferfresh-beyaz-sirke-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferfresh-beyaz-sirke-2-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-80-gr-bitter-cilek-draje",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-80-gr-kahveli-mini-draje",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-100-gr-bitfind-dildrt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-100-gr-sutlu-antfis-dikdrt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-50-gr-beyoglu-antfis-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bit-portakalli-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bit-visneli-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bitantep-fistkare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bitcilymersin-tab",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bitcilymersin-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bitkurtportlimon-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-bittarcbadem-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-dark-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-sutfind-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-sutfistfindbad-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-60-gr-sutlu-turk-kahveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-80-gr-bit-port-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-80-gr-bitlim-sertkap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-80-gr-bitrenk-bug-patdraje",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferlife-cikolata-80-gr-mix-roche",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ferrero-rocher-kayan-yildiz-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-100-nar-eksisi-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-balsamik-aceto-sirke-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-balsamik-bianco-sirke-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-balsamik-rose-sirke-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-balsamik-sirke-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-elma-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-elma-sirkesi-pvc-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-elma-sirkesi-pvc-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-limon-sosu-1000-ml-uretim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-limon-sosu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-nar-eksi-sos-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-nar-eksili-sos-330ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-portakal-eksili-sos-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-uzum-sirkesi-500-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-uzum-sirkesi-pls1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fersan-uzum-sirkesi-pls2-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fibril-islak-mendil-72li-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fibril-islak-mendil-72li-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fibril-islak-mendil-72li-papatya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ilikli-kemik-suyu-480ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-iskembe-corbasi-480ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-kelle-paca-corbasi-480ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-kozlenmis-patlican-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-mandalina-komposto-312gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-paca-ilikli-kemik-suyu-480ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-paca-ilikli-kemik-suyu-480ml-jole",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-paca-ilikli-kemik-suyu-480ml-jole-kiv-sebz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-tavuk-suyu-480ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ton-baligi-2160-gr-butun-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ton-baligi-2160-grekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ton-baligi-380-gr-butun-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ton-baligi-380-gr-ekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-ton-baligi-80-gr-ekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fide-zeytinyagli-ton-baligi-2160-gr-butun-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/filiz-koftelik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/filiz-makarna-500-gr-manti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/filiz-makarna-500-gr-yassi-spagetti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/filiz-pilavlik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/findik-taze-kabuklu-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/findik-taze-kg-ayiklanmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fine-taste-pesto-sos-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finetast-hot-siacha-mayonez-333-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-blisk-mktemizleyici-tablet-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-bulasik-makine-temizleyici-sivi-2250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-deo-dag-esintisi10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-hepsi-bir-arada-22-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-hepsi-bir-arada-50-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-hepsi-bir-arada-70li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-jel-1300-ml-classic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-makine-temizleyici-250-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-40-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-50li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-80li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-max-40-li-kapsul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-max-48-li-kapsul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-max-58-li-kapsul-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-ultimate-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-ultimate-40li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-ultimate-48li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-quantum-ultimate-60li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/finish-ultimade-22-li-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-60-dakika-27-gr-ahududu-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-60-dakika-27-gr-karpuz-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-60dk-cileklime-195-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-60dk-keskin-nane-27-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-60dk-nane-195-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-cilek-aromali-135-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-nane-aromali-135-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-sensations-x-fresh-nane-27-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/first-sensations-x-fresh-seftali-karpuz-27-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fixodent-dis-protez-yapistirici-krem-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/flam-up-firin-somine-cami-temizleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/flexi-asure-kabi-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/flora-f342-lale-faras-hamarat-lastikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/flora-ft521frpembe-donerbaslikli-renkli-sap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/flora-krema-bitkisel1000-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-antep-fistigi-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-badem-sutu-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-badem-sutu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-badem-sutu-200-ml-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-cheddar-bitkisel-bazli-sari-blok-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-dereotlu-surulebilir-kivam-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-findik-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-findik-sutu-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-findik-sutu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-findik-sutu-200-ml-serkersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-hindistan-cevizi-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-hindistan-cevizi-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-hindistan-cevizi-sutu-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-mozarella-bitkisel-bazli-beyaz-blok-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-soya-icecegi-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-yulaf-sutu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fomilk-zahterli-surulebilir-kivam-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fonex-biryantin-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-izgara-zeytin-230gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-zeytin-160-gr-dilimli-yesil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-zeytin-430-gr-az-tuzlu-az-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-zeytin-ezmesi-175-gr-cesnili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-zeytin-ezmesi-175-gr-yesil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fora-zeytin-ezmesi-340-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/frambuaz-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/freeze-fresh-krema-elmacilek-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/freeze-fresh-krema-orman-meyveli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/freeze-fresh-krema-yaban-mersinlicilek-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-inisdibi-soda-200-ml-24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-inisdibi-soda-200-ml-6-li-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-inisdibi-soda-200-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-6-li-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-6-li-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-6-li-karpuz-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-6-li-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-karpuz-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresa-soda-200-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresh-n-soft-15-li-cep-mendil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresh-n-soft-antibakteriyel-15-li-cep-mendil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fresh-n-soft-islak-mendil-153-lu-antibakteriyel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fripp-meksika-fasulye-410-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam15-kg-yavru",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam17-kg-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam17-kg-somonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam300-gr-etli-tavuklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam300-gr-somonlu-sebzeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam300-gr-yavru",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-kedi-mam75-gr-dostluk-mamasi-beefchick",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-yas-kopek-mamasi-100-gr-cig-lmbcrt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-yas-kopek-mamasi-100-gr-junior-cig-chknc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-yas-kopek-mamasi-4x100-gr-cig-bf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-yetiskin-kumes-hayvanli-kuru-mama-1500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/friskies-yetiskin-kumes-hayvanli-kuru-mama-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-badem-cig-75-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-camu-camu-tozu-130-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-cek-kahve-250gr-kolombiya-cooperativa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-cekkahve-250gr-brezilya-fazenda",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-chia-tohumu-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-granola-200gr-bademvanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-granola-200gr-brezilya-cevturkkahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-granola-200gr-yerfistikcikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-ham-kakao-nibi-130-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-ham-kakao-tozu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kaju-75-gr-kavrulmus-zarsiz-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kefir-250ml-chia-tohumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kefir-ketentohumlu-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kefir-sade-250ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-keto-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kombucha-250-ml-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kombucha-250-ml-lavantali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-kombucha-250-ml-naneli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-maca-tozu-130-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-omega-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-paleo-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-probiyotik-bar-35-gr-badem-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-probiyotik-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-smoothie-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-sporcu-mix-75-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-tropikal-mix-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fropie-yerfistigi-75-gr-kavrulmus-zarsiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/frosch-buldet-750-ml-limonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/frosch-buldet-750-mlbalsam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-blueber-lemon-yaban-mers-limon-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-cranberry-lemon-kizilcikli-limon-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-kivi-pinelemon-kivi-ananas-limon-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-melon-water-lemon-kav-kar-limon-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-pass-fruit-man-lem-carmanlimon-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruizy-peach-lemon-seftali-limonata-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-cool-lime-250-ml-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-cool-lime-41-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-cool-lime-4250-ml-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-gazoz-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-gazoz-1-lt-cool-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-gazoz-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fruko-gazoz-41-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fs-tarim-530-gr-taze-bamya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fs-tarim-600-gr-domates-sosu-balikesir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fs-tarim-600-gr-taze-enginar-kucuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fs-tarim-kereviz-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fumeci-roastbeef-karabiberli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fumeci-roastbeef-kekikli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fumeci-roastbeef-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-250-ml-limon-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-250-ml-seftali-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-mango-ananas-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-1-lt-karpuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-1-lt-kavuncilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-1-lt-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-15-lt-karpuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-15-lt-kavuncilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-15-lt-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-karpuz-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-kavuncilekcan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-lemon-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-limon-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-mango-ananas-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-mango-papple-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-seftali-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-330-ml-wmelon-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-41-lt-kavuncilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-41-lt-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-6330-ml-karpuzcan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-6330-ml-kavuncilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuse-tea-soguk-cay-6330-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuumono-oto-kokusu-pinapple-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/fuumono-oto-kokusu-white-jasminbeyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gaark-gazoz-250-ml-armut-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gaark-gazoz-250-ml-frambuaz-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gaark-gazoz-250-ml-mandalina-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gagoz-klasik-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-bb-krem-light-50-ml6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-bb-krem-medium-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-besleyici-bal-temizleme-jel-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-c-vitaminli-parlaklik-verici-kagit-yuz-mas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-deo-150-ml-dogal-ferahlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-makyaj-tem400-ml-saf-temiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-nem-bombasi-goz-maskesi-yesilcayli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-nem-bombasi-kagit-maske-mavi-32gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-nem-bombasi-kagit-maske-yesil-32gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-roll-on-50-ml-saftemiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-roll-on-50-mlmen-extra-ferahlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-serum-c-vitaminli-aydinlatici-30-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-serum-hyaluronik-aleo-nemle-dolgunlastira",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garnier-yuz-temjeli-saf-temiz-3-in-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garofalo-fusilli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garofalo-fusilli-lunghi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garofalo-linguine-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garofalo-penne-ziti-rigate-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/garofalo-spaghetti-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gediz-acili-tarhana-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gediz-tarhana-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gemlik-umurbey-s-zyag-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gemlik-umurbey-s-zyagi-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gerber-organic-puff-muzframbuaz-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gezer-terlik-1361202-gondol-merdane-kislik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-gr-aci-biberli-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-gr-kekikli-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-gr-kimyonlu-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-gr-sade-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-gr-zerdecalli-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ghee-sade-yag-225-grsarimsakli-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gibson-cam-mumluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-200ml-tras-jeli-normal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue-3-6-li-besiktas-taraftar-paketi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue-3-6-li-fenerbahce-taraftar-paketi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue-3-6-li-galatasaray-taraftar-paketi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue-3-comfort-3ct6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue-3-hybrid-r8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue3-tras-bicagi-comfort-3lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-blue3-tras-bicagi-comfort-slalom-62-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-comfortglide-with-olay-yedek-bicak-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-bicak-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-makine-2-up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-power-yedek-bicak-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-prog-flexball-power-makine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-proglide-jel-200-ml-serinletici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-proglide-makine-1-up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-fusion-proglide-makine-2-up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-mach-3-start-makine-2-up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-mach3-bicak-2li-charcoal-yedek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-mach3-charcoal-makineyedek-bicak-2up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-mach3-turbo-bicak-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-mach3-turbo-makina-1up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-sensor-3-h6-makine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-series-jel-charcoal-200ml6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-series-tras-jeli-200ml-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-skinguard-bicak-2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-skinguard-makine-1-up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-venus-simply-2-bicakli-2li-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-venus-simply-2-bicakli-4lu-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-venus-simply-3-bicakli-4lu-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-venus-simply-4-bicakli-4lu-hybrit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gillette-venus-tiras-makine-breeze-2up",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-aerosol-300-ml-askin-buyusu12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-aerosol-300-ml-cicek-festivali12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-aerosol-300-ml-tropikal-floral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-aerosol-300-ml12-oryantal-oud",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-auto-sport-lavantamango-7-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-banyo-yedek-lavanta-10-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-mini-jel-70-gr-clean-linen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-mini-jel-70-gr-floral-perfection",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-msprey-500-ml-lavanta12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-nature-s-sprey-400ml12-garden-retreat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-oda-kokusu-20ml-mak-ydk-tem-carsaf-ferah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-oda-kokusu-20ml-mkn-ydk-bahar-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-oda-kokusu-20ml-ydk-bahar-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-oda-kokusu-20ml-ydk-tem-carsaf-ferah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sistem-2-li-yedek-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sistem-2-li-yedek-okyanustropikal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sistem-2-li-yedek-oryantal-out",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sistem-2-li-yedek-temiz-carsaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sistem-2-li-yedek-vanilyapeony",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sprey-yedek-lavantavanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sprey-yedek-oryantal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-sprey-yedek-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-spreyyedek-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-spreyyedek-okyanus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-otomatik-spreyyedek-oryantal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glade-refresh-hang-it-yasemin-8-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gleen-bulasik-sivisi-1-lt-lavantacayagaci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gleen-bulasik-sivisi-1-lt-limonbergamot",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gleen-bulasik-sivisi-1-lt-parfumsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sac-kremi-360-ml-aqua-revive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sac-kremi-360-mlnutriblance-repair",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sampuan-360-ml-bio-tech-in-out",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-bio-tech",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-intense-theraphy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-million-gloss",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-serum-deep-repair",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-sun-protect",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gliss-sivi-sac-kremi-200-ml-supreme-lenght",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glutensiz-ada-corekotlu-kraker-35gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glutensiz-ada-findikli-kurabiye-117-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glutensiz-ada-tuzlu-kraker-105-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glutensizada-ekmek-240-gr-baton",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/glutensizada-halka-kraker-40-gr-kinoali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goddard-s-gumus-parlatici-163-ml-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/godiva-domes-double-123-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/godiva-domes-hceviz-cik-123-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/godiva-domes-hincevizli-cik-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/godiva-signature-cikolata-52-gr-90-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/godiva-signature-cikolata-60-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokbayrak-baldo-pirinc-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokbayrak-fasulye-1000-gr-nigde-dermason",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokbayrak-kepekli-pirinc-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokbayrak-pilavlik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokbayrak-yesil-mercimek-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gokkusagi-kurbiye-kalibi-ds-118",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gold-fistik-ezmesi-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goldcare-likit-boya-siyah-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goldcare-shine-parlatici-sunger",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goldcare-standart-krem-boya-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goldcare-standart-krem-boya-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goldcare-ucgen-depolu-sunger",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goon-premium-jumbo-no-3-40li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goon-premium-jumbo-no4-34lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/goon-premium-kilot-bez-ekonomik-no4-44lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-fermente-sucuk-kg-parmak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-gold-kedi-mama-85-gr-parca-sigir-eti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-gold-kedi-mama-85-gr-parca-somon-tavk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-gold-kedi-mama-85-gr-parca-tavuk-ciger",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-kavurma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-pastirma-100-gr-cemensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-salam-kg-ispanyol-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gourmet-salam-kg-ispanyol-karabiberli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/grandor-tereyag-200-gr-tuzsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-dedicato-calamari-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-dedicato-fettucine-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-dedicato-fusilli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-dedicato-pappardelle-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-dedicato-penne-rigate-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-gnocchi-dedicato-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/granoro-lasagna-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/greendas-leke-sokucu-1-litre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/greta-kraker-zeytinkekik-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/greta-kraker-zeytinkekik-304-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/greyfurt-kanli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gs-asma-oto-kokusu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gs-kavanoz-kokusu-gool",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gs-kavanoz-kokusu-stad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gul-mum-4lu-kapsul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gul-mum-4lu-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gul-mum-bakkal-4lu-kisa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gul-mum-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-cevizli-pestil-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-findikli-pestil-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-kalem-sarma-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-muska-tatlisi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-pestil-tatlisi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gumuskale-pestil-toplari-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gurmax-parca-permesan-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gurmax-somon-fume-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gurmax-toz-permesan-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/gurvita-paca-corbasi-660-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/guymak-kuymak-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-banyo-sabunu-150-gr-dogal-ozel-se",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-granul-matik-1000-gr6-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-guzellik-sabunu-470-gr-elegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-guzellik-sabunu-470-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sampuan-500-ml-adacayikepege-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sampuan-500-ml-balince-telli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sampuan-500-ml-limonyagli-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sampuan-500-ml-tum-saclar-zeytinyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sivi-sabun-1300-ml-lavanta-ozel-seri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sivi-sabun-1500-ml-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sivi-sabun-300-ml-hindistan-cevizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haci-sakir-sivi-sabun-300-ml-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hanuta-blackwhite-gofret-220-gr-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hanuta-gofret-222-gr-t2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/happy-cotton-yilbasi-desenli-havlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-altin-ayicik-200gr-efsane-tatlar-mini-maxi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-altin-ayicik-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-altin-ayicik-80-gr-yeni-yil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-chamallows-60-grcolorballs",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-chamallows-cikolatali-62-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-cocoballs-chamallow-62-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-70-gr-fries",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-70-gr-likirr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-arabalar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-blocks",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-harfler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-my-cupcakes",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-ocean-mix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-jel-seker-80-gr-rakamlar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-kubiks-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-marshmallows-70-gr-yeniyil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-mega-party-poset-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-sound-cube-75-gr-eksi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-starmix-doypack-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/haribo-supermix-doypack-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasbahce-barbunya-fasulye-1000-gr-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasbahce-dermason-fasulye-1000-gr-8-mm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasbahce-kirmizi-mercimek-1000-gr-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasbahce-nohut-1000-gr-8-mm-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasbahce-yesil-mercimek-1000-gr-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasmandira-ghee-sade-yag-300-gr-inek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasmandira-ghee-sade-yag-300-gr-inekkecikoyun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasmandira-gobek-kasar-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hasmandira-kelle-kasar-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-ciger-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-pilic-baget-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-pilic-but-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-pilic-but-izgara-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-pilic-fileto-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hastavuk-pilic-izgkanat-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/havuc-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-033-lt-maskot",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-05-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-6033-lt-bebek-su",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-615-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-8-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayat-su-spor-kapak-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-ates-baharati-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-chili-piri-aci-hardal-sos-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-cihili-piri-fermante-aci-sos-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-hava-baharati-115-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-su-baharati-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayfene-toprak-baharati-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hayvan-ciftligi-george-orwell",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hazan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-aci-sos-150ml-gr-hot-sauce",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-hardal-445-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-ketcap-460-grmayonez-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-ketcap-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-ketcap-sarimsakli-460-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-ketcap-tatli-460-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-kizmizi-biberli-sarimsak-sosu-300-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-mayonez-215-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-mayonez-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-mayonez-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-mayonez-505-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-mayonez-light-420-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/heinz-meksika-fasulyesi-konsv-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hellmanns-hardal-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hellmanns-ketcap-460-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hellmanns-mayonez-sise-385-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-cifte-kavrulmus-tahin-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-helva-200-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-helva-200-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-helva-200-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-helvasi-kg-kakao-dovme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-tahin-275-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-tahin-500-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-tahin-630-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-tahin-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-uzum-pekmezi-375-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/helvaci-sabri-yaz-helvasi-kg-duble-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/herciger-dana-damar-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/herciger-dana-iskembe-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-6-ay-200-gr-sutlu-rezeneli-tahilli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-kasik-200-gr-sut-bal-irmik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-kavanoz-organi120-gr-armut-muz-tahilli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-organik-kavanoz-120-gr-elma-puresi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-organik-kavanoz-120-gr-elma-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hero-baby-organik-kavanoz-120-gr-sutlac",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/herz-emmantel-kg-alman",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hicret-pismaniye-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-aktif-kopuk-banyo-750ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-leke-cikarici-sprey-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-narsprin-cok-amacli-temizleyici-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-silikonlu-temizleyici-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-sirkelim-sirkeli-temizleyici-1000ml-limo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/highgenic-tozal-cam-temizleyici-1000ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hindistan-cevizi-beyaz-taze-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hindistan-cevizi-taze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-1-organik-combiotic-bebek-sutu-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-2-organik-combiotic-devam-sutu-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-2-organik-devam-sutu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-3-organik-combiotic-devam-sutu-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-3-organik-devam-sutu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-bsanft-bebek-sac-ve-vucut-banyo-jeli-400-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-islak-havlu-352-yenidogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-islak-havlu-56-li3-lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-org-gece-sutlu-elmali-tahil-bazli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-org-sutlu-meyveli-tahil-bazli-ek-gida-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-akdeniz-sebzeleri-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-armut-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-bebek-biskuvi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-cilekli-muzlu-elma-puresi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-combiotic-bebek-sutu-800-gr-no1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-combiotic-devam-sutu-800-gr-no2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-devam-sutu-600-gr-no2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-domatesli-ve-tavuklu-patates-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-elma-ve-muz-puresi-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-elmali-havuc-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-elmali-sutlac-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-erik-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-havuc-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-kayisi-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-keci-sutu-bazli-bebek-sutu-no1-40",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-keci-sutu-bazli-devam-sutu-no2-400",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-keci-sutu-bazli-devam-sutu-no3-400",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-kivili-muzlu-armut-puresi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-pirincli-ahududulu-elma-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-sebze-karisimi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-sebze-ve-tavuk-pirinc-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-seftali-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-sutlac-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-yabanmersinli-elma-puresi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organik-yulafli-tahil-bazli-ek-gida-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hipp-organorman-mey-seftmango-elmapur-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hippi-bubble-tea-250ml-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hippi-bubble-tea-350-ml-blueberry",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hippi-bubble-tea-350-ml-red",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hippi-bubble-tea-350ml-green",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hippi-bubble-tea-350ml-orenge",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-cream-gliresinli-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-cream-soft-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-cream-zeytinyagli-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-250-ml-6-ultra-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-400-ml-4-islak-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-400-ml-4-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-400-ml-5-ekstra-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-crazy-150-ml-3-islak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-crazy-150-ml-4-islak-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-crazy-150-ml-4-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hobby-jole-crazy-150-ml-5-ekstra-sert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hole-sprey-krema-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/horizon-bakkal-mum-12-cm-3lu-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/howdah-brand-dokme-cay-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-men-ultra-samp-360-ml-old-spice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sac-krem-220-ml-dermaxpro",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-samp-250-ml-mentol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-samp-250-ml-sac-dok-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-300ml-with-old-spice6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-besleyici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-derin-nem-hindistan-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-dokulme-karsiti-kafein",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-ektra-hacim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-erkek-ozel-sac-dok-karsi-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-ipeksi-yumusaklik-2in1-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-kadin-ozel-sac-dok-karsi-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-klasik-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-klasik-bakim-2in1-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-limon-ferahligi-1in1-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-limon-ferahligi-2in1-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-330-ml-nemlendirici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-21-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-besleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-dermaxpro",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-dokulmeye-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-hydration-21",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-klasik-bakim-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-limon-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-mentol-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-sac-dok-karsi-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350-ml-sac-dokkarsi-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-sampuan-350ml-mentol-ferahligi-2in1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-skremi-275-ml-d-nemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-skremi-275-ml6-besleyici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-skremi-275-ml6-kadinlara-ozel-dokkars",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hs-supreme-sampuan-300-ml-arindirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hssamp-250-ml-21-klasik-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hulala-sprey-krem-santi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hulk-yara-bandi-10-lu-ince-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/huma-organik-sade-eriste-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/huma-organik-sebze-eriste-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/huma-organik-sebze-tel-eriste-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/huma-organik-tam-bugday-eriste-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-kurabiye-55-gr-zencefilli-tarcinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-tam-tahilli-balli-kah-gevrek-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-vegan-grissini-75-gr-pancarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-vegan-kurabiye-55-gr-tahinli-pekmez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-vegan-mini-kupler-30-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-vegan-mini-kupler-30-gr-muzlu-glut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/humm-organic-vegan-puflar-30-gr-pancarli-tam-tah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/hurma-taze-kg-ekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/i-uslu-klasik-beyaz-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-alpella-sut-1-lt-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-d-vitaminli-sut-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-kefir-250-ml-kdut-aronya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-kefir-cilekli-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-kekikli-tost-kasar-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-krema-200-ml-peynirli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-labne-180-gr-kajun-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-labne-180-grzeytin-kekik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-mini-peynir-taneleri-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-pastorize-sut-yy-pet-cikolatali-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-pastorize-sut-yy-pet-cilekli-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-180-gr-cilekli-surulebilir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-180-gr-krem-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-180-gr-taze-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-200-gr-surulebilir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-suzme-500-gr-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-suzme-500-gr-yarim-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-peynir-toplari-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-probiyotik-icecek-250-ml-kmurverli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-probiyotik-icecek-250-ml-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-probiyotik-icecek-250-mlahudud-hibuskus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-1-ltuht-35-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-1lt-uht-cocuk-devam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-200-ml-organik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-400-ml-fit-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-400-ml-fit-kahveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-400-ml-fit-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-400-ml-fit-vanilyali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-500-ml-fit-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-500-ml-fit-kahveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-500-ml-fit-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-500-ml-uht-cocuk-devam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-500ml-fit-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-sut-light-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-suzme-yogurt-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-suzme-yogurt-900-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-tereyag-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-tereyag-400-gr-koy-tipi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-ucgen-peynir-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-1000-gr-tam-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-3000-gr-yyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-750-gr-organik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-750-gr-probiyotik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icim-yogurt-750-gr-yarimyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icimino-sut-180ml-cikolatali-sise-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icimino-sut-180ml-cilekli-sise-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/icimino-sut-180ml-muzlu-sise-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/idaci-kasar-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ideal-buzdolabi-poset-4-al-3-ode-2kck-2ort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ideal-buzdolabi-poset-4-al-3-ode-2ort-2byk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ideal-buzgulu-portakalli-cop-torbasi-orta-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ideal-pis-kag-buzdoalbi-pos-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ideal-pisirme-kag37cm-16-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/idealbuzgulu-cop-trb-buyuk-boy-beyaz-sabun-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/idealbuzgulu-cop-trb-orta-boy-beyaz-sabun-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ikizler-erkek-yilbasi-boxer-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ikizler-yilbasi-desenli-paspas-4575",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ikizler-yilbasi-desenli-paspas-6090",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ilighter-manyatolu-cakmak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-cok-amacli-koku-keseleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-otomotik-yedek-amber",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-otomotik-yedek-buket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-otomotik-yedek-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-otomotik-yedek-ormantus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/image-home-otomotik-yedek-pudra",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/incim-kuru-incir-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/incim-sari-kayisi-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/incir-dikenli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/incir-siyah-kavak-incir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/indirimli-kitap-1990-tl-iki-a",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/indirimli-kitap-2490-tl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/indomie-noodle-60-gr-kori-cesnili-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/indomie-noodle-70-gr-sebze-cesnili-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipana-3d-white-75-ml-caykahve-icenler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipana-3d-white-75-ml-therapy-charco",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipana-3d-white-luxe-75-ml-intense",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipana-3dw-clini-miracle-glow-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipana-pro-sensitive-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipek-dus-jeli-400-ml-orkide",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipek-dus-jeli-400-ml-ylang-ylang",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipek-sac-kremi-570-ml-kuru-yipranmis-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ipek-sampuan-450-ml-kepekli-saclar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-esmer-kup-seker-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-esmer-tekli-sargili-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-kup-seker-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-tekli-sargili-kup-seker-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-toz-seker-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-toz-seker-2000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/irmak-toz-seker-5000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ispanak-kuzu-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ispanak-taze-ispanak-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-2-si-bir-arada-105gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-2-si-bir-arada-105gr-10-lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-3-in-1-16-gr-320",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-barista-editions-classig-filt-khv-225g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-barista-editions-medium-filt-khv-225g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-barista-editions-strong-filt-khv-225g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-gold-stick-kahve-18gr10-3u-1-yumusak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-gold-stick-kahve-18gr10-3u-1arada-yogun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-kahve-100-gr-velvet-gold-kavanoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-kahve-2-gr-monarch",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-kahve-70-gr-velvet-gold-eko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-monarch-filtre-kahve-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jacobs-stick-kahve-10-16-gr-3in110lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jagler-deo150-ml-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jagler-deodorant-150-ml-sport-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jalapeno-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jalepone-biber-akdeniz-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-afrika-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-cilek-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-cola-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-karpuz-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-mini-meyve-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jelibon-tistis-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-bel-bantli-hasta-bezi-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-bel-bantli-hasta-bezi-x-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-coverdry-yatak-koruyucu-ortu-60x90-34-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-normal-emici-kulot-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-normal-emici-kulot-x-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jender-yatak-koruyucu-ortu-60x90-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jet-pvc-tenizleyici-sprey-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jif-fistik-emzesi-440-gr-simpley",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jif-fistik-emzesi-454-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jif-fistik-ezme-454-gr-extra-crunchy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-bebek-sampuan-500-ml-kral-sakir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-bebek-yag-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-bebek-yag-300-ml-aloe-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-bsac-kremi-500-ml-isildayan-parlaklik-serisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-kulak-temizlik-cubugu-200-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jj-vazelin-100-ml-parfumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jovia-organik-cikolata-findik-kremasi-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jovia-organik-findik-kremasi-200gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/jovia-organik-sutlu-cik-findik-kremasi-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/k-dunyasi-gofrik-sutlu-a-fistikli-125gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/k2r-renk-koruyucu-mendil-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kabak-dolmalik-kabak-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunturk-kahve-30-grorta-kavrdraje-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-cappucino-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-findikli-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-karamel-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-muz-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-rafine-sek-ilav-10-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-seker-ilavesiz-10-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-ice-latte-vanilya-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-karamel-latte-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyas-vanilya-latte-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-beyoglu-sutlu-findikli-cikolata-30-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-bonte-draje-cikolata-200-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-draje-cikolata-60-gr-a-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-draje-cikolata-60-gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-draje-cikolata-60-gr-kahveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-draje-cilek-bitter-cik150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-draje-yaban-mersini-bitter-cik150-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-gofrik-sutlu-fistikli-gofret-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-lavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-tambol-sutlu-bademli-cik100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahve-dunyasi-tambol-sutlu-findikli-cik100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahvegibikahve-filtre-kahve-95-gr-guclu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kahvegibikahve-filtre-kahve-95-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kandira-hindisi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kandira-pismis-butun-hindi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-abaza-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-cerkez-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-fume-cerkez-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-kaskaval-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-orgu-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-sepet-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karagol-urfa-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karasevda-btozlu-beyaz-cekirdek-135-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karasevda-tuzsuz-siyah-cekirdek-125gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karlidag-malatya-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karlidag-tereyag-tuzlu-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karnabahar-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karnabahar-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/karper-ucgen-peynir-6li-108g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kartal-fileto-ancuez-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kasai-ruzgar-cakmagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kaserei-champignon-brie-petit-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kaserei-champignon-camambert-petit-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kavram-yagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kavun-antalya-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kavun-tropik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kayisi-alyanak-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kayisi-taze-igdir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kayisili-tulum-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-akdeniz-kirikkirak-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-cavdarli-cam-kraker-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-cay-kurabiyesi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-cay-kurabiyesi-tarcinli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-feslegenli-cam-kraker-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-geleneksel-kirikkrak-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-gluten-kurabiye-180-gr-kara-bug-tahsus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-gluten-kurabiye-180-gr-kara-bugkakfin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-kirikkirak-tahilli-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-kurabiye-200-gr-tahilli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-tahilli-cam-kraker-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kburnu-zeytinli-kirikkirak-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kebir-cecil250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kebir-gurme-tereyagi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kebir-gurme-tereyagi-400-gr-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kebir-gurme-tereyagi-750-gr-tuzsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-art-stick-4lu-4100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-art-stick-badem-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-art-stick-bitter-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-art-stick-karamel-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-art-stick-ruby-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-bitter-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-bitter-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-bitter-kesme-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-cilek-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-cilek-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-doga-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-doga-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-fistik-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-fistik-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-sade-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-sade-kesme-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-stevia-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-stevia-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-stevia-cik-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kechy-gurme-dondurma-stevia-cik-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kehanetin-dogusu-masal-zamani-melez-prenses-1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kellogg-s-tahil-gevrek-375-gr-froot-loops",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kellogs-coco-pops-misir-gevrek-360-gr-moonss",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kellogs-frosties-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kellogs-misir-gevrek-450-gr-coco-pops",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kellogs-yulaf-ezmesi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-aci-biber-sosu-parcacikli-cam-330-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-beyaz-sirke-1000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-beyaz-sirke-2000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-dort-hirsiz-sirkesi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-elma-sirkesi-pet-1000-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-gurme-sirke-elma-500-ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-gurme-sirke-uzum-500ml-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-nar-eksisi-11-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kemal-kukrer-sandaloz-sakizli-elma-sirkesi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-assortment-375-gr-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-beyaz-cik-topping-sos-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-beyaz-cik-topping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-bitter-damla-cikolata-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-bitter-parca-cikolata-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-cik-topping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-cikolatali-topping-sos-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-cilek-arotopping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-frambuaz-arotopping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-italyan-arotopping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-karamel-arotopping-sos-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-karamelli-topping-sos-325-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-kirmizi-renklendirici-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-mavi-renklendirici-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-meyveli-topping-sos-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-pankek-akcaagac-surubu-355-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-sari-renklendirici-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-boringer-yesil-renklendirici-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-cikolin-choco-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-elegan-meyveli-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-elegan-meyveli-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-miss-bonbon-meyveli-375-gr-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-miss-bonbon-sutlu-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-tofy-meyveli-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-tofy-meyveli-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kent-topitop-muzik-65-gr-5-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-cikolata-aski-bitter-sos-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-cikolata-sos-128-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-hamur-kabartma-tozu-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-hkabartma-tozu-1010-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-kakao-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-kakao-150-gr-kutu-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-krem-santi-300-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-krep-yap-200-gr-tuzlu-8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-manolya-talisi-vanilyakakao-195-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-manolya-tatlisi-muzlu-195-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-meyve-sos-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-misir-nisastasi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-puding-100-gr-cikolatafindik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-puding-100-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-puding-125-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-puding-125-gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-puding-147-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-pudra-sekeri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-pudra-sekeri125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sakizli-muhallebi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sekerli-vanilin-10lu-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sekerli-vanilin-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sivi-cikolatali-sos-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sivi-karamel-sos-325-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-sivi-meyveli-sos-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-supangle-tatlisi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kenton-tavuk-gogusu-tatlisi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kervan-klasik-inek-b-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/khiri-ananas-dilim-850-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinali-klasik-beyaz-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-bueno-gofret-43-gr-white-t2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-chocolate-t46-300-gr-yarim-metre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-mini-noel-baba-315-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-noel-baba-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-schokolede-noel-baba-102-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-surpriz-yumurta-joy-60-grt3-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kinder-surpriz-yumurta-joy-60-grt3-kiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kiraz-salihli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kiraz-salihli-lux-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kiri-tam-yagli-taze-peynir-6li-90g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kirlangic-aycicek-yag-45-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kirlangic-aycicek-yagi-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kirlangic-riviera-zeytinyagi-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kitir-bakerygaleta-cesitleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kivi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kivircik-lolorosso-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kizilay-maden-suyu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-acisso-sos-50-ml-aci-biber-sosu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-corba-aci-eski19-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-makarna-napoliten-fly-xl-97-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-noodle-66-gr-korili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-noodle-66-gr-sebzeli-et-cesnili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-noodle-66-gr-tavuk-cesnili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cabuk-noodle-67-gr-acili-domates",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cesni-et-40-gr-kekikli-ve-sarimsakli-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-cig-kofte-cesnisi-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-75-gr-asma-yaprakli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-75-gr-bademli-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-17-gr-ramen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-18-gr-acili-domates",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-18-gr-crazy-corn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-18-gr-kremali-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-20-gr-kr-mantar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-22-gr-domates-kitir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-22-gr-ezogelin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-22-gr-lentil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-22-gr-mercimek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-cub-48-gr-alfredo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-cub-48-gr-arabiata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-cabuk-cub-48-gr-kajun-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-klasik-58-gr-kis-corba",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-klasik-58-gr-kremali-domates",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-klasik-70-gr-kremali-brokoli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-klasik-74-gr-ezogelin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-klasik-79-gr-tahilliyogurt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-yoresel-103-gr-arpa-sehriyeli-tarhan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-yoresel-70-gr-bulgurlu-anali-kizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-yoresel-92-gr-bulgurlu-anali-kizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corba-yoresel-98-gr-arpa-sehriye-domate",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-corekotu-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-ekonomik-kekik-55-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-ekonomik-nane-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-ekonomik-pulbiber-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-hindistan-cevizi-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-humus-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-karanfil-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-makarna-sosu-45-gr-bolonez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-makarna-sosu-52-gr-kremali-mantar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-makarna-sosu-52-gr-peynirli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-makarna-sosu-52-gr-spagetti-bolonez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-mercimek-kofte-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/knorr-sebzeli-tavk-suyu-bulyon-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kocaman-salamura-karides-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-1800mah-car-charger",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-4lu-cinko-karbon-blister-ince-pil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-dugme-pil-2-li-cr-2025",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-dugme-pil-2-li-cr2016",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-dugme-pil-2-li-cr2032",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-extra-heavy-duty-9volt-pil-9v",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-extra-heavy-duty-kalem-pil-aa-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-2-adet-alkalin-ince-pil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-2-adet-alkalin-kalem-pil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-9volt-pil-9v",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-buyuk-pil-d",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-ince-pil-aaa-62-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-max-kalem-pil-aa-62-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-p10-isitme-cihazi-pili-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-p13-isitme-cihazi-pili-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-p312-isitme-cihazi-pili-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-p675-isitme-cihazi-pili-6li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kodak-xtralife-alkaline-ince-pil-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koglu-yumurta-20-li-l-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koglu-yumurta-30-lu-medium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koglu-yumurta-6-li-medium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-20-rofle-seti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-30-koyu-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-34-koyu-kestane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-40-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-566-sarap-kizili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-575-cekici-bakir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-67-cikolata-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-70-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-diyabetik-recel-240-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-71-kullu-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-73-findik-kabugu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-80-acik-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-81-acik-kullu-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-kit-sac-boyasi-90-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-boya-acik-kullu-kumral-81",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-20-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-30-koyu-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-34-koyu-kestane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-40-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-67-cikolata-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-70-medium-blonde",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-80-acik-kumral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koleston-single-sac-boyasi-90-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-aycicek-yag-5-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-misir-yag-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-organik-sizma-zeytinyagi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-sizma-yumusak-zeytinyagi-1-lt-yumusak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-sizma-zeytinyagi-3-lt-teneke",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-sizma-zeytinyagi-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-tasbaski-zeytinyagi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-yuvam-havlu-kag-12li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-yuvam-pecete-200-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/komili-yuvam-tvlt-kag-32li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koop-az-tuzlu-hellim-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koop-hellim-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-agac-sapli-etbalik-izgara-2236",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-jel-yakit-tutusturucu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-kullan-at-hazir-mangal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-lux-masa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-lux-masa-extra",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-mangal-komuru-15-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kor-mangal-yelpzesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-buz-torbasi-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-buzgextguccop-torba-10-lu-battal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-cop-torbasi-gul-kokulu-buyuk-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-kilitli-poset-15-li-cizgi-kahramanli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-pisirme-kagidi-8m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-prof-cop-torbasi-10-lu-jumbo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/korop-strec-film-100-mt30-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koropbuzgextguc-copt-vanilya-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koropbuzgextra-guc-cop-torta-cilek-kokulu15-l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-banyo-temizlik-mendili-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-buzdolabi-poseti-4-al-3-ode-buyuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-buzdolabi-poseti-4-al-3-ode-kucuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-buzdolabi-poseti-4-al-3-ode-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-buzgulu-coptorbasi-yasemin-orta-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-cop-torbasi-mandarin-buyuk-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-cop-torbasi-mandarin-kokulu-orta-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-cop-torbasi-orta-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-dogal-pisirme-kag-strec-film-25mt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-ekst-guclu-eldven-buyuk-l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-ekst-guclu-eldven-kucuk-s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-ekst-guclu-eldven-orta-m",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-extr-guclu-tasima-torbasi-hantal-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-firin-torbasi-2538cm-8-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-kedi-kumu-torbasi-7-adet-8250",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-kilitli-poset-kucuk-boy-12-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-mutfak-bezi-3-lu-limon-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-mutfak-cop-torbasi-6370-cm-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-ovma-teli-3lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-pisirme-kagidi-hazir-kesilmis-dogal-10-l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-pisirme-kagidi-kesilmis-1616-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-temizlik-mendili-cok-amacli-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-trend-cok-amac-renkli-tor-buzg-ort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-wc-blok-250gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-yuzey-temizlik-havlusu-100-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koroplast-yuzey-temizlik-havlusu-100-lu-beyaz-sab",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-badem-ezmeli-yaz-helvasi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-cevizli-yaz-helvasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-cezerye-40-grfindikli-36-6x12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-cicek-bali-850-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-depo-3-kg-fistikli-blok",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-depo-3-kg-kakaolu-blok",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-depo-3-kg-sade-blok",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-depo-4-kg-ext-cevizli-blok-2paket8kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-diyabetik-recel-240-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-diyabetik-recel-240-gr-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-ekstra-cevizli-yaz-helvasi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-helva-koz-40-gr36-kkuruyemis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-helva-tahin-kg-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-helva-tahin-kg-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-helva-tahin-kg-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-kagit-helva-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-keciboynuzu-ozu-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-krokan-30-gr-36-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-krokan-40-gr-36-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-krokan-yer-fistikli-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-lokum-500-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-lokum-500-gr-fistikli-sultan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-lokum-500-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-recel-380-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-recel-380-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-recel-organik-300-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-recel-seker-ilavesiz-290-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-recel-seker-ilavesiz-290-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-susamli-krokan-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-tahin-helva-1000-gr-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-tahin-helva-1000-gr-kako",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-tahin-helva-1000-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/koska-tahin-pekmez-karisimi-350-gr-keciboynuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kosntantiniyye-oteli-inkilap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-active-gunluk-ped-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-active-gunluk-ped-32-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-active-quadro-normal-24-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-active-quadro-uzun-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-anydays-normal-56-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-ince-gunluk-ped-18lu-parfumsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-ince-gunluk-ped-34lu-parfumsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-natural-gunluk-ped-normal-28li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-ultra-ikili-14-ped-uzun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-ultra-quadro-normal-24-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kotex-ultra-tekli-7-ped-uzun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kozlenmis-biber-kg-17",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kral-sakir-yara-bandi-10-lu-ince-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/krep-26-gri-granit-024130",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-ayvalik-sizmasi-zeytinyagi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-dolgun-g-naturel-sizma-zyagi-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-dolgun-g-naturel-sizma-zyagi-2-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-dolgun-guclu-naturel-sizma-zyagi-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-dolgun-guclu-naturel-sizma-zyagi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-organik-sizma-zeytinyagi-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-riviera-anatolia-zeytinyagi-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-riviera-zeytinyagi-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-riviera-zeytinyagi-2-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-riviera-zeytinyagi-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-sizma-anatolia-zeytinyagi-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-sizma-zeytinyagi-150-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kristal-yumusak-sizma-zeytinyagi-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-aci-hardal-255-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-balli-hardal-255-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-balsamikli-salata-sosu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-bin-ada-salata-sosu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-dilimli-salatalik-tursu-580-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-ege-usulu-kornison-tursusu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-elma-sirkesi-1000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-gewurzgurken-salatalik-tursu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-hardal-255-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-kori-sos-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-kurutulmus-domates-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-nar-eksili-sos-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-orta-aci-hardal-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-tursu-720-ml-turk-tipi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuhne-uzum-sirkesi-1000-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kumkuat-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kupiec-pirinc-patlagi-120-gr-deniz-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kurpu-tereyag-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kurtulus-projesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuru-sele-zeytin-kg-sifir-tuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kutahyaporselen-kupa-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-ciger-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-fileto-kg-uclerkemiksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-firinlik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-kulagi-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-rosto-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/kuzu-yurek-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-bbq-tortilla-cips-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-cedar-peynirli-sos-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-cheese-tortilla-cips-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-guacamole-dip-sos-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-sade-yuvarlak-tortilla-cips-lf-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-salsa-sos-315-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-fiesta-tako-kabugu-135-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-burger-dil-cheddarli-peynir-288",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-burger-dilimli-cheddarli-peynir-2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-laktozsuz-ucgen-peynir-8li-108g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-lor-peynir-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-pizza-dilimli-peynir-180gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-qui-rit-sade-dilimli-peynir-200g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-quirit-brgr-dil-cheddarli-peynir-180gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/la-vache-quirit-krem-140gr-chedarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lactima-dilimli-cheddar-130gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lactima-dilimli-emmantel-130-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-badem-ezmesi-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-cikolatahurmafindik-ezmesi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-cookie-no1-40-gr-bademlimon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-cookie-no2-40-gr-granola",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-cookie-no3-40-gr-findikportakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-fistik-ezmesi-310-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-fistik-ezmesi-310-gr-balli-crunchy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-granola-240-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-granola-240-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-granola-240-gr-yaban-mersinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ladin-super-karisim-ezme-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lahana-beyaz-ekonomik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lahana-bruksel-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/larina-baked-beans-415-gr-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-cips-107-gr-baharat-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-cips-107-gr-klasik-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-cips-107-gr-yog-mevs-yesil-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-cips-150-gr-klasik-parti-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-cips-193-gr-klasik-mega-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lay-s-firindan-mevsim-yesillik-134-grparti-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lays-firindan-7-baharat-cesnili-96gr-patates-cips",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/leflef-ayakkabi-parlsunger-kutulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/leflef-mini-parlatici-sungeri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/leflef-nubuk-suet-sprey-200-ml-kkahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/leflef-nubuk-suet-sprey-200-ml-renksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lente-edam-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lente-gouda-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lente-gouda-kg-hardalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lente-mimolette-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-andozu-ardozu-katvisharozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-cam-koz-okal-kat-nane-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-engve-avk-katnane-armharozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-kara-murkat-cilekarm-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-panginguarkat-karpuz-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-pasf-mayis-paplav-aro-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-polarisutu-kat-cikoar-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-ve-zenc-kat-por-harnup-ozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-pro-yaban-mer-nar-katkavun-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-propolis-ve-omega-3-harnup-ozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-propolis-ve-zerdecal-kat-harnup-ozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lexton-prove-kudnarkat-muz-ar-harozu-640-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lezita-citir-pilic-jumbo-fileto-700-gr-kova",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/likorinos-kg-1-kefal-fume-balik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/limon-yeni-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lindt-excellence-100-gr-dark-70",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lindt-excellence-100-gr-dark-90",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lindt-excellence-cikolata-100-gr-85-dark",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lindt-swiss-classic-cik-100-gr-milkhazelnut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lins-galeta-aycekirdekli-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lins-galeta-corek-otlu-susamli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lins-galeta-kepekli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lins-galeta-zeytin-yagli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lins-galete-anasonlu-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lionesse-sac-fircasi-2730",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-100-lu-dogu-karadeniz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-100-lu-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-100-lu-extra-dem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-25-li-dogu-karadeniz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-25-li-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-25-li-extra-dem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bardak-cay-25-li-yellow-label",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li-28-gr-rooibos-chai",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li-30-gr-papatya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-seker-375-gr-rainbow",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li-32-gr-ihlamur",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li-32-gr-nane-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li-40-gr-nane-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitki-cay-20-li40-gr-zencefil-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitkimeyve-cayi-b6-vitaminli-18li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitkimeyve-cayi-c-vitaminli-18li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-bitkimeyve-cayi-d-vitaminli-18li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-chai-tea-latte-10lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-chai-tea-latte-tekli-18-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dem-pos-cay-48-li-25-brdk-avantaj-pak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-100-lu-extra-dem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-100-lu-filiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-100lu-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-48-li-dogu-karadeniz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-48-li-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-48-li-extra-dem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-demlik-poset-cay-80-li-dogu-karadeniz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-1000-gr-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-1000-gr-extra-dem-premium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-1000-gr-filiz-premium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-1000-gr-yellow-label",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-500-gr-earl-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-500-gr-extra-dem-premium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-500-gr-filiz-premium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-500-gr-yellow-label",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-dogu-karadeniz-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-dokme-cay-extra-yogun-earl-grey-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-meyve-cay-20-li-50-gr-kusburnu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-slim-plus-ananas-hindistan-cevizi-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-slim-plus-kayisili-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-slim-plus-kiraz-sapli-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-slim-plus-mate-maydonoz-elma-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-1-lt-karpuz-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-1-lt-karpuznane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-1-lt-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-1-lt-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-15-lt-double",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-15-lt-karpuznane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-15-lt-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-15-lt-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-15-lt-yesil-cay-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-2-lt-limon-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-2-lt-seftali-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-karpuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-karpuznane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-light-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-330-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-soguk-cay-8250-ml-limon-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tek-dem-10lu-paket-19-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tek-dem-early-grey-10lu-paket-19-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tek-dem-early-grey-tekli-19-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tek-dem-tekli-19-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tomurcuk-earl-grey-dokme-cay-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-tomurcuk-extra-yogun-earl-grey-dokme-cay-10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-20-li-20-gr-yumusak-icimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-20-li-30-gr-berrak-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-20-li-30-gr-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-20-li-30-gr-tar-kar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-20-li-40-gr-bergamot",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lipton-yesil-cay-mangoananas-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/listerine-gargara-250-ml-advance-white-hafif-tat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/listerine-gargara-250-ml-tatalwhite-hafif-tat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/listerine-gargara-500-ml-advenced-white-hafif-tat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/listerine-gargara-500-ml-cool-mint",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/listerine-gargara-500-ml-fresh-burst",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lol-cocuk-maskesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lol-cocuk-yarabandi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lol-surpriz-yumurta-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lora-kanarya-yemi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lora-meyveli-3-lu-kraker",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lora-muhkusu-yemi-400-gr-kabuksuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lora-muhkusu-yemi-400-gr-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lora-muhkusu-yemi-400-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lotus-biscoff-crunchy-spread-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lotus-sandwich-biscoff-chocolate-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lotus-sandwich-biscoff-cream-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lotus-sandwich-biscoff-vanilya-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/louis-volante-yilbasi-cocuk-havlu-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-djeli-400-ml-akdeniz-cilegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-djeli-400-ml-lavanta-bahcesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-djeli-400-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-djeli-400-ml-sut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-men-djeli-400-ml-ardinc-agac",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-men-djeli-400-ml-sedir-agaci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lpm-sivi-sabun-300-ml-zeytinyag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/luppo-bar-kek-30-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/luppo-kek-182-gr-visneli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-aseton-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-disk-makyaj-pamugu-70-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-disk-peeling-oval-40li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-gogus-pedi-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-kagit-kulak-cubugu-200-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-kozmetik-makyaj-cub-80li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-pamuk-50gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-pamuk-zigzag-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-pamuk-zigzag-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-pamuklu-kulak-cubugu-100lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-pamuklu-kulak-cubugu-200lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lux-vucut-temizleme-havlusu-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lysol-dezenfektan-sivi-720ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/lysol-dezenfektan-sprey-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mabel-krokanfindik-dolsutlu-cikolata-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mabel-marse-reed-diffu-breeze-of-freshne-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mabel-marse-reed-diffuser-magical-cash-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mabel-marse-reed-diffuser-nerolijasmine-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mabel-marse-reed-diffuser-powder-dreams-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/macromax-buzgulu-cop-torbasi-oboy-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/macromax-cop-torbasi-bboy-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/macromax-cop-torbasi-kboy-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/macromax-cop-torbasi-oboy-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magic-dose-asma-koku-ataturk-suliet-imza",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magic-dose-asma-koku-ay-yildiz-turkiye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-badem-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-beyaz-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-cookie-95-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-dbl-chill-85ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-dbl-euphoria-85ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-dbl-krml-gold-90-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-dbl-starchaser-85ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-dbl-sunlover-85ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-double-cik-95-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-double-karadut95-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-duet-berry-20x90-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-mini-classic-345ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-mini-cookie-345ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-mini-duet-4x360-ml-kirimizbadem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/magnum-mini-kls-badem-beyaz-345ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-cilekli-toplari-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-cranberry-yer-fistikli-toplari-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-domates-hashasli-crisps-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-findik-kakao-toplari-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-malt-bar-34-gr-antep-fistigi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-malt-bar-34-gr-kakao-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/malty-malt-bar-34-gr-visne-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mandalina-bodrum-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mango-ithal-meyve-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mankafa-mandalina-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mantar-kestane-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mantar-kultur-kg-acikel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mapa-kapipencere-bandi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/marc-camasir-maktemizleyici-2250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/marc-mutfakbanyo-sprey-750ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/marc-yuzey-temizleyici-25-lt-floral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maret-lezzetince-hindi-fume-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maret-pratik-seri-dana-macar-salam-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maret-sosis-432-gr-10-lu-soyulmus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maret-vakumlu-kangal-220-gr-boncuk-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maretti-bruschette-kitir-ekmek-70-gr-eksi-krema",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/maretti-bruschette-kitir-ekmek-70-gr-pizza",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mars-cikolata-bar-51-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mass-toffee-bag-1-kg-karisik-meyve-bayramli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-ciambella-strawberry-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-ciocco-biscotto-120-gr-beyaz-cik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-colline-gofret-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-cornetto-chocolate-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-donut-6li-300-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-kruvasan-6li-300-gr-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merba-brownie-cookies-20200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-kruvasan-6li-300-gr-kirmizi-meyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-nuvola-3lu-150-gr-coconut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-nuvola-coffee-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-plum-cake-milk-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/massimo-savolardi-kedi-dili-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-300-gr-fistik-ezmesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-300-gr-leblebi-ezmesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-300-gr-parca-fistik-ezmesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-fis-ezm-deniz-tuz-misir-cipsi-140-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-fistik-ezmeli-aci-bibmisir-cipsi-140-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-fistik-ezmeli-balli-misir-cips-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-nut-ic-yer-fistigi-tuzlu-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-patoto-160-gr-acilibaharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-patoto-160-gr-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-patoto-160-gr-soganlieksi-krem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-pieces-granola-300-gr-cilekchia",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-pieces-granola-300-gr-y-mersinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/master-potato-160-gr-original-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/matsot-aviv-hamursuz-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/matsot-aviv-hamursuz-200-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/matsot-aviv-hamursuz-400-gr-kepekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/matsot-aviv-hamursuz-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/max-twister-forest-65ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/max-twister-hipnotix-58-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/max-twister-island-65-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-naturel-sizma-zyag-1000ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-naturel-sizma-zyag-250ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-naturel-sizma-zyag-500-ml-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-s-zeytin-400-gr-gurme-261290",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-400-gr-cizik-261290",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-400-gr-kirma-141160",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-400-gr-kokteyl-141160",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-500-gr-luks-2xs-351380-kmny",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-800-gr-sepet-291-320",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-850-gr-biberli-141160",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-850-gr-kirma-141160",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mbirlik-zeytin-850-gr-kokteyl-141160",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mc-vities-ceam-sand-biskuvi-88-gr-tarcin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mc-vities-ceam-sand-biskuvi-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mc-vities-digestive-biskuvi-98-gr-bitter-cikolatal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mc-vities-flipz-39-gr-milk-chocolate",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mcvites-karamelize-biskuvi-kremasi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mehmet-efendi-filtre-kahve-250-gr-press",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mehmet-efendi-turk-kahvesi-hediyelik-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melba-toast-kitir-ekmek-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melba-toast-round-yuvarlak-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melba-toast-susamli-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-bezelye-720-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-jalapeno-dilimli-tursu-370-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kahvaltilik-aci-sos-210-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kahvaltilik-sos-210-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kapari-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-karisik-tursu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kornison-tursu-370-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kornison-tursu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-kozlenmis-biber-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-pancar-tursu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-salatalik-tursu-530-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-salatalik-tursu-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-sarimsak-tursusu-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/melis-sus-biber-tursu-370-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-2-hours-berry-plastic-21-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-2-hours-limon-plastic-21-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-2-hours-seker-35-gr-naneli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-2-hours-seker-35-gr-yogun-naneli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-defensive-cleanbreath-21-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-pure-fresh-cilek-15gr-sakiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-cilek-60ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-karpuz-30ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-karpuz-60ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-karpuz-90ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-nane-30ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-nane-60ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-nane-90ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-ynane-30ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-fpck-mch-btl-ynane-60ggr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-sakiz-pure-vitamin-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mentos-seker-100-gr-meyve-bag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merba-chocolate-cookies-22150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merba-nougatelli-kurabiye-biskuvi-175-gr-kakaol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merba-rainbow-cookies-22150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merba-white-chocolatecranberry-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/merbaapple-pie-cookies-20200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meric-hellim-peynir-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meric-hellim-peynir-225-gr-az-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-detox-cay-16li-32gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-elma-cayi-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-kahve-gold-16li-32gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-karma-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-kusburnu-cayi-16li-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-ormanmeli-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-roybos-vanilya-cayi-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-yesil-cay-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mesh-stick-yesil-mango-cay-16li-32-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mestemacher-protein-ekmegi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mestemacher-siyah-cavdarli-tam-tah-ekm-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mestemacher-tam-tahilli-cavdar-ekmegi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mestemacher-yulaf-cav-rustam-tah-ekme-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meymed-tek-kullanimlik-maske-50li-cocuk-pembe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-acuka-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-alabalik-fume-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-arnavut-cigerpatates-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-beypazari-yaprak-sarmasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-biber-borani-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-cibes-otu-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-cig-tarama-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-coleslaw-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-dilimli-cesnili-zeytin-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-dolma-ici-etli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-domates-dolmasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-enginar-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-girit-ezmesi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-guacamole-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-haslanmis-nohut-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-imam-bayildi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-ispanak-borani-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-ispanak-koku-salata-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-kabak-cicegi-dolmasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-kafkas-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-karabugday-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-kereviz-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-kizartma-kg-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-kuru-cacik-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-lahana-sarmasi-etli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-midye-dolma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-mini-icli-kofte-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-muammara-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-nohut-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-patates-kroket-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-patates-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-patlican-babagannus-kgkozlenmisotel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-patlican-begendi-kg-patlican-salata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-patlican-kozlenmis-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-pazi-sarma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-pazi-sarmasi-etli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-pembe-sultan-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-portakalli-kereviz-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-puf-borek-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-rezene-salata-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-siciliano-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-tabule-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-tahinli-kopoglu-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-topik-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-yandim-hacer-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-yaprak-sarmasi-etli-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zeytin-salatasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zeytinyagli-ayva-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zeytinyagli-kereviz-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zeytinyagli-yerelmasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zyagli-kabak-kalye-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/meze-zyagli-pirasa-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mickey-yara-bandi-10-lu-ince-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-bitter-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-choco-grain-168-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-choco-grains-126-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-choco-sandwich-260-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-choco-sensations-biskuvi-156-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-cikolata-100-gr-karamel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-cikolata-85-gr-dark-milk-ahududu-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-cikolata-85-gr-dark-milk-sutlu-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-gofret-kakaolu-285-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-kakaolu-findik-kremasi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-oreo-bar-37-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-oreo-sandvic-92-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-sandvich-cilek-92-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-butun-findikli-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-oreo-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-oreo-choco-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-oreo-white-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-petit-lu-87-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milka-tablet-pirinc-patlagi-100-gr-riso-soffiato",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milliyet-gazetesi-haftasonu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/milupa-cocuk-sut-200-ml-tetra",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-dil-temizleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-dis-fircasi-koruma-basligi-4lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-dis-fircasi-saklama-kabi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-dis-ipi-50-metre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-dis-temizleme-silgisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-kurdanli-dis-ipi-32li-posetli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-kurdanli-dis-ipi-50li-silindir-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-l-tipi-arayuz-fircasi-turuncu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/misip-u-tipi-arayuz-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/miskets-leblebi-draje-100-gr-bcikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/miskets-leblebi-draje-100-gr-limonlu-cheescake",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/miskets-mini-cik-120-gr-crunch-bite",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/miskets-mini-cik-120-gr-marsmallow",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/miss-kirec-giderici-1-lt-sirke-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-polen-25-gr-bogurtlen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-polen-25-gr-erik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-polen-25-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-propolis-25-gr-hurma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-propolis-25-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mixmey-bee-propolis-25-gr-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mm-s-draje-cikolata-100-gr-peanut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mm-s-draje-cikolata-36-gr-crispy-pouch",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mm-s-draje-cikolata-90-gr-peanut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mm-s-kurabiye-biskuvi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-3d-bebek-bezi-jumbo-paket-e-large-17-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-3d-bebek-bezi-jumbo-paket-junior-20",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-3d-bebek-bezi-jumbo-paket-maxi-27-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-3d-bebek-bezi-jumbo-paket-mini-36li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-3d-bebek-bezi-jumbo-paket-y-dogan-40li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-cbezi-yeni-dogan-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molfix-izotonik-sulu-islak-havlu-50-li-y-dogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-anatomik-gece-16-li-seko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-anatomik-normal-24-lu-seko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-anatomik-uzun-20-li-seko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-antibac-gunluk-16li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-gunluk-incelik-h-ped-normal-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-gunluk-incelik-parfumsuz-60-li10",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-ilk-son-gun-h-ped-uzun-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-kanatsiz-normal-22li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-puresoft-super-eko-h-ped-gece-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/molped-puresoft-super-eko-h-ped-uzun-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/moms-cookies-40-gr-findik-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/moms-granola-300-gr-elma-glutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/moms-granola-360-gr-muz-ceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/moms-granola-360-gr-yaban-mersin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/moms-granola-360-gr-yer-fistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/monini-zeytinyag-limonlu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/monster-enerji-icecek-500-ml-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/monster-enerji-icecek-500ml-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/monster-enerji-icecek-500ml-pipeline-punch",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/monster-enerji-icecek-500ml-rossi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/morelli-spaghetti-nero-siyah-murekkepli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/morfose-wax-150-ml-hard-aqua",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-dondurma-100-ml-ceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-dondurma-100-ml-isvicre-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-dondurma-500gr-vanilya-ruyasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-kahve-189-ml-cappuccino-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-kahve-189-ml-espresso-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-kahve-189-ml-macchiato-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-yogurt-100-gr-ahududu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-yogurt-100-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-yogurt-100-gr-mangokayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/movenpick-yogurt-100-gr-seftalipassion-fruit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-glade-yuzey-temizleyici-25-lt-floral",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-glade-yuzey-temizleyici-25-lt-lavant",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-glade-yuzey-temizleyici-25-lt-okyanu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-likit-750-ml-bahar-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-likit-750-ml-ksoker-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-likit-750-ml-orman-esintisi12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-muscle-mutfak-banyo-sprey-2750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-no-sandvic-60-gr-karamel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-no-sandvic-60-gr-labne-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-no-sandvic-60-gr-limonlu-cheescake",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-no-sandvic-60-gr-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-oxy-cok-amac-yuzey-tem-hav-100-lu-beyaz-sa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-oxy-cok-amac-yuzey-tem-hav-100-lu-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-oxy-sih-bez-kir-ve-pas-yuz-tem-havlu-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mr-oxy-yer-temizleme-havlusu-50li-mop-uyumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrmuscle-dezenfektan-sprey-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrmuscle-lavabo-acici-jel-500-ml-mutfak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrno-dana-bacon-fresh-185-gr-sandvic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrno-ezine-pey-fresh-180-gr-sandvic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrno-grm-ton-balikli-cheddarli-sndvc-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrno-humus-kozlenmis-biberli-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mrno-mega-jambcheddar-230-gr-sandvic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/multipower-pack-xxl-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/multipower-protein-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/munzur-su-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/munzur-su-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/muratbey-anadolu-lezzetleri-200-gr-e",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/muratbey-dil-peyniri-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/muratbey-surmeli-peynir-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/muratbey-topi-peynir-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mutlu-makarna-boncuk-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/mutlu-makarna-ince-kesim-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/myclean-tuy-alma-bandi-60-yaprak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/myday-krem-santi-sprey-250gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-dana-jambon-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-dana-kangal-sucuk-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-dana-kangal-sucuk-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-fistikli-dana-macar-salam-724-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-hindi-fis-macar-salam-724-50-gr-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-hindi-jambon-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/namet-pastirma-110-gr-cemeni-siyrilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nar-2boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/narca-patates-1-kg-premium-99",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naren-bagisiklik-meyve-su-250-ml-propolis-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naren-bagisiklik-meyve-su-250-ml-zencefil-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naren-meyve-su-1000-ml-nar-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naren-meyve-su-1000-ml-narportakalelma-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naren-meyve-su-1000-ml-portakal-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naturali-cicek-ihlamur-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/naturali-meyve-karnavali-cayi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-findik-unu-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-findikli-kurabiye-karisimi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-kakao-tozu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-pancake-waffle-karisimi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-sarimsak-tozu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-sogan-granul-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nature-tuzlu-hamurisi-karisimi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-3in1-arada-10lu-black-roast150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-3in1-arada-15-gr-black-roast",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-3u1-arada-findik-aromali-1017gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-choco-kahve-105-gr-3in1-ice-cik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-choco-kahve-10lu-105-gr-3in1-ice-cik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-classic-50-gr-eko-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-classic-fine-blend-2-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-100-gr-kafeinsiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-150-grgold-fincan-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-200-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-60-gr-eko-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-decaf-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-filitre-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-gold-filtre-kahve-8x250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-klasik-crema-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-mocha-cikolata-kopuklu-917-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-original-kahve-10lu-105-gr-3in1-ice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-1010-gr-2in1-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-1010-gr-s-kopuklu-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-1017-gr-c-latte-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-15-gr-cappuccino-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-17-gr-crema-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-17gr-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-stick-kahve-192-gr-w-choc-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-toffee-nut-kahve-105-gr-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-koftelik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-xpress-soguk-kahve-250-ml-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-xpress-soguk-kahve-250-ml-choco",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-xpress-soguk-kahve-250-ml-coconatte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-xpress-soguk-kahve-250-ml-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nescafe-xpress-soguk-kahve-250-ml-zero-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-ballibademli-400-gr-20-bedava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-bar-seker-ilavesiz-20gr-balli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-bar-seker-ilavesiz-20gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-gran-300-gr-yulaf-kabak-cekcranberry",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-granola-310-gr-yulafbal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-karisik-meyveler-400-gr-20-bedava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-kirmizi-meyveler-400-gr-20-bedava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-400-gr-bal-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-400-gr-cikolata-20-bdv",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-400-gr-kmeyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-400-gr-meyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-420-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesfit-tahil-gevrek-bar-225-gr-beyaz-cik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesil-manti-kg-bohca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesil-manti-kg-kayseri-muska",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-beyaz-gofret-267-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-kare-cikolata-70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-misir-gevrek-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-misir-gevrek-310-gr-cilek-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-misir-gevrek-310-gr-muz-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-misir-gevrek-400gr-minismini-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-misir-gevrek-450-gr-20-bedava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-stick-cikolata-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-sut-180-ml-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-sut-6180-ml-kakao-6-li-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-sut-6180-ml-laktozsuz-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-tahil-gevrek-310-gr-cokokare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-tahil-gevrek-310-gr-harfler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nesquik-toz-icecek-375-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-1927-beyaz-cikolata-60-gr-bademli-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-1927-gofret-28-gr-yogun-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-1927-gofret-30-gr-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-1927-ikr-cikolata-kese-1512-gr-fin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-1927-ikramlik-cikolata-188-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-bar-cikolata-27-gr-crunch-patt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-beyaz-cikolata-60-gr-bademlipor-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cheerios-gevrek-225-gr-cheerios-balyulaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-30-gr-damakbaton",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-cornflakes",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-damak-baklava-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-damak-gece-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-damak-inci-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-damak-karamel-krkn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-60-gr-damak-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-70-gr-damak-gece-ddortgen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-70-gr-damak-incitablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikolata-70-gr-damak-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-cikota-18-gr-damak-gece-stick",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-citir-gofret-27-gr-beyaz-cik-kaplamali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-clas-golden-60-gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-clas-karamel-macchto-60-gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-clas-limonlu-chesk-60-gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-classic-cik-60-gr-bitter-fistik-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-classic-cikolata-60-gr-beyazsutlu-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-corn-flaks-gevrek-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-corn-fleks-gevrek-450-gr-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-crunch-tam-tahil-ve-pirinc-gevregi-280-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-crunch-tam-tahil-ve-pirinc-gevregi-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-damak-30-gr-ala",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-damak-60-gr-lokum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-damak-baton-30-gr-baklava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-expres-soguk-kahve-250-ml-americano",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-expres-soguk-kahve-250-ml-caramel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-expres-soguk-kahve-250-ml-nedir-butat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-gol-colombia-252-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-gold-tam-tahilli-gevrekler-310-ml-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikr-cik-1512-gr-crunch-pouch-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikr-cik-153-gr-sutlu-pouch-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikr-cik-156-gr-mini-keyifler-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikram-cik126-gr-damak-baklava-pouch-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikram-cik130-gr-damak-double-twist-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikram-cik1512-gr-damak-sutpouch-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikramlik-cikolata-1764-gr-damak-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikramlik-cikolata-mini-1512-gr-damakbyr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-ikramlik-cikolata-mini-50-gr-damak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-lion-chocolate-bar-cikolata-60-g-r",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-lion-peanut-yer-fis-bar-cikolata-41-g-r",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-lion-white-dolg-beyaz-cik-kapli-gof-42gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-su-15-lt-5526",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-su-5-lt-1362",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-yorkie-original-46-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nestle-yorkie-raisin-biscuit-44-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-besbakim-kremi-300ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-canlandirici-yuz-tjeli-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-el-kremi-75-ml-hizli-emilen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-el-kremi-75-ml-parfumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-hizli-emilen-bak-kremi-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/neutrogena-krem-200-ml-yogun-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nevzat-kadayif-400-gr-tepsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nevzat-spare-250-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nevzat-spare-250-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nevzat-tel-kadayif-200-gr-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/newes-foods-burger-kofte-180-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/newes-foods-falafel-kofte-1000-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/newes-foods-kebap-kofte150-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/newes-foods-kofte-190-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nickjulie-lady-finger-kedi-dili-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nigde-gazozu-250-ml-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nilky-soya-cecegi-1-lt-vegan-salep-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nilky-soya-icecegi-1-lt-sekersiz-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-aloe-vera-vucut-losyonu-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-aqua-rose-goz-maske",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-aqua-rose-kagit-maske",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-aqua-rose-nemlendirici-tonik-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-aqua-rose-yuz-tempeeling-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-baby-pisik-kremi-100-ml-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-baby-sac-ve-vucut-sampuani-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-balsam-100-ml-hassas6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-balsam-100-ml-nem-deep",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-bb-krem-5i-1-arada-50ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-bb-yuz-temizleme-400-ml-micellar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-bebek-f50-gunes-bakim-sutu-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-body-milk-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-body-q10-sikilastirici-bronzluk-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-body-q10-vucut-sekillendirici-yag-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-bw-org-rolon-50ml-bw-org-male-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-cellular-50-ml-yaslanma-karsiti-gunduz-krem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-cellular-yaslanma-karsiti-gunduz-bakim-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-cocuk-f50-gunes-sprey-200-ml-renkli-neml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-degyag-icervucut-los-kiraz-cic-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-clear-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-clear-nivbw-roll-on-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-inv-guclu-etki",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-inv-guclu-etkix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-power-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-pure-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-bw-soft-krem-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-deep-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-deep-ext-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-derma-protect-women",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-derma-prt-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-comfort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-femal-fresh-roll-on-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-flower-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-kirli-sakal-balsam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-male-fresh-roll-on-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-power-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-sensation-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-fresh-sensation-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-invbw-fresh-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-ipeksi-puruzsuzluk-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-150-ml-org-male-org-male-roll-on-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-200-ml-bw-pwr-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-200-ml-fresh-ntr-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-deo-bay-50-ml-deep-beet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-derm-skin-gece-arind-40ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-derm-skin-siv-kars-tonik-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-derm-skin-siv-kars-yuz-te-jel-krem-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-dus-jeli-400-ml-cremecare12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-dus-jeli-500-ml-active-clean",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-dus-jeli-500-ml-sunshine-love",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-dus-jeli-men-500-ml-deep-extreme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-dus-jeli-men-500-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-f50-gunes-sprey-200-ml-korumanem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-fresh-male-rolon-50ml-fresprmalrol-25-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-gunes-sutu-f50-200-ml-korumanemlendirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-gunes-yag-f30-200-ml-korumabronzluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-gunes-yag-f50-sprey-200-ml-korumabronzluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-gunes-yag-sprey-200-ml-bronzluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-hand-krem-100-ml-balmumu-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-hand-krem-100-ml-zyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-hand-puruzsuzlestirici-el-kremi-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-hydra-skin-maske-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-hydra-skin-nemlendirici-jel-krem-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-kirisik-karsiti-50-ml-nemlendirici-55",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-krem-250-ml-klasik-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-krem-30-ml-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-krem-75ml-yaslanma-karsiti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-lip-care-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-lip-care-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-make-up-exp-50-ml-nemlendi-mat-makyaj-baz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-make-up-expert-50-ml-nemlendirici-makyaj-ba",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-makyaj-tem-mendil-dry-skin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-makyaj-tem-mendil-normal-skin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-men-roll-on-50-ml-bw-inv-guclu-etkix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-miceller-makyaj-temizleme-kopugu-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-organpiric-peeling-normal-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-q10-c-vitamin-kagit-maske",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-q10-firming-losyon-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-q10-power-bakim-kremi-gunduz-sikilastirici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-q10-vucut-sikilastirici-losyon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-repaircare-krem-75-ml-onarici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-blackwhiteclear-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-ck-kck-fresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-cool-kickbay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-deep-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-dry-fresh-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-fresh-flower-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-fresh-power-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-powder-touch-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-protectcare-bayan6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-50-ml-silver-protectx",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-bay-50-ml-deep-beet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-bay-50-ml-fresh-sensation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-bayan-50-ml-derma-control",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-bayan-50-ml-fresh-sensation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-roll-on-erkek-50-ml-derma-control",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-rose-yuz-temizleme-jeli-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-soft-krem-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-soft-krem-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-soft-krem-300-ml-50-ml-hediyeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-40-ml-silver-protect-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-bw-fresh-man",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-deep-byn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-fresh-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-inv-bw-silk-byn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-pearl-beauty-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50-ml-silver-protect-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-stick-50ml-fresh-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-sun-hassas-alerji-sprey-spf50200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-sun-hassas-koruma-sprey-spf50200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-tras-kopuk-200-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-tras-kopuk-200-ml-protectcare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-urban-skin-detox-yuz-yikama-kopugu-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-visage-cift-etkili-maktem125ml-micellar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-visage-gunluk-bakim-kremi-50-mlcanlandir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-visage-peffect-all-in-one-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-visage-tonik-yumusatici-nk-200ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-vucut-bakim-nat-good-vucut-los-aloe-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-vucut-bakim-nat-good-vucut-los-avakado-35",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-vucut-bakim-nat-good-vucut-loslavanta-350",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-vucut-bakim-yogun-el-v-krem-400ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-yogun-nem-el-kremi-75-mldudak-bakim-kremi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-yuz-temjel-150-ml-lotus-cicegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-yuz-temjel-200-mlaqua-sensation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nivea-yuz-yikama-kopugu-150-ml-normal-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-findikli-gofret-100-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-irmik-500-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-kakaolu-gofret-100-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-karabugday-tane-400-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-karabugday-unu-400-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-koftelik-bulgur-500-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-misir-gevregi-250gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-pilavlik-bulgur-500-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-portakalli-kurabiye-100-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-susamli-cubuk-kreker-100-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-un-500-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nog-vanilyali-gofret-100-gr-gulutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nogger-sandwich-karamel-kakao-145-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nuh-un-ankara-makarna-spagetti-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-bebeblac-muzlu-muhallebi-125-kvn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-bebelac-kvm125-gr-pure-elma-armut6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-kvm-org-seftali-elma-125-gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-milupa-125-gr-tek-meyve-armut-puresi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-milupa-125-gr-tek-meyve-elma-puresi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/numil-milupa-125-gr-tek-meyve-erik-puresi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nurev-laurel-kitchen-20-cm-sahan-grey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/nurev-laurel-kitchen-22-cm-sahan-rose-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-sivi-deterjan-1480-ml-expresfres-renkbeyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-sivi-deterjan-1480-ml-expresfres-renksiya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-sivi-deterjan-1690-ml-active",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-sivi-deterjan-1690-ml-activefresh",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/omo-sivi-deterjan-1690-ml-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oncu-domates-salcasi-1650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oncu-domates-salcasi-700-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oncu-salca-karisik-700-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oncu-tatli-biber-salcasi-1650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-alacati-kurabiye-200-grtahinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-alacati-kurabiye-200-grtarcinli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-cikolata-dolgulu-220-gr-lezzet-bombas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-elma-dolgulu-kurabiye-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-findikli-kurabiye-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-kakaolu-kurabiye-220-grcatlak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-kurabiye-180-grkakao-find-cik-parc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-teryagli-kurabiye-210gr-kalp",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-un-kurabiyesi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onefis-uzumlu-kurabiye-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oneo-bottle-draje-sakiz-60-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oneo-energy-drasise-mey-ar-sak-44-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-cilekli-limonata-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-cilekli-limonata-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-elma-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-greyfurt-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-havuc-porti-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-karisik-meyveli-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-klasik-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-klasik-limonata-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-nar-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-nar-750-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-narmand-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-narport-750-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-narportakal-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-portakal-250-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-fresh-portakal-750-ml-taze-sikilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-glow-250-ml-avk-port-muz-arm-sef-lim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-green-srar-250-ml-uzum-muz-manelmkivsal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-hijyen-z-kat-200lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/only-linht-green-250-ml-avkarmmuzisplimelm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onton-jel-reso-yakiti-180-cc-teneke",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onton-pasta-masasi-137622",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/onton-tuy-alma-bandi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oragro-bluberry-125-gr-yaban-mersini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-16487-furca-3dw-luxe-pro-38-med",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-50-ml-prof-dis-eti-minesi-pro-onarim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-75-ml-macun-cocuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-75-ml-onarim-hassas-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-75-ml-onarim-orjinal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-75-ml-pro-onarim-extra-ferahlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-advantage-11-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-cocuk-0-2-yas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-2-li-maxi-cleanorta-40",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-3d-brilliance-medium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-3d-white-advantage",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-adv-cavity-defense",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-classic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-cocuk-57yas12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-komple-5-yon-tem-40-med-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-11-sensitive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-expall-in-one-35-soft12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-expert-2-li-hepsibirarada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-expert-3d-40-orta-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-expert-dis-eti-sag-medium-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-expert-massager-orta-40-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pro-flex-clinic-38-soft12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pulsar-40-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-pulsar-hassas-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-d-firca-yedek-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dfirca-simple-sensitive-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dis-eti-temizligi-1-ct-gum-purify",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dis-fircasi-pro-expert-medium",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dis-fircasi-pro-expert-neonfrsh-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dis-fircasi-proexpert",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dis-ipi-satin-tape-25-mt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-50-ml-profhassasiyetrahatlama",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-65-ml-deep-clean",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-65-ml-densify-curuk-kalkani",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-75-ml-hassasiyet-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-75-ml-komple-7-ektra-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-3dw-adv-luxe-glom",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-3dw-clinical-muk-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-3dw-hafif-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-3dw-luxe-perfection",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-3dw-parlak-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-cay-ve-kahve-icinler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-dmacunu-pro-75-ml-profesyonel-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-gargara-500-ml-pro-exp-prokoruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-pro-health-komur-extra-hassas-1ct96",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-ultra-dis-fircasi-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-ultrathin-sensitive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oral-b-vitalty-pro-sarjli-dis-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orbit-sakiz-karpuz-sekersiz-14-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orbit-sakiz-nane-sekersiz-14-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orc-katyon-set-sapka",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-biskuvi-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-biskuvi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-biskuvi-440-gr-10lu-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-biskuvi-choco-95-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-classic-44-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-double-fun-frambuaz-157-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oreo-milk-snack-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orfa-the-standart-vegan-burger-relish-sos-275-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orfa-the-standart-vegan-mayonez-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orfa-the-standart-vegan-otlu-biberli-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orfa-the-standart-vegan-tomato-relish-sos-275-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-baby-tarhana-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-biber-salcasi-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-cavdar-un-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-cok-amacli-un-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-dut-pekmezi-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-galete-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-hceviz-yag-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-hurma-pekmezi-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-hurma-suyu-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-karabugday-un-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-keciboynuzu-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-misir-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-organik-kuru-fasulye-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-organik-misir-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-organik-nohut-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-organik-pirinc-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-organik-yesil-mercimek-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-pirinc-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-siyez-bulguru-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-tam-bugday-unu-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-tarhana-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-toz-biber-aci-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-yulaf-ezmesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/organik-gurme-yulaf-unu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orgibite-cilek-kurusu-70gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orgibite-organik-dut-kurusu-70gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orgibite-organik-gun-kurusu-250gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orgibite-organik-incir-kurusu-250gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orhan-reis-siya-sarimsak-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orhan-reis-soyulmus-sarimsak-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orhan-siyah-sarimsak-puresi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/original-gourmet-lollipop-31-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-gunluk-normal-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-gunluk-uzun-28-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-gunluk-yaprak-ince-tekli-20li-bahar-cic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-plati-dortlu-gece-ekstra-plus-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinium-4lu-gece-extra-no4-14-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinium-gece-extra-no4-5li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinum-dortlu-gece-24lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinum-dortlu-gece-ekstra-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinum-dortlu-paket-normal-32li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinum-dortlu-uzun-28li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-platinum-gece-extra-tekli-24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-4-lu-26-li-normal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-4-lu-extra-gece-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-4lu-gece-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-4lu-gece-extra-plus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-gece-24lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-gece-ekstra-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-gece-ext-max-14lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-gece-ext-plus-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-normal-32li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-ekstra-dortlu-uzun-28li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-ultra-extra-gece-plus-tekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-yaprak-normal-20li-18-tekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkid-yaprak-pamuksu-tekli-normal-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkide-aycicek-yagi-2-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orkide-aycicek-yagi-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orucdede-tahin-pekmez-900-gr-ikiz-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orvital-organik-kaymakli-tava-yogurt-900-gr-tam-ya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orvital-organik-kefir-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orvital-organik-pastorize-gunluk-sut-1-lt-tam-yag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orvital-organik-pilic-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/orvital-organik-yumurta-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osmanli-kolonya-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osram-mini-twist-12w-e27-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osram-mini-twist-20w-e27-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osram-mini-twist-23w-e27-beyaz-hrosr00153",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osso-geleneksel-kahve-karisimi-200-gr-dibek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osso-geleneksel-kahve-karisimi-200-gr-menengic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/osso-geleneksel-kahve-karisimi-200-gr-osmanli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/otaci-sampuan-400-ml-isirgan-otlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/otaci-sampuan-400-ml-ivy-kepege-karsi-etk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/otana-dolmalik-fistik-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ovalyum-bildircin-yumurta-12li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ovalyum-yumurta-gezen-10lu-ZEQVO",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ovalyum-yumurta-organik-15li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/oxy-elde-bulasik-deterjani-30-kg-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozkaynak-arom-maden-su-6200-ml-c-plus-176",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozkaynak-su-05-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozkaynak-su-10-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozkaynak-su-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-biskuvi-cikolatali-40-gr-hoppo-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-biskuvi-sut-kremali-40-gr-hoppo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-challenge-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-cornet-cilekli-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-cornet-sutlu-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-farm-parmak-cikolata-11-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-go-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-kek-ogopogo-30-gr-cilekkako",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ozmo-kek-ogopogo-30-gr-mukako",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pakkoy-yumurta-10-lu-gezen-omega",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pakkoy-yumurta-15-li-gezen-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pakkoy-yumurta-15-li-gezen-tavuk-y7nSD",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pakmaya-kuru-maya-100-gr40",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palet-100120-tahta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-djeli-500-ml-micellar-care-gul-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-djeli-500-ml-micellar-keten-tohumu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-1000-ml-sensfeel-relaxyedek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-aroma-sensation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-body-butter-bogurtlen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-body-butter-hindcevizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-body-butter-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-body-hcevizijojoba",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-body-mind-komurnane-yag",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-bodybutter-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-bogurtlenorkide-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-clay-detox",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-clay-rejuvenation",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-clay-tonus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-feel-glow",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-feel-good",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-feel-good-dTubH",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-feel-the-energy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-kadinlar-gunu-hed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-luminiouse-oil-avokado",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-luminous-oil-incir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-luminous-oil-macedemi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-massage",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-men-sport12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-so-firm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-so-relaxed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-so-sweet12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-super-food-acaiyulaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-500-ml-super-food-greyzence",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-750-ml-feel-the-energy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-750-ml-feel-the-glow",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-750-ml-so-relaxed",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-dus-jeli-micellar-care-250ml-pamuk-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-hyaluronic-acid-dus-jeli-500ml-aloe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-hyaluronic-dus-jeli-500ml-dag-igdesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-men-dus-jeli-500-ml-limited-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-men-dus-jeli-500ml-cooling-feel-of-muscl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sabun-150-gr-badem-yagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sabun-150-gr-macademia",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sabun-150-gr-massage",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sabun-150-gr-micellar-care-pamuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sivi-sabun-300-ml-siyah-orkide",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/palmolive-sivi-sabun-700-ml-anti-stres",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pamir-pmr-111-doruk-mandal-18li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pancar-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-3-dk-sac-bakim-kremi-200-ml-guc-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-3-dk-sac-bakim-kremi-200-ml-sac-dok-kar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-aqualight",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-gucparlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-hacim-havali-silikonsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-klasik-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-sac-dok-karsiti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-275-ml-yagterapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-360-ml-guclu-parlak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-360-ml-klasik-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-s-kremi-360-ml-onarici-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-sac-bakim-kremi-200-ml-onarici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-sac-bakim-kremi-200-ml-superfood",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-sac-bakim-kremi-275-ml-uzun-ve-guclu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp-mirecles-bambu-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp225-ml-miracles-pro-menekse",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-dokulmeye-karsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-dokulmeye-karsi-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-guc-ve-parlak-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-guc-ve-parlaklik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-kepek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-kepek-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-klasik-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-koruyucu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-koruyucu-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-nem-onarici-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-ml-superfood",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-mltemel-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp350-mlyag-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-guc-ve-parlak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-guc-ve-parlaklik-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-kepege-karsi-etkili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-kepege-karsi-etkili-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-onarici-koruyucu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-onarici-koruyucu-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-sac-dokulme-karsiti-31-arada",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pantene-samp400-ml-yag-terapisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papia-decor-pecete-20li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papia-platinium-cek-al-mendil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papia-tuvalet-kagidi-16-li-ipek-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papia-tuvalet-kagidi-16-li-parfumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papia-tuvalet-kagidi-32-li-parfumlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/papita-kaplamali-biskuvi-33-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-buzdolabi-poseti-buyuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-cam-bezi-mikrofiber-mf-tem-bez-hediye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-flowers-cok-amacli-temzl-bezi-7li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-moppy-mikrofiber-paspas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-mutfak-bezi-mikrofiber-temizlik-bezi-hediye",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-pisirme-kagidi-8-mt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-strec-film-natura-15-mt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-sunger-2-li-mega-comfort-oluklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parex-temizlik-bezi-30-mf-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parmex-aseton-125-mlsumbul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dis-firca-tam-koruma-extra-yumusak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dis-firca-uzman-bakim-11-yumusak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75-ml-tam-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75ml-beyazlatici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75ml-florurlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75ml-orginal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75ml-tam-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-75ml-ultra-temizlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-beyazlatici-752-firsat-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/parodontax-dmacunu-dis-etihassasiyetferbeyazla",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pasabahce-80030-2-tuzluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-500-gr-buket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-500-gr-bukle",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-500-gr-eriste",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-bundle-400-grporsiyonluk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-bronz-linguini-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-bronz-rigatoni-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-bronz-rotini-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-maccheese-210-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-sosu-310-gr-arrabbiata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-sosu-330-gr-napoliten",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-tagliatellle-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-makarna-veggipasta-rotini-350gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pastavilla-tam-bugday-makarna-350-kalem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/patates-kg-ekonomik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/patates-kg-kirmizi-kizartmalik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/patlican-zebra-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-konserve-400-gr-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-konserve-400-gr-kuzu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-konserve-400-gr-somon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-kuru-1500-gr-yavru-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-kuru-500-gr-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-kuru-500-gr-yavru-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-yavru-pouch-85-gr-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-yetiskin-pouch-85-gr-biftek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-yetiskin-pouch-85-gr-somon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kedi-mamasi-yetiskin-pouch-85-gr-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kopek-mamasi-kuru-3kg-kuzu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kopek-mamasi-yavru-konserve-400-gr-kuzu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pawpaw-kopek-mamasi-yetiskin-konserve-400-gr-kuzu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/payna-baklava-32-gr-afistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/payna-baklava-32-gr-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/payna-baklava-4lu-128-gr-afistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/payna-baklava-4lu-128-gr-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-440410-dondurmalik-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-52291-6-1097580",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-cay-bardagi-damla-logo-pkdkirem-6li-42451",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-cay-bardagi-optikli-uskudar-6li-42021-6-o",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-cay-bardagi-pkdklinka-3lu-42741",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-fincan-takimi-hkdkvela-6li-97302-hk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pb-toros-su-bardagi-6li-52644",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pbbardak-kulplu-pkdk-55411-lina-g4u-6li-55783",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pbcay-bardagi-incebelli-optik-incebelli-6li-4278",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pbcay-bardagi-pkdkdantel-6li-42841",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pbsade-su-bardagi-pkdkalanya-6li-52052",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pbyonca-su-bardagi-6li-52056",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-jumbone-medium-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-kopek-mama-160-gr-jumbone-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-kopek-mama-400-gr-konserve-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-kopek-odul-mama-180-gr-dentastick",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-kopek-odul-mama-200-gr-biscrock",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-kuru-2-kg-kucuk-cins-kopek-bifteksebze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-odul-45-gr-dentastix-kucuk-3-lu18",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-odul-77-gr-dentastix-orta-3-lu18",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-odul-mama-110-gr-dentastix-kucuk-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-rodeo-biftekli-4-pcs-70gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-rodeo-biftekli-8-pcs-123gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pedigree-y-kopek-mama-400-gr-konserve-danaet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pek-bohca-gurme-manti-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pek-findik-lahmacun-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pek-mix-pizza-460-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pek-ucgen-gurme-manti-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penguen-aboya-likit-75-ml-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penguen-cilek-receli-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penguen-gul-receli-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penguen-taze-fasulye-720-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-cotton-naturel-siyah-no2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-cotton-naturel-siyah-no3",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-diz-alti-corap-500-pantolon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-diz-alti-corap-500std",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-diz-alti-corap-51",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-diz-alti-corap-57std",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-kulotlu-corap-5001",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-kulotlu-corap-5002",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-kulotlu-corap-5003",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-kulotlu-corap-5004",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-fit-15-kulotlu-corap-573",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-micro-40-diz-alti-corap-500",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-micro-40-diz-alti-corap-57",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-5001",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-5002",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-5003",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-5004",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-512",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-513",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-571",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-572",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-573",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-kulotlu-corap-574",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-pantolon-corap-500std",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-pantolon-corap-51std",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-super-pantolon-corap-57std",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-termal-tayt-no2",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/penti-termal-tayt-no3",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepino-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-1-lt-max-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-1-lt-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-15-lt-max-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-15-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-25-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-250-ml-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-250-ml-max",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-250-ml-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-250-ml-zero-sugar-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-41-lt-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-4250-ml-twist",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pepsi-cola-mango-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perfecto-draje-sutlu-cikolata-60-gr-afistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perfecto-indulge-cikolata-100-gr-cilek-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perfecto-indulge-mini-210-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perfecto-madlen-sutlubitter-cikolata-224-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-alic-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-elma-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-enginar-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-gul-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-nar-eksisi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perinthos-uzum-sirkesi-500-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/permatik-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/permatik-6-li-banyo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/permatik-poset-lady-5-li6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/persil-matik-15-kg-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/persil-matik-15-kg-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-1-lt-beyaz-liguid",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-1-lt-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-132-lt-cicek-cazibe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-15-lt-beyaz-liguid",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-15-lt-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-15-lt-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-15-lt-yunlu-ipek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-27-lt-yenilemebuyuleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-275-lt-cicek-cazibesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-297-lt-renk-doku",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-297lt-beyazdoku",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-297lt-cicek-cazibesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-3-lt-bakimferahlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/perwoll-sivi-deterjan-900-ml-yenilemebuyuleyici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pesotti-profiterol-toplari-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pesto-squeezy-feslegenli-makarna-sosu-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-140-gr-ic-ceviz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-200-gr-kuru-uzum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-anadolu-yoresel-lezzetler-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-asorti-kokteyl-mix-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-bar-27-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-bar-30-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-bar-30-gr-yer-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-cig-ara-ogun-140-gr-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-cig-badem-ic-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-cig-findik-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-cig-kar-enerji-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-cig-karisik-kuruyemis-165-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-dut-kurusu-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-ege-yoresel-lezzetler-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-gun-kurusu-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-gurme-155-gr-ortaya-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-hurma-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kabuklu-a-fistik-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kaju-cig-fistik-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kavrulmus-ic-badem-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kavrulmus-ic-findik-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kavrulmus-ic-yer-fistik-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kavrulmus-kabak-cekirdek-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kavrulmus-kaju-fistik-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kizartilmis-yer-fistik-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kuru-erik-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kuru-incir-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-kuru-kayisi-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-ortaya-karisik-klasik-mix-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-siirt-fistigi-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-soslu-karisik-kuruyemis-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-tuzlu-kavrulmus-findik-130-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-bahceden-tuzlu-leblebi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-benekli-leblebi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-citliyo-aycekirdegi-120-gr-kara-sim-bol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-citliyo-aycekirdegi-120-gr-kara-simsek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-citliyo-aycekirdegi-180-gr-kara-sim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-citliyo-aycekirdegi-180-gr-kara-sim-bol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-130-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-130-gr-taco",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-130-gr-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-crunch-barbeku-82gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-crunch-cheddar-sogan-82gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-popcorn-105-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-popcorn-85-gr-yer-fistigi-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-popcorn-85-grdomatesaci-biber",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-popcorn-85-grmevsim-yesipermasan-a",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peyman-nutzz-popcorn-90-gr-yer-fistigi-badem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/peynir-helvasi-kg-malkarali-seref",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pfanner-meyve-su-1-lt-cranberry",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pfanner-meyve-su-1-lt-frenk-uzumu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pfanner-meyve-su-1lt-ananas6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pfanner-meyve-su-2-lt-yesil-elma6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pfanner-meyve-suyu-2-lt-limonata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/philadelphia-taze-peynir-original-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/philips-tornado-ampul-15-w-e-27-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/philips-twister-amp-23w-e-27-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pide-ramazanlik-330-gr-1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-1-lt-pet-cool-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-1-lt-pet-hibiscus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-1-lt-pet-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-250-ml-cam-cool-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-250-ml-cam-hibiscus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-250-ml-cam-orange-mandalina",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-250-ml-cam-orijinal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piin-soguk-cay-250-ml-cam-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piknik-agac-adana-sis-40li-114-p",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piknik-agac-bambu-cop-sis-25-cm100lu-113-p",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piknik-agac-kurdan-200lu-jelatinli-117-p",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piknik-asure-sutlac-kabi-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piknik-desenli-tatli-kasesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-ayran-1-lt-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-ayran-300-ml-lezzetin-alasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-180-gr2-avantaj-paketi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-ext-yumusak-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-beyaz-peynir-800-gr-salamurali12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-burger-545-greko-donuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-burger-gurme-extra-240-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-burger-mini-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-but-etli-fume-hindi-gurme-110gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-cool-lime-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-cool-lime-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-dilimli-tost-peyniri-300-gr-kahvalti-keyfi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-fume-hindi-gurme-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-fume-hindi-karabiberli-gurme-110gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-gogus-fume-hindi-gurme-110gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-gurme-frankfurter-255-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-gurme-hindi-frankfurter-255-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-hardal-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-hardal-balli-280-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-hsalam-50-gr-ac-bitir-fistikli-bdilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kahvalti-keyfi-kasar-600gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kahvalti-kremasi-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kasap-illaki-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kefir-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kefir-200-ml-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-kefir-200ml-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-krem-peynir-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-krem-peynir-cheddarli-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-krema-eksi-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-labne-180-gr-avantaj-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-labne-180-gr-kurutulmus-domatesli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-labne-180-gr-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-labne-180-gr-sarimsak-ve-fsogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-limonata-033-lt-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-limonata-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-msalam-fistikli-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-msalam-fistikli-50-gr-bdilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-pacanga-boregi-270-gr-donuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-salam-macar-ac-bitir-50-gr-buyuk-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-soguk-cay-1-lt-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-soguk-cay-330-ml-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sosis-gurme-bratwarst-255-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sosis-gurme-cabanos255-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sosis-hot-dog-dana-sosis-295-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-033-lt-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-040-lt-piremium-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-05-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-075-lt-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-1-lt-premium-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-su-15-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sucuk-75-gr-ac-bitir-16",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sucuk-90-gr-ac-bitir-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sucuk-hindi-ac-bitir-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sucuk-hindi-dilimli-80-gr-ac-bitir-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-1-lt-15-yagli-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-11-ltorganik-light-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-12-lt-cocuk-sutu-balli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-12-lt-ekstra-light-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-12-lt-light-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-12-ltprotein-kahveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-12-organik-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-15-lt-extra-light-27",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-200-ml-6li-yarim-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-cocuk-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-180-ml-cilekli-6-li-4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-180-ml-muzlu-6-li-4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-180ml-cilekli-27",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-180ml-muzlu-27",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-kakaolu-laktozsuz-180-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-kido-karamelli-180-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-sut-tozu-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-tereyag-200-gr-yayik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-ucgen-peynir-yyagli-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-yogurt-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-yogurt-2000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-yogurt-2000-gr-yarim-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pinar-yogurt-probiyotik-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piyam-galeta-cekirdekli-200-gr-kepekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/piyam-galeta-cekirdekli-200-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/plife-aliminyum-sutlac-kabi-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/plife-cop-sis-adana-25-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/plife-kagit-bard-7oz-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/plife-kristal-seffaf-bardak-180-cc-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pol-s-glutensiz-tahin-helvasi-175-gr-uzum-pekmez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pol-s-glutensiz-tahin-helvasi-175-gr-visne-pekmez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polente-sut-receli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-200-gr-dana-sosis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-220-gr-hindi-sosis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-50-gr-dana-jambon-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-50-gr-macar-salam-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-dfume-90-gr-biberli-dilimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-fit-dana-jambon-60-gr24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-fit-hindi-fume-eti-50-gr24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-fit-hindi-jambon-50-gr24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonez-hsalam-50-gr-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-bergama-tulum-peynir-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-burger-peynir-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-burger-peynir-kg-dilim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-dil-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-kasar-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-orgu-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-tel-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-tereyag-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/polonezkoy-tereyag-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-chia-tohumlu-ahududu-receli-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-chia-tohumlu-erik-receli-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-chia-tohumlu-kayisi-receli-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-chia-tohumlu-nar-receli-380-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-chia-tohumlu-portakal-receli-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-elma-pekmezi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-freeze-fresh-cilek-15gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-freeze-fresh-kayisi-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-freeze-fresh-misir-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-freeze-fresh-portakal-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-freeze-fresh-visne-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pols-hurma-pekmezi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-bulasik-makinesi-parlatici-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-bulasik-makinesi-temizleyici-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-camasir-makinesi-temizleyici-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-kucuk-ev-aletleri-temizleyici-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-sirkeli-yuzey-temsprey-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/porcoz-sprey-premium-leke-cikarici-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/portakal-sikma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/posta-gazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/powerade-500-ml-sporcu-icecegi-ice-blast-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-blok-mozzarella-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-gouda-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-kasar-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-krema-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-peynir-180gr-taze-surulebilir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/president-rende-mozzarella-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-4-kg-losyon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-excelensce-tablet-22li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-excelensce-tablet-40li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-excelensce-tablet-60li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-gold-jel-900-ml-hijyen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-hepsi-bir-arada-extra-tablet-45li-hia",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-power-675-gr-adana-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pril-tuz-2000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-bebek-bezi-standart-no4-maxi-32-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-bebek-bezi-standart-no7-beden-19lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-cbezi-premium-jumbo-mini-88-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-firsat-paketi-no4-50-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-firsat-paketi-no4-54-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-firsat-paketi-no6-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-islak-mendil-fresh-52-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-pants-kulot-bebek-bezi-7-no-14lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-pants-kulot-bebek-bezi-extralarge-15-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-pants-kulot-bebek-bezi-maxi-21-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-junior-19-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-maxi-23-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-maxi-28-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-midi-26-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-midi-32-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-mini-30",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-mini-37",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-xxl-17",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-bebek-bezi-ikiz-pk-ydogan-33-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prima-prem-care-ekono-paket-35li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-130-gr-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-165-gr-original-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-40-gr-paprika",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-70-gr-eksi-krema-sogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-70-gr-original",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pringles-cips-70-gr-paprika",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/privacy-women-150-ml-addiction-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/proline-bentonit-kedi-kumu-5-lt-pudra-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/proline-bentonit-kedi-kumu-5-lt-sabun-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pronto-ahsap-temizleyici-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pronto-ahsap-temizleyici-750-ml-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pronto-mobilya-sprey-300-ml-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-bel-bantli-buyuk-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-bel-bantli-orta-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-bel-bantli-x-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-emici-kulot-buyuk-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-emici-kulot-buyuk-8-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-emici-kulot-orta-10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-emici-kulot-orta-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-hasta-bezi-emici-kulot-x-large-30lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-mesane-pedi-10lu-normal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-mesane-pedi-10lu-yogun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/prosafe-yatak-koruyucu-30lu-6090",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-1500-ml-ultra",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-300-ml-herbal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-300-ml-ultra-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-600-ml-cream",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-700-ml-ultra-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/protex-sivi-sabun-700-mlcream",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pure-paws-kedi-kumu-5lt-aloe-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/pure-paws-kedi-kumu-5lt-lavanta-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purelife-htuzu-115-gr-aci-biberli-deg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-gourmet-gold-kedi-mama-85-gr-hindi-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-gourmet-gold-kedi-mama-85-gr-sigir-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-gourmet-gold-kedi-mama-85-gr-tavuk-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-gourmet-gold-kedi-mama-85-gr-ton-balikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-200-gr-junior-tavuklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-200-gr-sigir-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-200-gr-tavuklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-800-gr-junior-tavuklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-800-gr-sigir-etli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/purina-one-kuru-mama-800-gr-somonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/qlux-320337-l-301-mini-kek-kapsulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/qlux-l-172-krema-torbasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/quru-kurutulmus-elma-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/quru-kurutulmus-portakal-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/quru-kurutulmus-tarcinli-elma-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/quru-kurutulmus-trabzon-hurmasi-19-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/r-sport-abademli-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/r-sport-uzumlufindikli-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/raffaello-150-gr-t15",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/raid-electro-likit-2-li-yedek-60-gece12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/raid-gecegunduz-sistem12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/raid-karinca-yemi-11",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/real-thai-pirinc-cub-rice-vermicell-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/real-thai-pirinc-yufkasi-22-cm-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-100-ml-ice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-125-ml-dark-spice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-125-ml-ice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-125-ml-jasmine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-125-ml-pine-forest",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-aqua",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-dark-spice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-ice",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-jasmine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-lavanda",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-mandarine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rebul-kolonya-250-ml-pine-forest",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-blue-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-classic",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-kayisi-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-white-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-250-ml-yellow-edition",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-355-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/red-bull-enerji-icecek-473-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-asurelik-bugday-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-cin-misir-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-esmer-pilavlik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-gonen-baldo-pirinc-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-kepekli-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-kirmizi-mercimek-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-kuru-fasulye-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-kuru-fasulye-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-nohut-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-osmancik-pirinc-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-pilavlik-bulgur-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-royal-barbunya-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-royal-basmati-pirinc-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-royal-battal-fasulye-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-royal-beyaz-nohut-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-royal-ic-bakla-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reis-yesil-mercimek-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-hepsi-bir-arada-tablet-33lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-hepsi-bir-arada-tablet-44lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-15-kg-renkliler-plt-50",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-15-kg-renkliler-ve-beyazlar-plt-50",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-45-kg-renkliler-plt-165",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-45-kg-renkliler-ve-beyazlar-plt165",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-8-kg-renkliler-ve-beyazlar-plt-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-matik-8-kg-renklilerplt-100",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-svi-2520ml-beyazlar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-svi-2520ml-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/renax-svi-2520ml-siyahlar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/resimli-cocuk-simit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-cotton-bayan24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-invis-blackwhite-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-invisible-blackwhite-clothes",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-musk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-ocean-frehs-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-s-clean",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-ml-shower-fresh-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150-mladv-invisible-pure-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo-150ml-lime-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo150-ml-musk-bayan24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo150-ml-sexy-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-deo150-ml-stay-fresh-bayan24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-bouqith-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-cotton12-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-invisible-men12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-on-xtra-coll-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-shower-clean12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-roll-on-50-ml-v812-bay",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rexona-seftali-ve-limon-otu-sprey-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/reyhan-demet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/right-cok-amacli-mutfak-temiz-600-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rine-dijon-hardal-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rine-dijon-taneli-hardal-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rinso-matik-4-kg-limon-karbonat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rinso-matik-6-kg-deniz-mineralleri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rinso-sivi-deterjan-15-lt-komple-bakim-aloe-v",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rinso-sivi-deterjan-3-lt-komple-bakim-argan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rinso-sivi-deterjan-3-lt-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rixyl-misir-cipsi-40-gr-cedar-peynir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rixyl-misir-cipsi-40-gr-fistik-ezmeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rixyl-misir-cipsi-40-gr-pizza-cesnili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rocat-kedi-kumu-5-lt-pudra-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rocs-dis-fircasi-baby-0-3-yas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rodeo-115-sac-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rodeo-1241-sac-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rodeo-6003-sac-fircasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rodeo-tirnak-fircasi-405",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roka-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-7-oz-karton-bardak-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-7-oz-karton-bardak-25li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-karton-bardak-4-oz-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-piknik-seti-37-parca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-bardak-50li-seffaf-180cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-catal-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-kasik-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-lux-bicak-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-lux-catal-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-plastik-lux-kasik-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-pls-bardak-10lu-250-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/roll-up-yagli-pisirme-kagidi-kesilmis-3842-16-yap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ruffles-cips-107-gr-ketcap-super-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ruffles-cips-107-gr-mangalda-et-tadinda-super",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rufflesdoritos-ikiz-paket-411-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rummo-penne-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rummo-penne-rigate-glutensiz-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rummo-spaghetti-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/rummo-spaghetti-glutensiz-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ruzgar-gulu-20cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ruzgar-gulu-30-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/s-pellegrino-maden-su-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sabdullah-gullac-100-gr-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sabdullah-gullac-400-gr-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sabdullah-gullac-400-gr-tepsi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-aycekirdek-ici-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-bugday-ruseymi-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-cin-misir-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-karadut-suyu-1000-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-kizilcik-suyu-1000-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-kuru-fasulye-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-siyez-bulgur-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-yaban-mersini-suyu-1000-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-yesil-mercimek-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sade-organik-yulaf-ezmesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-dogal-kaya-tuzu-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-himalay-tuzu-150-gr-degirmen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-himalay-tuzu-premium-200-gr-degirmen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-himalaya-tuzu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-iyotsuz-tuzluk-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-iyotsuz-tuzluk-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/safir-sofrada-ogutme-tuz-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sahin-pastirma-80-gr-cemeni-siyrilmis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sahin-uzun-tane-pirinc-5-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/salep-toz-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-mutfak-sivi-sabun-485-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-okyanus-ferahligi-sivi-sabun-18l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabsultan-hasbahce-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-beyaz-sabun-kokulu-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-dogal-okyanus-ferh-15l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-dogal-zeytinyagli-15l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-dogal-zeytinyagli-18l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-gul-15l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-sultan-has-bahce-18-l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-sultan-has-bahce-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-sultan-hasbah-15l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-taze-mango-15l",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-taze-mango-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-sivi-sabun-zeytinyagli-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-ssabun-beyaz-sabun-kokulu-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-ssabun-gul-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saloon-ssabun-okyanus-ferahligi-3-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sampiyon-iskembe-corbasi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sampiyon-kelle-paca-corbasi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sampiyon-kokorec-pismis-sade-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sampiyon-kokorec-pismis-sebzeli-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sampiyon-midye-dolmasi-20-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sana-marg250-gr-kase",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sana-marg500-gr-16-kase",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sana-margarin-250-gr-paket-margarin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sana-tereyagi-lezetti-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sandef-bazlama-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sandef-burgersandvic-ekmek-200gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sandef-eksi-mayali-ekmek-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sandef-t-bugday-sandvic-ekmek-350gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sanino-dis-macunu-75-ml-komple-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sanino-dis-macunu-75-ml-maksimum-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sanino-dis-macunu-75-ml-sensitive-hashas-disler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/santral-zeytin-ezmesi-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/santral-zeytin-ezmesi-340-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saol-vitamin-water-500-ml-antioxidant",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saol-vitamin-water-500-ml-cmix",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saol-vitamin-water-500-ml-men-power",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saol-vitamin-water-500-ml-multi-vitamin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saol-vitamin-water-500-ml-women-fit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarelle-bitter-findik-kremasi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarelle-gofret-33-gr-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarelle-gofret-33-gr-hindistan-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarelle-kakaolu-findik-kremasi-350-gr-duo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarelle-kakaolu-findik-kremasi-350-gr-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-14-gr-peynir-kabi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-ahsap-karistirici-500-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-havlu-8-li-akilli-secim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-cimri-tuvalet-kagidi-6-li-5-kg-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-cop-poseti-80110-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-dispenser-pecete-18250",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-erze-kopuk-light-tabak-mod16-2000-gr150",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-erze-kopuk-tabak-mod114-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-erze-kopuk-tabak-mod16-2000-gr150",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-erze-kopuk-tabak-mod2-light-500-gr250",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-erze-kopuk-tabakmod1-1000-gr250",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-et-kasa-poseti-70202080",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-icten-cekmeli-hareketli-havlu-21-cm-4kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-karton-bardak-3000-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-oxy-camasir-suyu-parfumlu-30kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-oxy-camsil-5-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-oxy-sivi-el-sabunu-30-kg-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-oxy-yuzey-temizleyici-30-kg-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-poset-vakumlu-1530-sarkuteri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-poset-vakumlu-1822-sarkuteri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-poset-vakumlu-2030-sarkuteri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-rulo-manav-alisveris-poseti-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-sizdirmaz-kab-1000-gr600-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-sizdirmaz-kab-250-gr1000-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-sizdirmaz-kab-375-gr1000-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-sizdirmaz-kab-500-gr800-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-sizdirmaz-kab-750-gr600-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-strech-film-40cm15008-micc-sarkuteri-wrap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-strech-film-45cm15009-mic-kasap-wrap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-tavuk-pedi-12517cm-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-tel-pet-5-li-cila-teli-sarf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-vakum-poseti-et-icin-2270",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarf-vakum-poseti-et-icin-4050",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarihanlar-ayak-paca-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarihanlar-dil400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarihanlar-iskembe-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarihanlar-kelle-paca-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarimsak-200-gr-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarimsak-file-250-gr-orhan-reis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarimsak-soyulmus-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarimsak-taze-bas-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sarmisak-taze-demet-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/saviordi-buskivi-200-gr-kedi-dili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/savon-de-royal-sivi-sabun-500-ml-inci-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/savon-de-royal-sivi-sabun-500-ml-inci-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/savon-de-royal-sivi-sabun-500-ml-inci-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sbenedetto-spark-mineral-water-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sbrite-paspas-pratik-set-yedek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sbrite-sunger-pratik-yedek2-li-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-choco-balls-cik-250-gr-misir-gev",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-cornflakes-sade-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-kaymakli-cikolatali-biskuvi-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-makarna-250-gr-burgu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-makarna-250-gr-kalem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-melto-bar-30-gr-chocolate",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-noccioli-gofret-63-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-ouadritos-40-gr-cik-kap-gofret",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-pan-mult-ket-aycekir-ekm-250-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-pasta-capelli-sehriye-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-pasta-unu-1000-gr-mix-b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-spagetti-makarna-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-twin-bar-645-gr-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schar-glutensiz-wafers-alle-n0-125-gr-findikli-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schogetten-alpine-milk-hazelnuts-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schogetten-cikolata-sutlu-100-gr15-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schogetten-yoghurt-strawberry-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-1-lt-limon-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-1-lt-mandalina-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-250-ml-limon-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-250-ml-limon-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-250-ml-mandalina-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-250ml-mandalina-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-gazoz-6250-ml-mandalina-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/schweppes-tonik-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/scotti-arborio-pirinci-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/scotti-hakumaki-sushi-pirinci-500gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/scotti-risotto-pirinci-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sea-food-yaprak-yosun-28-gr-10-yaprak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seftali-2boy-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/segmen-probiyotik-recel-380-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/segmen-probiyotik-recel-380-gr-karadut",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/segmen-probiyotik-recel-380-gr-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-ciftlik-yogurt-1200-gr-tam-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-ciftlik-yogurt-900-gr-tam-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-kasar-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-krema-200-ml-tetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-meyve-su-1-lt-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-quark-140-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-1-lt-pastorize-laktozsuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-1-lt-pastorize-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-1-lt-pastorize-yeni-nesil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-1lt-pastorize-sise-yogurt-sutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-200-ml-pastorize-cikolatali-tetratop",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-200-ml-pastorize-cilekli-tetratop",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-200-ml-yagli-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sut-500-ml-pastorize-yeni-nesil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-sutlu-kahve-cikolatali-mocha-330ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-tereyag-1-kg-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-tereyag-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sek-yogurt-1500-gr-yyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selale-aseton-50-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selektia-beyaz-truflu-sos-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-ardic-kolonyasi-pet-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-buz-ferhligi-kolonyasi-pet-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-cicek-buketi-kolonyasi-pet-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-kiraz-cicegi-kolonyasi-pet-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-kolonya-150-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-kolonya-200-ml-pvc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-kolonya-900-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-nemlendirici-cam-kolonya-180-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-okyanus-kolonyasi-pet-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selin-parfumlu-kolonya-180-ml-bahce-gulu-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-antibakteriyel-56-li-islak-havlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-cek-al-mendil-100-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-cep-kagit-mendil-6-li-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-dev-rulo-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-dev-rulo-24-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-el-ve-yuz-havlusu-100-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-el-ve-yuz-havlusu-80-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-extra-desenli-6li-havlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-islak-mendil-3-lu-antibakteriyel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-bez-75-mtx",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-havlu-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-havlu-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-havlu-8-li-yag-emici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-mendil-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kagit-mendil-mentollu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-kutu-mendil-taraftar-serisi24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-pecete-50-li-mini-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-tuvalet-kagidi-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-tuvalet-kagidi-32-li-extra-pamuk-kat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-tuvalet-kagidi-extra-banyo-fer16-tk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/selpak-tvltkagidi-8-li-pudra-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/semizotu-taze-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-cok-yonlu-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-hassasiyetdis-eti-beyazlatici12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-hassasiyetdis-eti12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-hizli-rahatlama12-regular",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-promine-onarim-beyazlatici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-75-ml-pronamel12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dfirca-11-cok-yonlu-koruma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-eti-dis-fircasi-yumusak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-firca-hassasdis-eti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-firca-onarim-koruma-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-firca-promine-onarim-gentle",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-fircasi-tam-koruma-soft",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-macunu-20-ml-derin-temizlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dis-macunu-pronamel-beyazlatici-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-dogal-beyazlik-aktif-komur-dis-macunu-75",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-hassasiyet-dis-eti-dis-minesi-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-herbal-dis-macunu-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-nourish-dogal-ferahlik-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensodyne-tam-koruma-75mlfirca-hediyeli12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sensoy-sushi-nori-yosun-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seramik-aura-kupa-9-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seramik-tabak-20-cm-baskili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/serhas-taze-kasar-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/serhas-taze-kasar-500-gr-hasmandira",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/servis-pecete-100-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-agda-bandi-vucut-42li-hassas-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-agda-bezi-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-sir-agda-100-ml-roll-on-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-sir-agda-100-ml-roll-on-normal-cilt-mavi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-sir-agda-100-ml-roll-on-normal-cilt-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-sir-agda-bandi-24lu-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-sir-agda-bandi-24lu-komurlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-tuy-dokucu-kopuk-150-ml-normal-tuyler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-tuy-dokucu-krem-100-ml-for-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-tuy-dokucu-krem-100-ml-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-tuy-dokucu-krem-100-ml-normal-tuyler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sesu-tuy-sarartici-krem-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sev-sp200-narenciye-sikacagi-48",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seven-7up-pet-1-lt-gazoz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sevindik-yumurta-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-bal-20gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-helva-175-gr-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-helva-175-gr-kakolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-helva-175-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-helva-200-gr-firindabaliktan-sonra",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-pekmez-keciboynozu-450-gr-ac-dok-bidon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-pekmez-keciboynuzu-ozu-640-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-pekmez-uzum-450-gr-ac-dok-bidon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-pekmez-uzum-ozu-640-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-recel-20gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-recel-380-gr-bahce-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-300-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-350-gr-ac-dok-bidon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-helvasi-400-gr-kurek-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-helvasi-400-gr-kurek-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-helvasi-500-gr-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-helvasi-500-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tahin-pekmez-740-gr-ikiz-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyidoglu-tereyag-10gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/seyyidler-cezerye-300-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/shandong-bambu-mat-beyaz-24x24-cm",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/she-deo-150-ml-sexy-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/she-deo150-ml-fun-kadin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sifalikoy-bamya-cicek-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sifalikoy-kurutulmus-aci-sus-biber-25-ad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sifalikoy-kurutulmus-dol-aci-biber-20-ad",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sifalikoy-kurutulmus-patlican-20ad-antep",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sifalikoy-kurutulmus-patlican-25ad-aydin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-11-dfirca-performance-black",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-11-dfirca-vertical-expert12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-21-dfirca-performance",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-31-dfirca-clean-action",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dfirca-carbon-correct12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dfirca-double-care",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dfirca-kids-3-12-yas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dfirca-sensitive-expert12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dfirca-vertical-expert12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macun-75-ml-white-now-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macun-75-ml-white-now-gold",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macun50-ml-wht-power-mint-zaha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macunu-75-ml-white-now-a-beyazlik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macunu-75-ml-white-now-cc-bright",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macunu-75-ml-white-now-glossy-shine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-dis-macunu-75-ml-white-now-men",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-uclu-koruma-disl-macunu-120-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-w-now-dmac-115gr-performance-dis-fircas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-white-now-11-dis-firca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signal-white-now-sensitive-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/signaldfirca-beyaz-guc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silikon-muffin-kalibi-6-li-1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silindir-ince-mum-kutuk-beyaz-kucuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silindir-kalin-mum-kutuk-kirmizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silivri-arslan-kaymakli-yogurt-900-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silivri-arslan-yogurt-1000-gr-manda",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silivri-arslan-yogurt-1000-gr-manda-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silivri-arslan-yogurt-200-gr-mandainek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/silivri-arslan-yogurt-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-acilan-un-2-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-altin-hasat-katkisiz-un-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-ekmek-unu-cavdar-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-ekmek-unu-koy-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-ekmek-unu-tahilli-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-galeta-unu-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-dilimli-tost-ekmegi-360-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-hamburger-ekmegi-285-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-hindistan-cevizli-tatli-kurabi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-karabugdayli-baton-ekmek-350-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-kurabiye-susamlicorekotlu-110",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-kurabiye-zedecalkirmizi-biber",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-sandvic-ekmegi-240-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-gluten-yok-uzumlu-kurabiye-120-g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-guzel-kabaran-un-2-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-hamur-kabart-tozu-10gr-5li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-karbonat-5-gr-5li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-kremsanti-150gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-organik-bugday-unu-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-pudra-sekeri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-siyez-unu-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-tam-bugday-unu-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-un-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-un-5-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sinangil-vanilin-5-gr-5-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sir-asprins-leke-cikarici-750-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sir-yag-sokucu-750-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-desenli-bambu-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-desenli-koton-bayan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-desenli-koton-erkek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-erkek-corap-koton-sneakers-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-kadin-corap-koton-sneakers-yz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-koton-erkek-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-koton-jakarli-erkek-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-koton-patik-erkek-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-lakost-sneakers-erkek-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-modal-bayan-corabi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sirim-tenis-erkek-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/skittles-draje-seker-38-gr-crazy-sours",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-anti-viral-islak-mendil-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-bebek-bezi-naturel-2li-firsat-junior-58x3",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-bebek-bezi-naturel-2li-firsat-maxi-82x3",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-bebek-bezi-naturel-2li-firsat-xl-46x3",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-bio-natural-midi-28li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-bio-natural-yenidogan-32li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-dogalorganik-islak-havlu-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-islak-bakim-havlusu-350li12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-islak-havlu-90-li-beyaz-sabunlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-natural-ultra-gece-gunluk-1832x8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-natural-ultra-normal-gunluk-2440x8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-naturel-36li-gece-64lu-uzun-gunluk-avanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-petcare-evcil-hayvan-ortusu-6090-30x4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-petcare-islak-bakim-havlusu-24x60",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-sen-ikiz-6090-30x4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-sen-ikiz-large-30x4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-sen-ikiz-medium-30x4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-sen-ikiz-xl-large-30x4",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-sen-vct-tem-mendili-6x3x50",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-tem-havlusu-mop-50-li-camasir-su",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-temizlik-havlusu-100-lu-arap-sabunlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-temizlik-havlusu-100-lu-camasir-suyu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-temizlik-havlusu-100-lu-easy-clean",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-temizlik-havlusu-mop-50-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sleepy-yuzey-temizlik-havlusu-mop-50-li-arap-sab",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sma-optipro-probiyotik-1-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sma-optipro-probiyotik-2-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sma-optipro-probiyotik-3-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/snickers-cikolata-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/snickers-cikolata-bar-80-gr-ikili-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/so-british-cheddar-200-gr-dilimli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sogan-ekonomik-kuru-sogan-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sogan-erdek-kapidag-sogan-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soke-esmer-kepekli-unu-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soke-glutensiz-un-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soke-ruseymli-un-karisimi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soke-tam-cavdar-unu-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soke-yulafli-un-karisimi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-biscolata-nirvana-gofret-117-gr-minis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-biscolata-veni-gofret-50-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-biscolata-veni-gofret-50-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-kare-chocodans-findikli-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-nutymax-gofret-44-gr-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solen-nutymax-gofret-44-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solia-chopstick-japon-cin-yemek-cubuk-1cift",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-dezenfektan-islak-tembezi-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-havlu-12-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-kagit-havlu-16-li-bambu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-kagit-havlu-3-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-kagit-havlu-6-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-kagit-havlu-6li-bambu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-mini-tvlt-kagidi24-50-yaprak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-pecete-150-li-gunluk-cek-al",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tum-yuzeyler-islak-tembezi-40-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-12-li-bambu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-16-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-16-li-akilli-secim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-24-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-32-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-40-li-bambu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tuvalet-kagidi-8-li-akilli-secimler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-tvlt-kagidi-24-lu3-akilli-secimhb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-yuzey-temizleme-havlusu-80li12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/solo-yuzey-tm-mndl-100-lu-okaliptus-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/son-curet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/soyacak-set-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/spiderman-yara-bandi-10-lu-ince-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/splenda-granul-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/splenda-stevia-tablet-80-li-64-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/splenda-tablet-100lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sprite-gazoz-6200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/squid-balik-sos-725-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/squid-balik-sosu-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/st-clemens-brie-125gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/st-clemens-camembert-125gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/st-clemens-danish-blue-100gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/st-dalfour-portakal-receli-284-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-cafe-verano-200-gr-filtre-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-cinn-dolce-latte-4235gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-colombia-200-gr-filtre-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-200-ml-doubleshot-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-220-ml-caramel-macchiato-brd",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-220-ml-doubleshot-sekersiz-can",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-220-ml-latte-brd",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-220-ml-skinny-latte-brd",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-220-ml-whitw-cho-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-coffee-owb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-daily-brew-sutli-khv",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-daily-brew-sutlu-vanilya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-frappuccino-owb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-frappuccino-vanilla",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-kahve-250-ml-mocha-owb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-rg-veranda-100-gr-turk-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-14-gr-cappuccino",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-14-gr-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-20-gr-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-215-gr-vanilla-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-23-gr-caramel-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-stick-kahve-24-gr-white-mocha",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-toffee-nut-latte-423gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/starbucks-verona-200-gr-filtre-kahve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-3lu-bez-30-microfiber-32x38",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-3lu-microfiber-temizlik-bezi-puffy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-8li-sari-bez-30-microfiber-34x35",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-aluminyum-folyo-5-metre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-black-bulasik-sungeri-5li-oluklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-black-cop-torbasi-buzgulu-buyuk-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-black-cop-torbasi-buzgulu-orta-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-bulasik-sungeri-5li-oluklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-buzdolabi-poseti-buyuk-boy-20-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-buzdolabi-poseti-orta-boy-20-li-pl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-buzgulu-buyuk-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-buzgulu-orta-boy-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-buzgulu-orta-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-buzgulu-orta-lavanta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-premium-battal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-premium-jumbo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-cop-torbasi-siyah-buzgulu-buyuk-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-hobby-sunger-gulenyuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-kaka-torbasi-tasima-aparati",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-kilitli-poset-orta-boy-10lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-klasik-paspas-2li-yedek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-lowly-sunger-gulenyuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-strec-film-33-metre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-sunger-comfort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-sunger-inox-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stepy-viora-tasima-torbasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stillo-kestaneci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/stillo-krema-torbasi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sumerli-chia-tohumu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sumerli-nohut-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sumerli-osmancik-pirinc-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sumerli-pilavlik-bulgur-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sumerli-yesil-mercimek-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sun-sc-421-metal-kek-kapsulu-6li-60",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sun-sc-445-neon-cirpici",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sun-sc-449-neon-kapak-acacagi-36",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sun-sc-481-neon-metal-konserve-kapak-acacagi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sunpride-100-7-meyve-7-vitamin-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sunpride-100-domates-suyu-250-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sunpride-meyve-su-250ml-100-nar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sunpride-meyve-suyu-1-lt-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sunpride-meyve-suyu-250-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-baba-burger-360-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-bamya-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-bamya-sultani-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-bezelye-1000gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-bogurtlen-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-bruksel-lahanasi-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-cikolatali-pizza-tost-210-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-falafel-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-fir-mini-baget-ekmek-kep-8li-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-firinda-manti-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-firindan-mini-baget-ekmek-8li-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-frambuaz-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-frambuaz-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-garnitur-540-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-gul-boregi-ispanakli-nohutlu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-gul-boregi-pirasali-havuclu-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ispanak-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-karnabahar-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-kasap-kofte-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-kasarli-pogaca-6-li-432-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-kunefe-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-milfoy-1000-gr-tereyagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-misir-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-misir-3200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-patates-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-pizza-king-2li-390-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-pizza-king-300-gr-slimmo-suprem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-rulo-borek-420-gr-sosisli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-rulo-borek-500-gr-kiymalipatatesli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-sade-pogaca-6li-420-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-sogan-halkasi-425-gr-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tava-borek-360-gr-ispanakli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-taze-fasulye-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tepsi-boregi-patatesli-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tepsi-boregi-penirli-650-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-aycicekyag-2140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-aycicekyag-2150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-aycicekyagli-475-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-fasulyeli-ton-balik-2150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-tahilli-ton-balik-2150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-zeytinyagli-2140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-ton-zeytinyagli-2150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tost-180-gr-tavuk-donerli-durum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tost-pizza-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tost-pizza-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tost-pizza-cheddarli-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-tost-pizza-cheddarli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-visne-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/superfresh-visne-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-poke-bowl-250-gr-karidesli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-poke-bowl-250-gr-somon-fume",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-poke-bowl-250-gr-ton-balikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-tokyo-mix-190-gr-dardanel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-wrap-calfornia-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-wrap-somon-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sushida-wrap-ton-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-ayran-300-ml-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-babymix-4100-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-beyaz-peynir-200gr-suzme-kupkup",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-cecil-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-cilek-receli-3620-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-dil-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-izgara-peyniri-360-gr-izgarella",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kaf-kefir-1l-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kaf-kefir-250-ml-orm-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kaf-kefir-laktozsuz-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kaf-kefir-orman-meyveli-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kasar-peynir-400-gr-pide-pizza",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kasar-peyniri-pors4020-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kasar-peyniri-rende-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kaymak-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-klasik-kasar-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kolay-cacik-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kolot-peynir-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-krem-pey-20-gr-porsiyon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-kutu-kutu-kahvalti-seti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-labne-20-gr-porsiyon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-latte-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-meyveli-yogurt-475-gr-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-mihalic-peyniri-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-orgu-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-peynir-izmir-tulum-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-sut-15-lt-muzlu-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-sut-180-ml-cilekliseker-ilavesiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-sut-1lt-cikolta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-sut-200-ml-lihgt-uht",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-suzme-yogurt-900-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyag-1000-gr-pastorize-rulo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyag-450-gr-yayik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyag-4815-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyag-750-gr-pastorize-rulo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyagi-225-gr-yayik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-tereyagi-250-gr-yayik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-yayik-tereyag-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-yogurt-2000gr-kase-kaymaksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-yogurt-500-gr-sarmisakli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/sutas-yogurt-750-gr-k-kaymaksiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/t-ciftligi-vegan-salam-tadinda-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-ankara-tava-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-domates-corbasi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-etli-kuru-fasulye-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-etli-nohut-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-biscotti-110-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-hindistan-cevizli-kurabiye-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-kahveli-findikli-kurabiye-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-karabugdayli-grissini-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-mercimekli-karniyarik-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-peynirli-dereotlu-mini-kraker-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-glutensiz-sebzeli-mini-krakert-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-hamsi-konservesi-limon-soslu-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-hindili-bulgur-pilavi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-klasik-ton-baligi-2150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-klasik-ton-baligi-375-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-misir-3200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-nohutlubasmati-pirinc-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-patatesli-salsa-soslu-tavuk-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-patlican-kizartmasi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-sardalya-konservesi-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-sebzeli-bulgur-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-tavuk-konservesi-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-tereyagli-cayeli-fasulye-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-yayla-corbasi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-zeytin-yagli-yaprak-sarma-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-zeytin-yagliispanak-200-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-zeytin-yaglimercimeklienginar-200-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-zeytintyagli-fasulye-250-gr-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tada-zeytinyagli-kuru-patlican-dolmasi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-15-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-30-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-30-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-60-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-60-gr-antep-fistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-bol-sutlu-cikolata-60-gr-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-findik-dol-sutlu-cik-poset-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-gofret-kup-200-gr-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-gofret-kup-200-gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-gofret-kup-200-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadelle-maxinut-gofret-30-gr-yer-fistigi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-antep-fistik-122-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-antep-fistik-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-aycekirdek-143gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-aycekirdek-143gr-bol-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-aycekirdek-180-gr12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-aycekirdek-68-gr-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-aycekirdek-68-gr-siyah-bol-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-badem-ici-135-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-barbeku-soslu-misir-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-cift-kavrulmus-sari-leblebi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-cig-findik-ici-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-citir-fistixx-140-gr-eksi-kremasogan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-citir-fistixx-140-gr-taco-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-festival-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-fistixx-180-gr-aci-baharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-fistixx-180-gr-klasik-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-karisik-kokteyl-75-gr-favori",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-kavrulmus-kaju-fistik-140-grzarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-kuruyemis-bar-40-gr-hurmafindiktarcin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-sari-leblebi-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-tuzlu-yer-fistik-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-tuzlu-yer-fistik-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tadim-yer-fistigi-kaplamali-aci-sos-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taft-7-days-straight-balm-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-kup-seker-500-gr-kahverengi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-stevia-zero-toz-tatlandirici-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-tablet-tatlandirici-18-gr-stevia-300",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-tablet-tatlandirici-18-gr-sucralose-300",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-tablet-tatlandirici-32-gr-650-tablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-toz-seker-500-gr-kahverengi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/takita-toz-tatlandirici-500-gr-diyabetik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-aci-sos-240-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-barbeku-sos-260-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-biber-salcasi-aci-580-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-domates-salcasi-370-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-hardal-240-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ice-tea-15-lt-limon-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ice-tea-15-lt-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ice-tea-200-ml-karpuzcilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ice-tea-330-ml-limon-lime",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ice-tea-330-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-jalapeno-biber-tursusu-aci-370-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-kahv-sos-300-gr-acili-fin-ve-cev",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-kahv-sos-300-gr-findikli-ve-cevizli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-kayisi-nektari-kahverengi-sise-200-ml-markal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ketcap-355gr-mayonez-320-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ketcap-600-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ketcap-600-gr-mayonez-540-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-kozl-patlican-520-gr-normal-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-kozlenmis-kirmizi-biber-670-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-1-lt-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-1-lt-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-1-lt-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-1-lt-mango",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-250-ml-karisik-nek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-su-250-ml-seftali-nek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-meyve-suyu-200-ml-seftali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-misir-3210-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-napoliten-sos-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-pesto-sos-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-premium-recel-380-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-premium-recel-380-gr-incir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-premium-recel-380-gr-karakiraz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-ranch-sos-220gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-recel-cilek-290-gr-seker-ilavesiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-recel-visne-290-gr-seker-ilavesiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-seftali-nektari-kahveren-sise-200-ml-markal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-serbet-1lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-tursu-370-ml-kornison-12-cam350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-tursu-720-cc-karisik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-tursu-720-ml-kornison-12-cam-kvnz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tamek-visne-nektari-kahveren-sise-200-ml-markal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tampax-tampon-compak-22-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-biberli-yesil-zeytin-485-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-cekirdeksiz-izgara-zeytin-domatesli-500-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-findikli-incir-lokumu-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-hakiki-sivi-zeytinyagi-sabunu-400-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-hakiki-zeytinyagli-sabunu-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-incir-400-gr-protoben",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-kuru-incir-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-naturel-sizma-750-ml-1915",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-naturel-sizma-zeytinyag-2-lt-1915",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-naturel-sizma-zeytinyagi-500-ml-bellolio",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-organik-naturel-sizma-zeytinyag-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-riviera-zeytintyagi-250-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-riviera-zeytintyagi-750-ml-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-riviera-zeytinyag-1-lt-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-riviera-zeytinyagi-5-lt-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyag-1-lt-kuzey-ege-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyag-2-lt-guney-ege",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyag-2-lt-kuzey-ege-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyag-5-lt-kuzey-ege-tnk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyag-cam-750-ml-kuzey-ege",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyagi-1-lt-guney-ege",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyagi-250-ml-guney-ege",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyagi-250-ml-maraska",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyagi-500-ml-maraska",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-sizma-zeytinyagi-750-ml-guney-ege",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-yesil-cizik-zeytin-485-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-yesil-zeytin-500-cc-domates-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-yesil-zeytin-bademli-485-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/taris-yesil-zeytin-ezmesi-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tas-hcevizi-suyu-490-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tas-pirinc-makarnasi-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tassya-panko-ekmek-kirintisi-1-kg-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tassya-panko-ekmek-kirintisi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tassya-shirtaki-noodle-270-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-acur-tursusu-680-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-ajvar-balkan-sosu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-cevizli-ezme-300-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-domates-puresi-680gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-domates-suyu-1-lt-cam-sise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-domates-tursusu-680-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-fasulye-tursusu-700-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-hardal-3750-gr-kova",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-karnabahar-tursusu-680-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-ketcap-250-grmayonez-210gr-siki-dostlar-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-ketcap-390-gr-tatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-ketcap-650-grmayonez-560-gr-siki-dostlar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-kornison-tursu-680-gr-cam-alman",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-koy-biber-salcasi-560-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-lutenitsa-balkan-sosu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-mango-kori-sos-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-mantar-340-gr-cam-kvnz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-marmelat-370-gr-kaysi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-marmelat-370-gr-kusburnu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-marmelat-370-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-mayonez-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-mayonez-pls-205-gr-x",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-misir-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-napoliten-sos-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-organik-biber-salcasi-480-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-organik-dogranmis-domates-460-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-organik-dogranmis-domates-ren-470-gr-ca",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-peri-peri-sos-225-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-pesto-rosso-sos-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-pesto-sos-feslegen-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-recel-380-gr-bogurtlen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-recel-380-gr-kuru-incir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-tombul-bamya-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tat-turlu-660-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tatli-irmik-helvasi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tatli-kabak-tatlisi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tatsan-bademli-un-kurabiyesi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tatsan-bademli-un-kurabiyesi-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-cafi-kaps-kahve-107gr-caffe-espresso",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-cafis-kapsul-kahve-107gr-blackwhite",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-cafissimo-kaps-kahve-107gr-caffe-fine-mi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-cafissimo-kapsul-107gr-espresso-elegant",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-cafissimo-kapsul-kahve-107gr-caramel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-gold-selection-eko-paket-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-gold-selection-eko-paket-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-gold-selection-kavanoz-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-gold-selection-kavanoz-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-kahve-filtresi-14-80-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-privat-cekirdek-kahve-500-gr-brazil",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-privat-filtre-kahve-250-gr-brazil-mild",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tchibo-privat-filtre-kahve-250-gr-latin-grande",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tealight-mum-18-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tealight-mum-25-li-kirmizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tealight-mum-25-li-kirmizi-kalpli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tealight-mum-8-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-ayran-1-litre",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-beyaz-peynir-350-gr-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-dil-peyniri-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-krem-peynir-80gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-labne-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-rulo-tereyagi-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-suzme-peynir-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/teksut-taze-surulebilir-peynir-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tereyag-suda-tuzsuz-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tex-ozcan-arap-sabunu-kase-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tibtrap-fare-kapani-zehirsiz-24lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tikvesli-ayran-170-ml-bardak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tikvesli-krema-200-ml-18-yagli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tikvesli-salep-970-ml-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tikvesli-yogurt-800-gr-tava-yogurt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tilda-basmati-pirinc-1kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tilda-kahverengi-basmati-pirinc-1kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tilda-yasmin-pirinc-1-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tirbison-cork-screw-turbisong-183-12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-dana-ilikli-kemik-suyu-600-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-organik-yogurt-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-organik-yogurt-750-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-pastorize-tereyagi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-pastorize-tuzlu-tereyagi-900-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-sis-kofte-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-taze-kasar-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-taze-kasar-600-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-yogurt-1500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tire-yogurt-tava-1000-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-ayakkabi-firca1-tp-566",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-bulasik-fircasi-deterjan-hazneli-tp-110",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-camsil-buyuk-33-cm-tp-181",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-camsil-kucuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-hanimeli-supurge-no0-tp-164",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-hanimeli-supurge-no1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-kareli-mop-tp-130",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-klozet-fircasi-cakmalitp-146",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/titiz-klozet-fircasi-tekli-tp-114",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tkv-bal-225-gr-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tkv-bal-460-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tkv-bal-460-gr-cicek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tkv-bal-850-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toblerone-beyaz-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toblerone-bitter-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tofita-1913-bicak-pls10-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tofita-limon-47-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-bpeynir-200-gr-ezine-keci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-bpeynir-200-gr-ezine-koyun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-cecil-peyniri-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-cecil-peyniri-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-dil-peynir-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-dil-peynir-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-bpeynir-450-gr-ezine",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-bpeynir-450-gr-inek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-dilimli-kasar-200-gr-dagkekikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-dilimli-kasar-200-gr-kimyonlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-dilimli-kasar-200-gr-pulbibbiberi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-gurme-dilimli-kasar-200-gr-zerdacalkarab",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-lor-500-gr-poset",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/toglu-orgu-peyniri-180-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tokat-erbaa-bizim-slm-bag-yaprak-400-gr-vakum",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tombul-organik-domates-salcasi-660-cc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tombul-tarhana-500-gr-aci",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tombul-tarhana-500-gr-tatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/torku-seker-kup-750-gr-esmer-16",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tortadolce-savoiardi-kedi-dili-biskuvi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/trina-torpu-uc-yollu-0048",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tropicana-meyve-su-1-lt-afyon-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tropicana-meyve-su-1-lt-malatya-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tropicana-meyve-su-200-ml-afyon-visnetetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tropicana-meyve-su-200-ml-bursa-seftalitetrapak",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-iphone-gri-usb-kablo-2dk7508gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-iphone-usb-kablo-2dk7508b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-micro-arac-ici-sarj-21a-2cks20ms",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-micro-beyaz-arac-ici-sarj-21a-2cks01",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-micro-beyaz-usb-kablo-2dk7530b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-micro-siyah-usb-kablo-2dk11s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-micro-usb-kablo-2dk7530s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-microfon-kulaklik-35mm-2kmm13s-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-microfon-kulaklik-pop-35mm-2kmm13b-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-microfon-kulaklik-pop-35mm-2kmm13gr-gri",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-microfon-kulaklik-pop-35mm-2kmm13m-mavi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-microfon-kulaklik-pop-35mm-2kmm13mr-mor",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-stereo-aux-kablo-2ak02",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-telefon-tutucu-2tt16",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-trio-beyaz-usb-kablo-2dk7521",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-trio-siyah-usb-kablo-2dk7521s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-type-c-beyaz-hizli-ev-sarji-21a-2scs20cb",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-type-c-beyaz-usb-kablo-2dk12b",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ttec-type-c-siyah-usb-kablo-2dk12s",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tunas-800-gr-pekmez-dut-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turkiye-gazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turmepa-yag-cozucu-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turp-alabas-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turp-arjantin-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turp-beyaz-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turp-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/turp-japon-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tursil-jel-26-yikama-leylak-bahcesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tursu-abiber-tursu-kg6-pelit",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/tursu-kg-salatalik-tursu-no0",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/twiix-karamel-kurabiye-biskuvi-144-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/twix-extra-gofret-75-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/twix-gofret-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/twix-white-46-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-amerikan-badem-ici-250-cig",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-antep-fistik-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-antrikot-pastirma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-aycekirdek-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-badem-ici-kav250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-baton-sucuk-kg-isilislem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-beyaz-leblebi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-ceviz-ici-160-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-dana-hamburger-kofte-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-findik-ici-cig-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-findik-ici-kavru250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-gurme-keci-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-gurme-koyun-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kabak-cekirdek-ici-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kabuklu-ceviz-kg-yerli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kaju-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kavrulmus-tuzlu-fistik-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kokteyl-kuruyemis-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kudus-hurma-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kuru-incir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kuru-kayisi-kg-jumbo-sari",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-kuru-kayisi-kg-naturel-jumbo-koyu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-ramazan-kumanya-2023",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-ramazan-kumanya-2024",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-ramazan-kumanya-ozel-1",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-sari-leblebi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-sigborek-kgpeynirli-pismis",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-siirt-fistik-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-soslu-fistik-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-soslu-misir-140-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ucler-tuzlu-leblebi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-8-kek-55-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-8-kek-mini-162-gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-8-kek-orman-meyveli-55-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-9-kat-tat-gofret-39-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-9-kat-tat-kup-200-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-9-kat-tat-kup-200-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-9-kat-tat-portsek-118-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-albeni-cik-kapli-kah-36-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-albeni-cikolata-bar-440-gr-4-lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-albeni-tane-tane-29-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-alpella-gofret-24-gr-sutlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-altinbasak-grissini-125-gr-cavdarli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-badem-kreker-sade-38-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-baton-cikolata-30-gr-cilekli-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-baton-kek-220-gr-mandalina-soslu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-be-happy-88-gr-cilek-tadpat-sek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-bebe-bisk-tam-tahilli-172-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-biskrem-biskuvi-184-gr-extra-cookie",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-biskrem-biskuvi-90-gr-portakalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-biskrem-biskuvi-94-gr-byz-cik-limon-dolgu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-biskrem-cookle-80-gr-cikolata-dolgulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-buklet-ikramlik-cik-300-gr-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cik-60-gr-cilekli-beyaz-cikolatakare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cik-sos-baton-kek-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-278-gr-sutlu-pasta-tadinda",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-60-gr-altin-cikolatakare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-60-gr-badem-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-60-gr-findik-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-60-gr-seker-ilavesiz-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-60-gr-tuzlu-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-70-gr-sutlutablet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-albeni-yapay-zeka-mavi-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-amore-limon-dol-54-gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-draje-100-gr-biskuvili-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-draje-100-gr-findikli-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-findik-ruyasi-75gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolata-kalp-kutu-123-gr-iyiki-varsin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolatali-gofret-30-gr-karemeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolatali-gofret-436-gr-4-lui-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cikolatali-gofret-47-gr-maxi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-80-gr-peynirlisoganli-citir",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-80-gr-tulum-peynirli-koz-biberli-citi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-citir-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-citir-tulum-peynirli-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-kraker-4x70grx12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-kraker-70-gr-hashasli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizi-kraker-70gr-zyaglikekikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizivic-kraker-82-gr-hashaslibaharatli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cizivic-kraker-82-gr-zyag-kekik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-coco-star-crisp-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cocostar-4x25gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cocostar-biskuvi-154-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cocostar-sand-bis-22gx24",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokokrem-180-gr-blister",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokokrem-250-gr-inmold-kase",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokokrem-350-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokokrem-650-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokokrem-saklama-kabi-950-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-dev-muzlu-22-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-dev-sade-22-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-marsmellow-1512-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-marsmellow-3512-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-marsmellow-3512-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomel-marsmellow1512-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomet-mars-cilekli-3x12g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokomet-mars-sade-3x12g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokonat-gofret-30-gr-baklava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokonat-gofret-333-gr-3lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokoprens-cik-kap-biskuvi-81-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokoprens-dark-234-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokoprens-dark-23gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokoturta-108-gr-cikolataportakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cokoturta-visne-300gx12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-crown-3u1-findikli-175-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-cubuk-kraker-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-donut-tekli-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-kek-200-gr-tam-tahilli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-magma-210-gr-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-magma-210-gr-karamelli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-magma-65-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-magma-65-gr-cilekli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-magma-65-gr-karamelli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-mousse-45-kg-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-tart-180-gr-poratakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dankek-tart-kek-mini-150-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dido-cikolata-345-gr-bitter-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dido-gofret-55-gr-kare",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dido-gofret-59-gr-kare-latte",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dido-trio-365-gr-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-dubai-cikolata-93-gr-kadayifli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-everwell-defence-tablet-136-grx12x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-everwell-kmurver-c-cinko-tab-675gx9x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-everwell-vit-c-d-cink-ef-tab-675gx9x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-everwell-vitamin-c-eftab-675gx9x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-findik-aski-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-findik-ruyasi-350-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-findik-ruyasi-625-gr-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-finger-biskuvi-117gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-finger-biskuvi-5-x150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-halley-beyaz-cik-kap-biskuvi-7x30-grx12",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-halley-cik-dolgulu-biskuvi-236-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-halley-mini-cik-kap-biskuvi-100-gr-pos",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-halley-mini-muzlu-kap-biskuvi-66-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-halley-muzlu-7x30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimeller-biskuvi-81-gr-dere-otlu-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimeller-biskuvi-945-gr-franbuaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimeller-fram-sos-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimeller-mahlepli-tuzlu-kurabiye-81-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimeller-zey-biber-tuz-kur-81-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hanimellerun-kurabiye-141-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hero-goodies-cilek-bar-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hero-goodies-findik-bar-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-hero-goodies-muz-bar-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikram-biskuvi-84-gr-cikolatali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikram-biskuvi-87-gr-cift-fram-ve-vanl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikram-draje-145gr-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikram-draje-150gr-bis-cikolata",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikraml-cikolata-208-gr-buklet-mavibyr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cik-200-gr-bukletspecial-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cik-455gr-ece-findikli-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-200-gr-laviva-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-208-gr-buklet-pembe",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-215-gr-ece-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-240-gr-select-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-2893-gr-truffle-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-350-gr-cikolatin-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-ikramlik-cikolata-350-gr-ece-byr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kangy-suy-kornet-26gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kanky-bulut-kornet-gofret-265g",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kat-kat-tat-baklava-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kat-kat-tat-cilekli-28-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kat-kat-tat-findikli-25-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kekstra-jbol-xl-70-gr-portakalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kekstra-kek-mini-150-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-biskuvi-165-gr-frambuazli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-kakaolu-bisk-61-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-rondo-biskuvi-61-gr-muz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-rondo-biskuvi-61gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-sand-sade-biskuvi-4x61-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kremali-sandvic-biskuvi-8x61-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kripsi-sut-misir-cerez-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-kripsi-sut-misir-cerez-40-gr-peynirli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-cubuk-krker-40-gr-acili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-cubuk-krker-40-gr-ketcapmayonez",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-cubuk-krker-40-gr-macho",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-cubuk-krker-40-gr-taco",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-pops-pm-taco-32-grx18",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-tirkracili-48-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-tirkrbahr-48-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krispi-tirkrk-ktcp-48-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-krokantli-findik-ezmesi-350-gr-6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-laviva-limcheescake-bar-35-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-laviva-selection-45-gr-bitter-gofret",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-laviva-selection-45-gr-sutlu-gofret",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-lokumcuk-600-gr-karisik-meyve-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-mcv-milk-chocolate-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-mcv-peanut-butter-90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-metro-buyuk-boy-45-grx18x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-metro-cikolata-bar-436-gr-4lu-paket",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-napoliten-cikolata-33-gr-tuzlu-karamel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-olala-162-gr-sufle-mini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-olala-gurme-tatli-70-gr-kirmizi-kadife-pasta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-olala-sufle-70-gr-profiterol",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-olala-tiramisu-45-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-oneo-60-dakike-ferah-sakiz-31-gr-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-oneo-karpuz-sakiz-14-gr-7-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-oneo-nane-sakiz-14-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-pastil-ihllimprop224grx12x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-pastil-kmurdun-224gx12x16",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-pastil-okamennane224gx12x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-pastil-znzrdpr-224gx12x6",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-pembo-karisik-meyve-aromali-balonlu-sakiz-22",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-piko-gofret-18-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-rodeu-cikolata-45-gr-yerfistiklikaramel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-rondo-8li-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-rulo-pasta-muzlu-235grx8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-rulokat-204-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-saklikoy-biskuvi-100-gr-frambuaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-saklikoy-biskuvi-73-gr-tam-tahseker-ilav",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-saklikoy-biskuvi-785-gr-kavilcaun-cikolat",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-susamli-corekotlu-kraker-44-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-susamli-cubuk-kraker70-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-susamli-simit-kraker-41-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-tablet-cikolata-70-gr-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-tablet-cikolata-70-gr-bitter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/ulker-tac-kraker-susamli-corekotlu-76gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-arom-maden-su-200-ml-karpuz-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-aromali-maden-su-200-ml-c-max-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-aromali-maden-su-200-ml-elma",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-aromali-maden-su-200-ml-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-extra-300-ml-mandalina",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-extra-300-ml-orman-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-frutti-extra-300-ml-yesil-limon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-gazoz-1-lt-efsane-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-gazoz-250-ml-efsane-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-gazoz-250-ml-efsanesise",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-gazoz-6250-ml-efsane-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-limonata-1-lt-sekersiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-limonata-yesil-limonlu-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-meyveli-gazsiz-1-lt-ananas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-meyveli-gazsiz-1-lt-mandalina",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-ramazan-serbeti-1-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-su-1-lt-premium-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-su-330-ml-premium-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-su-400-ml-premium-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uludag-su-750-ml-premium-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/umay-manda-yogurdu-1000-gr-tava",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/umay-manda-yogurdu-550-gr-cam",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/umay-manda-yogurdu-750-gr-comlek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/umay-yogurt-2000-gr-bidon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-baklavalik-yufka-800-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-mini-cepli-ekmek-4-li-240-gr-sade",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-mini-cepli-ekmek-4-lu-240-gr-tbugday",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-mini-cepli-ekmek-4-lu-240gr-tbugday",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-sandvic-ekmegi-440-gr-xl",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unabella-toriilla-15-cm-12-li-330-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-bergam-tulum-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-cerkez-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-dil-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-hellim-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-manyas-peyniri-kg-aztuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-manyas-peyniri-kg-tuzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-orgu-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/unal-ciftligi-sepet-peyniri-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-camasir-deterjani-1500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-camasir-deterjani-1500-ml-sensitive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-camasir-sabunu-1500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-camasir-yumusatici-1500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu-352-aktif-oynaogren",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu-360",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu-52li-hassas-dokunus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu-52lix3lu-paket-hassas-dokun",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu-552-li-ilk-adim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-havlu352-li-aile",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-islak-mendil-52li-ilk-adim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-kolay-tarama-spreyi-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-kolonya-150ml-bebeksi-dokunus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-kolonya-150ml-ciceksi-dokunus",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-kulak-cubugu-60-li-bebek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-pisik-kremi-75-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-sampuan-700-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-baby-sampuan-900-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-eagle-sriracha-aci-biber-sosu-230-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-ilk-adim-3x52li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uni-konsantre-yumusatici-1200-ml-pudra-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-2-kat-lifli-tava-ekmegi520-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-cikolatali-kruvasan-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-geleneksel-lavas-520-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-grissini-coktahilli-125-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-iki-kat-tahilli-tava-ekmegi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-kitiruno-ctahilli-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-kitiruno-tbugday-ciya-tohum-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-otantik-tava-ekmegi-550-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-p-cok-tahil-sbugday-350-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-pastaban-3-kat-3200-gr-kakaolu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-premium-susamli-b-hamburger-4lu-340gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-sandvic-ekmegi-gurme-440-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uno-tost-ekmegi-450-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uretim-mercimek-kirmizi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uretim-pirinc-baldo-gonen-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-baby-enginar-580-gr-6adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-cagla-badem-tursu-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-cubuk-tursu-660gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-enginar-kalbi-410-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-enginar-tapanade-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-jalopone-sos-265gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-kapari-230gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-kuru-domates-295gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-kuru-domates-ezme-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-makarna-sos-265-grdomates",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-makarna-sos-265-grfeslegen",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-nane-sos-265gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/urlaciftlik-yesil-zeytin-tapanade-300gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-beyaz-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-cavus-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-cekirdeksiz-kirmizi-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-redglobe-ithal-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-siyah-efes-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzum-siyah-kg-ithal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/uzumlu-tulum-peynir-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vakfi-kebir-ekmek-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vancat-kedi-kumu-10-lt-premium-active-carbon-inc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vancat-kedi-kumu-5-kg-pudra-kokulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-elde-hali-samp2850",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-multi-power-400-gr-beyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-on-islem-leke-cikarici-jel-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-oxi-act-cok-amacli-spr-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-oxi-act-hali-spr-500-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vanish-sivi-2-lt-beyaz8",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-longlife-pil-power-2-li-ince-aaa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-cr-216-2-li-lityum-dugme",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-dugme-cr-2025-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-dugme-cr-2032-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-energy-8li-ince-aaa-alkalin-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-energy-8li-kalem-aa-alkalin-avantaj",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-2li-c-orta",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-2li-d-buyuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-9-volt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-power-2-li-kalem-aa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-power-4lu-ince-aaa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-power-4lu-ince-aaa-cErG9",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-power-4lu-kalem-aa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-longlife-power-4lu-kalem-aa-UN4EC",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-sarjli-2li-kalem-aaa-2100-mah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-sarli-2-li-ince-aaa-800-mah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-superlife-4-lu-ince-aaa-cinko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-superlife-62-ince-aaa-cinko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-pil-superlife-62-kalem-aa-cinko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-superlife-cinko-aa-kalem-pil-12li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/varta-superlife-cinko-aaa-ince-pil12li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vaseline-nemlendirici-100-mlcocoa-butter",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vaseline-nemlendirici-100-mloriginal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vavana-oto-kokusu-angelicbeyaz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vavana-oto-kokusu-artistic-mavi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vavana-oto-kokusu-mystic-siyah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vavana-oto-kokusu-optimisticmor",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vavana-oto-kokusu-relaxedkirmizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-agda-kremi-tuy-dok-100-ml12-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-agda-kremi-tuy-dok-100-ml12-normal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-men-koltuk-alti-bolgesi-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-men-tuy-dokucu-dusta-krem-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-men-tuy-dokucu-krem-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-men-tuy-dokucu-krem-200-ml-hassas-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-prof-agda-bant-20-li-hassas-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-prof-agda-bant-40-li-hassas-cilt-prom",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-prof-krem-200-ml-tum-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-prof-yuz-agda-bant-20-li-normal-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-prof100-ml-tumciltler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-pure-agda-16li-bikini-b-koltuk-alti-hassas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-pure-kremi-100-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-sir-agda-bant-20-li-hassas-cilt-super-firsa",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-sir-agda-banti-12-li-hassas-ciltler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-sir-agda-banti-12-li-normal-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-sir-agda-banti-20-li-normal-cilt-super-firs",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-tuy-dokucu-krem-150-ml-dusta-hassas-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-tuy-dokucu-krem-200-ml-normal-cilt-super-f",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-tuy-dokucu-krem-200ml-hassas-cilt-super-f",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veet-tuy-dokucu-krem-sprey-150-ml-hassas-cilt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegan-beyaz-peynir-tadinda-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegan-fume-tadinda-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegan-koy-peyniri-tadinda-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegan-paemesan-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-balkabagi-corbasi-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-beyran-corbasi-480-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-brokoli-corbasi-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-hurma-pekmezli-sade-sutlac-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-ilik-yagihindistan-c-yagi-ile-zenginle",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-iskembe-corbasi-480-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-keci-sutu-biskuvili-sutlac-190-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-kemiksulu-tarhana-corbasi-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-tavuklu-princli-sebze-corbasi-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegbones-yuvalama-corbasi-480-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vegboneseksili-kofte-corbasi-480-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vege-koli-bandi-4540-seffaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vege-koli-bant-40mt-seffaf",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-bitki-bazli-nugget-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-bitki-bazli-schnitzel-190-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-bitki-bazli-sotelik-150-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-gurme-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-soya-kofte-karisimi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-tofu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-tutsu-aromali-tofu-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veggy-vegan-kofte-300-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vepa-sac-fircasi-004",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/verbena-arac-kolonyasi-limon-ozlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1200-ml-cont-buz-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1320-ml-cont-cicek-ferahligi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1440-ml12-gul",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1440-ml12-mineralhindistan-cevizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1440-ml12-sensitive",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-1440-ml12-yaseminaloe-vera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-supreme-yumusatici-1200-ml-elegance",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-max-supreme-yumusatici-1200-ml-romance",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vernel-yumusatici-3-lt-deniz-esintisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veronelli-makarna-burgu-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veronelli-makarna-kalem-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veronelli-makarna-kelebek-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/veronelli-makarna-spaghetti-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/viennetta-cikolata-vanilya-800-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-bez-actifibre-2-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-bez-cam20",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-bez-mfiberli-style-tembezi-4-lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-bulasik-teli-celik-21-li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-celiktel-power-inox-3lu-color",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-eldiven-kullan-at-l-multi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-eldiven-kullan-at-m-multi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-fibra-koton-mikrofiber-paspas",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-parlak-konfor-sunger-2-li-duz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-parlak-konfor-sunger-2-li-oluklu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-sap-kirmizi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-sapli-paspas-klasik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-spino-ultra-temizlik-seti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vileda-sunger-silgi-2li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vim-kirec-cozucu-1000-ml-sprey",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vim-mutfak-yag-cozucu-sprey-1000-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vim-tuvalet-temizleyicisi-750-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/visne-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-insta-white-63gr-sakiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-22-gr-m-suyu-box",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-45-dk-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-45-dk-karpuz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-45-dk-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-45-dk-yesil-nane",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-45-dkahududu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-cilek-mandalina-cuzdan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-26-gr-mentol-air-action-cuzdan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-67-gr-45-dak-nane-comfort-pack",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-67-gr-mentol-air-action-comfort",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vivident-sakiz-67-gr-white-comfort-pack",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vshot-takviye-icecegi-100ml-karamurver-vitamin",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/vshot-takviye-icecegi-100ml-vitamin-c-d-cinko",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/warmen-exclusive-penye-yilbasi-bayan-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/warmen-exclusive-yilbasi-bayan-havlu-corap",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasa-ekmek-200-gr-susamli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasa-ekmek-275-gr-original-sade-gevrek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasa-sandvic-krem-peynirli-humus-vegan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasa-sandvic-krem-peynirli-sade-30-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasp-cilekli-protein-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasp-findikli-protein-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasp-kurabiye-aromali-protein-bar-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wasp-yer-fistikli-ve-kakao-protein-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/waspantep-fistikli-protein-bar-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-brezilya-cevizi-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-cok-tahilli-patlak-100-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-kakao-yagi-150-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-karabugday-patlagi-100-gr-glutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-karadut-ozu-315-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-kids-organik-bebe-bis90-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-kids-organik-domatesli-kraker-50-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-org-hindistan-cevizi-yagi-300-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-aci-kirmizi-pul-biber-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-aci-kirmizi-toz-biber-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-kekik-20-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-seylon-tarcin-toz-55-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-tatli-kirmizi-toz-biber-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-zencefil-tozu-65-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-organik-zerdecal-tozu-85-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-pirinc-patlagi-100-gr-glutensiz",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-tahinli-kraker-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wefood-zeytin-kekik-kraker-40-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-14-kg-kuru-biftek-havuc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-14-kg-kuru-tavuk-sebze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-14-kg-kuru-ton-balik-sebze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-300-gr-kuru-biftek-havuc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-300-gr-kuru-tavuk-sebze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-300-gr-kuru-ton-balik-sebze",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-400-gr-konserve-ton-balik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-kuzulu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-kuzulu-hindili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-pouch-guvec-sigiret",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-pouch-guvec-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-pouch-kuzu-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-gr-pouch-somin-havuc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85-grpouch-yavru-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-kedi-mama-85gr-pouch-somon",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-pouch-85-gr4-lu-kumes-hayvn",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-pouch-85-gr4-lu-somonlu-ton-balikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/whiskas-yavru-kedi-mama-300-gr-kuru-tavuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wou-misir-cipsi-40-gr-cedar-peynir-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wou-misir-cipsi-40-gr-pizza-cesnili",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/wou-misir-cipsi-40-gr-tavuk-aromali",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtra-careburun-bandi-12li-yetiskin-standart-boy",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtra-careburun-bandi-12li-yetiskin-xl-large",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtra-clean-mikrofiber-temizlik-bezi-3lu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtracare-kurdanli-dis-ipi-50li",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtraclean-gozluk-temizleme-mendili-30-adet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/xtraclean-termojel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yaban-mersini-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yaprak-erbaa-haslanmis-500-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yaprak-salamura-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yaris-gazetesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-barbunya-pilaki-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-glutensiz-nohutlu-karabugday-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-glutensiz-sebzeli-karabugday-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-haslanmis-fasulye-700-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-haslanmis-garnitur-560-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-haslanmis-tane-misir-220-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-hazir-yemek-etli-kuru-fasulye-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-hazir-yemek-etli-nohut-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-hazir-yemek-garniturlu-pirinc-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-hazir-yemek-nohutlu-pirinc-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-hazir-yemek-sehriyeli-pirinc-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-napolitan-soslu-makarna-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-nohutlu-bulgur-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-turk-hindi-but-fume-karabiberli-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yayla-yesil-mercimekli-bulgur-pilavi-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yedigun-gazoz-1-lt-blue",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yedigun-gazoz-1-lt-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yedigun-gazoz-25-lt-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yedigun-gazoz-250-ml-kutu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yeni-macera-ikm-dergisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-ahududu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-karisik-4-meyve",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-kayisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-portakal",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-turunc",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-visne",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-diyabetik-recel-230-gr-yaban-mersini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-gold-seri-recel-450-gr-bergamot",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-lokum-250-gr-antepfis-findik-badem-yerfi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-lokum-250-gr-findik-nar",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-lokum-250-gr-kakao-findkremcesultan",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-lokum-250-gr-narli-karisik-cerezli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-organik-recel-visne-290-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-seker-ilavesiz-recel-290-gr-4-meyveli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-seker-ilavesiz-recel-290-gr-ejder-meyvesi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-seker-ilavesiz-recel-290-gr-yaban-mersini",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yenigun-sut-receli-250-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yesil-malatya-lokum-350-gr-safran-afistik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yesil-malatya-lokum-350-gr-safran-findik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yesil-malatya-lokum-350-gr-special",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yesil-malatya-lokum-350-gr-sultan-afistikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yesil-malatya-lokum-350-gr-sultan-findikli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yildiz-4-lu-dogum-gunu-mumu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yoruk-ana-antep-fistigi-ezmesi-200-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yturk-hindi-but-fume-kekikli-120-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycck-yagi-1-lt-personel",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-1-lt-zerdacalli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-12-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-2-lt-1hKEo",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-45-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-5-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-aycicek-yagi-5-lt-pet",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-egemden-riviera-zeytinyagi-1-lt",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yudum-egemden-zyag-500-ml-riviera",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-cmsrspreyi-450-ml-12-amber",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-cmsrspreyi-450-ml-12-sakura",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-cmsrspreyi-450-ml12-hanimeli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-ext1440-ml-lavantamanolya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-ext1440-ml-sakayik",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-ext1440-ml-sakura",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-extra-yumusatici-1200-ml-vanilya-pamuk",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-jet-fresh-sprey-lilyum-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-jet-fresh-sprey-orkide-200-ml",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-sivi-deterjan-1690-ml-renkliler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-sivi-deterjan-1690-ml-siyah-bakim",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-sivi-deterjan-2520-ml-tum-renkler",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-uzman-serisi-1200-ml-acik-hava-etkisi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yumos-uzman-serisi-1200-ml-grilesme-karsiti",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yupo-eglenceli-dondurma-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yupo-kolali-80-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yupo-super-jeli-80-gr-limonportakal-mandalina",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yuva-maya-125-gr-instant-kuru-maya",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yuvam-barbunya-pilaki-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/yuvam-fasulye-pilaki-400-gr",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zencefil-kg",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zeylif-dus-lifi",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-glutensiz-granola-180-gr-kakao",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-glutensiz-granola-180-gr-kurumeyvekuruyem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-glutensiz-protein-bar-35-gr-kakaobadem",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-glutensiz-protein-bar-35-gr-kakaokaju",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-kakaolu-lokum-48-gr-hcevizi-kapli",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-kidz-glutensiz-meyve-kupu-49-gr-cilek",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-kidz-mbar-30-gr-kakaolumeyve-tah",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-kidz-mbar-30-gr-muzlu",
    component: component_45stubLBysIGp3OX
  },
  {
    name: component_45stubLBysIGp3OXMeta?.name,
    path: "/zuber-limonlu-lokum-48-gr-hcevizi-kapli",
    component: component_45stubLBysIGp3OX
  }
]